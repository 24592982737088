import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
import AddSessionProblemModal from "../components/AddSessionProblemModal";
import { Get, Patch, Post, Delete } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";

const SessionProblems = () => {
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isApiCall, setIsApiCall] = useState(false);
  const [showAddProbModal, setShowAddProbModal] = useState(false);
  const [showUpdateProbModal, setShowUpdateProbModal] = useState(false);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [sessProblems, setSessProblems] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [isUpdateApiCall, setIsUpdateApiCall] = useState(false);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const addNewQuestion = async (params) => {
    const newQuesURL = NODE_URL(`sessionProblem`);
    console.log({ params });
    if (params?.question === "") {
      toast.info("❌ Question Or Answer field is left empty.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else if (params?.options?.length === 0) {
      toast.info("❌You haven't added any options yet.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else if (params?.options?.some((option) => option.opt === "")) {
      toast.info("❌ One of your options has left empty.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      setIsApiCall(true);

      const resData = await Post(newQuesURL, params, authHeader);

      if (resData !== undefined) {
        let fetchedData = { ...resData?.data?.data };
        setSessProblems([fetchedData, ...sessProblems]);
        toast.success("One New Session Problem Added!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setShowAddProbModal(false);
      } else {
        console.log("error");
      }
      setIsApiCall(false);
    }
  };

  const getAllProblems = async () => {
    const newQuesURL = NODE_URL(
      `sessionProblem/admin?page=${pageNumber}&limit=${limit}`
    );
    setIsApiCall(true);
    const resData = await Get(newQuesURL, accessToken);

    if (resData !== undefined) {
      setSessProblems(resData?.data?.data);
    } else {
      console.log("error");
    }
    setIsApiCall(false);
  };

  const toggleProblem = async (index) => {
    const newQuesURL = NODE_URL(`sessionProblem/${sessProblems[index]?._id}`);

    setIsApiCall(true);
    let params = {
      isActive: !sessProblems[index].isActive,
    };
    const resData = await Patch(newQuesURL, params, authHeader);

    if (resData !== undefined) {
      let fetchedData = { ...resData?.data?.data };
      setSessProblems([...sessProblems, fetchedData]);
      toast.success("Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      //   let copyData = sessProblems?.slice();
      let copyData = [...sessProblems];
      copyData[index].isActive = !sessProblems[index].isActive;
      console.log(copyData);

      setSessProblems(copyData);
    } else {
      console.log("error");
    }
    setIsApiCall(false);
  };

  const deleteSessionProblem = async (item, index) => {
    const deleteURL = NODE_URL(`sessionProblem/${item?._id}`);

    setIsApiCall(true);
    const resData = await Delete(deleteURL, null, authHeader);

    if (resData !== undefined) {
      let copyData = [...sessProblems];
      copyData?.splice(index, 1);
      setSessProblems(copyData);
      console.log({ copyData });
      toast.success("Deleted Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      console.log("error");
    }
    setIsApiCall(false);
  };

  const handleUpdateProblem = async (question) => {
    const updateUrl = NODE_URL(`sessionProblem/${question?._id}`);
    let index = sessProblems?.findIndex((ele) => ele?._id === question?._id);
    console.log({ index, question });

    setIsUpdateApiCall(true);
    const resData = await Patch(updateUrl, question, authHeader);

    if (resData !== undefined) {
      let copyData = [...sessProblems];
      copyData?.splice(index, 1, question);
      setSessProblems(copyData);
      console.log({ copyData });
      toast.success("Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setSelectedProblem(null);
      setShowUpdateProbModal(false);
    } else {
      console.log("error");
    }
    setIsUpdateApiCall(false);
  };

  useEffect(() => {
    getAllProblems();
  }, []);

  useEffect(() => {
    console.log({ pageNumber });
    getAllProblems();
  }, [pageNumber]);

  // useEffect(() => {
  //   if (selectedProblem !== null) {
  //   }
  // }, [selectedProblem]);
  return (
    <div className="container-fluid sess-prob">
      <h1 className="h3 mb-2 text-gray-800">Session Problems</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="card-body">
            {isApiCall ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isApiCall}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end mt-4  mb-4">
                  <h3 className="mr-3"> Add New Session Problem Question</h3>
                  <i
                    className="add-problem fas fa-plus-circle"
                    onClick={() => {
                      setShowAddProbModal(true);
                    }}
                  ></i>
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>Question</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sessProblems?.map((item, index) => {
                        return (
                          <>
                            <tr key={item?.id}>
                              <td>
                                <div className="d-flex justify-content-center">
                                  {index + 1}
                                </div>
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedProblem(item);
                                  setShowUpdateProbModal(true);
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <p className="fw-bold text-capitalize m-0">{`Question: ${item?.question}`}</p>
                                  <p className="ml-2">{`Problem case refers to: ${item?.for}`}</p>
                                </div>
                                {item?.options?.map((ele, ind) => {
                                  return (
                                    <div className="d-flex flex-column pl-5">
                                      <p className="m-0">
                                        {`Option: ${ele?.opt}`}
                                      </p>
                                      <div className="d-flex flex-row">
                                        <p>{`Default case: ${ele?.defaultCase}`}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </td>
                              <td>
                                <div className="d-flex justify-content-center fw-bold">
                                  {item?.isActive ? "Enabled" : "Disabled"}
                                </div>
                              </td>

                              <td className="d-flex justify-content-center">
                                <button
                                  className={`btn ${
                                    item?.isActive ? "not-appliedd" : "appliedd"
                                  } `}
                                  style={{ width: "100px" }}
                                  disabled={isApiCall}
                                  onClick={() => {
                                    toggleProblem(index);
                                  }}
                                >
                                  {item?.isActive ? "Disable" : "Enable"}
                                </button>
                              </td>
                              <td>
                                <button
                                  className={`btn not-appliedd`}
                                  style={{ width: "100px" }}
                                  disabled={isApiCall}
                                  onClick={() => {
                                    deleteSessionProblem(item, index);
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="pagination">
                    <Pagination
                      maxLastLength={maxLength}
                      array={sessProblems}
                      setPageNumber={setPageNumber}
                      pageNumber={pageNumber}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* This one is being used for adding  */}
      <AddSessionProblemModal
        isApiCall={isApiCall}
        title={"Add New Session Problem"}
        isShowModal={showAddProbModal}
        setIsShowModal={setShowAddProbModal}
        onClick={addNewQuestion}
        questionToUpdate={null}
        isUpdating={false}
        buttonText={"Add"}
      />

      {/* This one is being used for updating  */}
      <AddSessionProblemModal
        title={"Update Session Problem"}
        isApiCall={isUpdateApiCall}
        isShowModal={showUpdateProbModal}
        setIsShowModal={setShowUpdateProbModal}
        onClick={handleUpdateProblem}
        questionToUpdate={selectedProblem}
        isUpdating={true}
        buttonText={"Update"}
      />
    </div>
  );
};

export default SessionProblems;
