import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Delete, Get, Patch, Post } from "../components/Axios/AxiosFunctions";
import { nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";
import { SpinnerRoundOutlined } from "spinners-react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getMentee } from "../store/actions/mentorActions";
import { toast } from "react-toastify";
import UpdateEmailModal from "../components/UpdateEmailModal";

function BlockedList(props) {
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [blockedList, setBlockedList] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isModifying, setIsModifying] = useState(false);
  const [updateEmailText, setUpdateEmailText] = useState("");
  const [isBlockingUser, setIsBlockingUser] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [blockEmail, setBlockEmail] = useState("");
  const [updateObject, setUpdateObject] = useState(null);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getBlockedUsers = async () => {
    const blockedUsersURL = NODE_URL(`admin/emailBlockList`);
    setIsFetchingData(true);
    const responseData = await Get(blockedUsersURL, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setBlockedList(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  const unblockUser = async (user) => {
    const unblockUserURL = NODE_URL(`admin/emailBlockList/${user?._id}`);
    setIsModifying(true);
    const responseData = await Delete(unblockUserURL, authHeader);

    if (responseData !== undefined) {
      let index = blockedList?.findIndex((ele) => ele?._id === user?._id);

      let copyData = [...blockedList];
      copyData?.splice(index, 1);
      console.log({ copyData });

      setBlockedList(copyData);
      toast.success("User has been added unblocked.");
    } else {
      console.log("error");
    }
    setIsModifying(false);
  };

  const updateEmail = async () => {
    const unblockUserURL = NODE_URL(`admin/emailBlockList/${updateObject?._id}`);
    let params = {
      email: updateEmailText,
    }
    setIsModifying(true);
    const responseData = await Patch(unblockUserURL, params, authHeader);

    let index = blockedList?.findIndex((ele) => ele?._id === updateObject?._id);
    if (responseData !== undefined) {

      let copyData = [...blockedList];
      copyData?.splice(index, 1, responseData?.data?.data);

      setBlockedList(copyData);
      resetStates();
      toast.success("User E-mail updated successfully!");
    } else {
      console.log("error");
    }
    setIsModifying(false);
  };

  const blockThisUser = async (user) => {
    const unblockUserURL = NODE_URL(`admin/emailBlockList`);
    setIsBlockingUser(true);
    let params = {
      email: blockEmail,
    };
    const responseData = await Post(unblockUserURL, params, authHeader);

    if (responseData !== undefined) {
      toast.success("User has been added to blocked list.");
      setBlockEmail("");
      getBlockedUsers();
    } else {
      console.log("error");
    }
    setIsBlockingUser(false);
  };

  const resetStates = () => {
    setUpdateEmailText("");
    setUpdateObject(null);
    setShowUpdateModal(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getBlockedUsers();
  }, [pageNumber]);

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid blocked-users">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All Blocked Users</h1>
        {/* <p className="mb-4">All Mentees list</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">
              DataTables Example
            </h6> */}
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>E-mail Address</th>
                      <th>Action</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blockedList?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{index + 1}</td>
                            <td>{item?.email}</td>
                            <td>
                              <Button
                                disabled={isModifying}
                                className={`btn block-btn`}
                                onClick={() => {
                                  unblockUser(item);
                                }}
                              >
                                Unblock Now
                              </Button>
                            </td>
                            <td>
                              <Button
                                disabled={isModifying}
                                className={`btn block-btn`}
                                onClick={() => {
                                  setUpdateEmailText(item?.email);
                                  setUpdateObject(item);
                                  setShowUpdateModal(true);
                                }}
                              >
                                Update E-mail
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div>
                  <h4>Block A User</h4>

                  <div className="d-flex flex-row align-items-center">
                    <input
                      className="block-input mr-4"
                      value={blockEmail}
                      onChange={(e) => {
                        setBlockEmail(e.target.value);
                      }}
                      disabled={isBlockingUser}
                    />
                    <button
                      className="btn block-btn mt-4 mb-4"
                      disabled={isBlockingUser}
                      onClick={() => {
                        blockThisUser();
                      }}
                    >
                      {isBlockingUser ? "Please Wait" : "Block Now"}
                    </button>
                  </div>
                </div>
                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={blockedList}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <UpdateEmailModal
          state={updateEmailText}
          setState={setUpdateEmailText}
          setIsModalVisible={setShowUpdateModal}
          isModalVisible={showUpdateModal}
          onClickYes={updateEmail}
          onClickNo={resetStates}
          isApiCall={isModifying}
          confirmTextLine={"Enter email to be updated:"}
        />
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default BlockedList;
