import React, { useEffect, useState } from "react";
import { loginVector, coverPhoto } from "../constant/imagepath";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NODE_URL, nodeImageUrl } from "../components/Config/apiURL";
import { Get, Post, Patch } from "../components/Axios/AxiosFunctions";
import moment from "moment";
import ResolveDisputeModal from "../components/ResolveDisputeModal";
import { toast } from "react-toastify";

function UnresolvedSessionDetail() {
  const reciever = useSelector((state) => state.mentorReducer.disputeDetail);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [favorFor, setFavorFor] = useState("both");

  let disputeCreatedBy =
    reciever?.timeLine?.timeLineArray[
      reciever?.timeLine?.timeLineArray?.length - 2
    ]?.message?.split(" ")[0];

  let name =
    disputeCreatedBy?.toLowerCase() === "mentor"
      ? reciever?.mentor?.displayName
      : reciever?.mentee?.displayName;

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const resolveDipsute = async (
    message1,
    message2,
    mentorAmount,
    menteeAmount
  ) => {
    let args = {
      winner: favorFor,
      menteeMsg: message1,
      mentorMsg: message2,
      mentorAmount: Number(mentorAmount),
      menteeAmount: Number(menteeAmount),
    };
    setIsApiCall(true);
    // const collector = NODE_URL(`booking/resolve-dispute`);
    const collector = NODE_URL(`general/dispute-result/${reciever?._id}`);
    const responseData = await Patch(collector, args, authHeader);
    if (responseData !== undefined) {
      setShowProposalModal(false);
      setDisableAllButtons(true);
      toast.info("Dispute Resolved!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      console.log("error");
    }
    setIsApiCall(false);
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid DisputedSessionDetail">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Disputed Session Details</h1>
        <p className="mb-4"> </p>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* profile */}
            <div className="row">
              <div
                className="col-md-2"
                style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
              >
                <div className="profile">
                  <img
                    src={`${nodeImageUrl}/${reciever?.mentor?.photo}`}
                    alt=""
                    className="img-fluid rounded-circle"
                  />
                </div>
              </div>
              <div
                className="col-md-4"
                style={{
                  borderRight: "1px solid rgba(0,0,0,0.2)",
                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                <div>
                  <h4 className="d-flex flex-row align-items-center">
                    {reciever?.mentor?.displayName}{" "}
                    <span className="name-type ml-2">(Mentor)</span>
                  </h4>
                  <p className="location-lang">
                    {reciever?.mentor?.country} ,{reciever?.mentor?.city}
                  </p>
                  <p className="location-lang">
                    {/* Language : {reciever?.mentor?.preferredLanguage} */}
                  </p>
                </div>

                <div className="mt-3">
                  {/* <p className="bio-text">{`Email: ${reciever?.mentor?.UserId?.email}`}</p> */}
                </div>
              </div>

              <div
                className="col-md-2 pb-4"
                style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
              >
                <div className="profile">
                  <img
                    src={`${nodeImageUrl}${reciever?.mentee?.photo}`}
                    alt=""
                    className="img-fluid rounded-circle"
                  />
                </div>
              </div>
              <div
                className="col-md-4 pb-4"
                style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
              >
                <div className="d-flex flex-column justify-content-center">
                  <h4 className="d-flex flex-row align-items-center">
                    {reciever?.mentee?.displayName}{" "}
                    <span className="name-type ml-2">(Mentee)</span>
                  </h4>
                  <p className="location-lang">
                    {reciever?.mentee?.country} ,{reciever?.mentee?.city}
                  </p>
                  <p className="location-lang">
                    {/* Language :{reciever?.mentee?.preferredLanguage} */}
                  </p>
                </div>

                <div className="mt-3">
                  {/* <p className="bio-text">{`Email: ${reciever?.mentee?.UserId?.email}`}</p> */}
                </div>
              </div>

              <div
                className="mt-0 pt-4 col-md-6 disputedSection "
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <div>
                  <h3>Session Details</h3>
                  <p className="bio-text">
                    Session Date/Time [wrt Mentee] :{" "}
                    {moment()
                      .utc(reciever?.sessionStartTime)
                      .format(
                        `ddd DD, YYYY hh:mm A (UTC${reciever?.mentee?.timezone})`
                      )}
                  </p>
                  <p className="bio-text">
                    Session Date/Time [wrt Mentor] :{" "}
                    {moment()
                      .utc(reciever?.sessionStartTime)
                      .format(
                        `ddd DD, YYYY hh:mm A (UTC${reciever?.mentor?.timezone})`
                      )}
                  </p>
                  <p className="bio-text text-capitalize">
                    Session Type: {reciever?.lesson?.lessonName}
                  </p>
                  <p className="bio-text">
                    Price: {`$${reciever?.lessonAmount?.toFixed(2)}`}
                  </p>
                </div>
              </div>
              <div className="mt-0 pt-4  col-md-6 disputedSection ">
                <div>
                  <h3>Dispute Details</h3>

                  <p
                    className=" bio-text text-capitalize"
                    style={{ color: "black", fontWeight: "bolder" }}
                  >
                    {`Diputed Created by ${disputeCreatedBy} (${name})`}
                  </p>
                  {reciever?.dispute?.problemType && (
                    <p className="bio-text text-capitalize">
                      Description: {reciever?.dispute?.problemType}
                    </p>
                  )}

                  {reciever?.dispute?.settlementamount === undefined &&
                    reciever?.dispute?.settlementamount === "" &&
                    reciever?.dispute?.settlementamount === null &&
                    reciever?.dispute?.settlementamount === 0 && (
                      <p className="bio-text text-capitalize">
                        Proposed Resolution:{" "}
                        {reciever?.lessonAmount ==
                        reciever?.dispute?.settlementamount
                          ? `Return All Credits to ${
                              reciever?.BookingStatus ===
                              "there was a problem for mentor"
                                ? "mentee"
                                : "mentor"
                            }`
                          : reciever?.BookingStatus ===
                            "there was a problem for mentor"
                          ? `Return $${reciever?.dispute?.settlementamount} to mentee`
                          : `Return $${reciever?.dispute?.settlementamount} to mentor`}
                      </p>
                    )}
                  {reciever?.dispute?.message && (
                    <p className="bio-text text-capitalize">
                      Additional: {reciever?.dispute?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-12 disputedSection ">
                <h3>Session Records</h3>
                {reciever?.timeLine?.timeLineArray?.length > 0 &&
                  reciever?.timeLine?.timeLineArray.map((step) => {
                    return (
                      <div className="d-flex flex-column">
                        <p className="bio-text mb-0">{`◉  ${step?.message}`}</p>
                        <span className="bio-text mb-2 fw-bold ml-3">
                          {moment(step?.createdAt).format(
                            "dddd, MMM DD, hh:mm A"
                          )}
                        </span>
                      </div>
                    );
                  })}
              </div>
              <div className="col-md-12 disputedSection ">
                <h5 className=" text-center">Resolve dispute in favour of</h5>

                <div className="button-container">
                  <div style={{ margin: "6px" }}>
                    <Button
                      className={`btn btn-user btn-block btn-Fav-MenteeMentor`}
                      onClick={() => {
                        setFavorFor("mentee");
                        setShowProposalModal(true);
                      }}
                      disabled={disableAllButtons || isApiCall}
                    >
                      Mentee
                    </Button>
                  </div>
                  <div style={{ margin: "6px" }}>
                    <Button
                      className={`btn btn-user btn-block btn-Fav-MenteeMentor`}
                      onClick={() => {
                        setFavorFor("mentor");
                        setShowProposalModal(true);
                      }}
                      disabled={disableAllButtons || isApiCall}
                    >
                      Mentor
                    </Button>
                  </div>
                  <div style={{ margin: "6px" }}>
                    <Button
                      className={`btn btn-user btn-block btn-Fav-MenteeMentor`}
                      onClick={() => {
                        // resolveDipsute(reciever._id, "mentor");
                        setFavorFor("both");
                        setShowProposalModal(true);
                      }}
                      disabled={disableAllButtons || isApiCall}
                    >
                      Both
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ResolveDisputeModal
        className="mente_mentor_Modal"
          isShowModal={showProposalModal}
          setIsShowModal={setShowProposalModal}
          onClick={resolveDipsute}
          title={"Dispute Resolve Proposal"}
          isApiCall={isApiCall}
          favorFor={favorFor}
          data={reciever}
        />
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default UnresolvedSessionDetail;
