import React, { useState, useEffects } from "react";
import { loginVector, coverPhoto } from "../constant/imagepath";
import StarRatings from "react-star-ratings/build/star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { nodeImageUrl } from "../components/Config/apiURL";
import { NODE_URL, baseUrl } from "../components/Config/apiURL";
import { getDeactivatedDetails } from "../store/actions/mentorActions";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import ReportModal from "../components/ReportModal";
import { ToastContainer, toast } from "react-toastify";

function DeActivatedAccountDetails() {
  const deactivatedDetail = useSelector(
    (state) => state.mentorReducer.deactivatedDetail
  );

  const [reportModal, setReportModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [successDisable, setSuccessDisable] = useState(false);

  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  //   let keywords = JSON.parse(approvedMentors.keywords);
  //   console.log(keywords, ";;;;;;;;;;;;;;;;;");

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const sendReportMessage = async (item) => {
    console.log({ item });
    let obj = {
      status: status,
      reportedBy: item.reportBy,
      subject: subject,
      adminMessage: description,
      userId: item?.reportBy === "mentee" ? item?.mentorUser : item?.menteeUser,
      displayName:
        item?.reportBy === "mentee"
          ? item?.mentor?.displayName
          : item?.mentee?.displayName,
    };
    console.log(obj);
    const sendReportUrl = NODE_URL(`admin/admin-resolve-report/${item._id}`);
    setLoading(true);
    const responseData = await Post(sendReportUrl, obj, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      toast.success("success");
      setSuccessDisable(true);
      //   let arr = languages.slice();
      //   arr.push(responseData?.data?.data);
      //   console.log(arr, "4444");
      //   setLanguages(arr);
    } else {
      console.log("error");
    }
    setLoading(false);
  };


  // == activate btn == 
  
  const Activate = async (deactivatedDetail) => {
    console.log({ deactivatedDetail });
    let obj = {
        reportId: deactivatedDetail?._id,
        id: deactivatedDetail?.reportBy === "mentee" ? deactivatedDetail?.mentor?.UserId :  deactivatedDetail?.mentee?.UserId,
    };
    console.log(obj);
    const activateUrl = NODE_URL(`users/activate-user-account?reportId=${deactivatedDetail?._id}`);
    // setLoading(true);
    const responseData = await Patch(activateUrl, obj, authHeader);

    if (responseData !== undefined) {
      console.log(responseData);
      toast.success("success");
      // setSuccessDisable(true);
        // let arr = deactivateList.slice();
        // arr.push(responseData?.data?.data);
        // setDeactivateList(arr);
    //  let arr = deactivateList.slice();
    //   arr.splice(index, 1);
    //   setDeactivateList(arr);
    } else {
      console.log("error");
    }
    // setLoading(false);
  };


  console.log("deactivate Detail ", deactivatedDetail);
  return (
    <>
      <div className="container-fluid ApprovedMentorDetails">
        <h1 className="h3 mb-2 text-gray-800">DeActivated Account Details</h1>
        {/* <p className="mb-4"> Ipsum is a placeholder text commonly</p> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              {/* <div className="col-md-12">
                <div className="cover-photo">
                  <img
                       src={`${nodeImageUrl}/${deactivatedDetail?.reportBy === "mentee" ? deactivatedDetail?.mentor?.coverPhoto : deactivatedDetail?.mentee?.coverPhoto}`}
                      alt="cover"
                      className="image-fluid"
                  />
                  </div>
              </div> */}
              <div className="col-md-2">
                <div className="deactivateProfile">
                    <img
                        src={`${nodeImageUrl}/${deactivatedDetail?.reportBy === "mentee" ? deactivatedDetail?.mentor?.photo : deactivatedDetail?.mentee?.photo}`}
                        alt=""
                        className="image-fluid"
                    />
                    </div>
              </div>
              <div className="col-md-10">
                <div style={{marginLeft: '36px',marginTop: '109px'}}>
                  {/* <h2>{deactivatedDetail?.displayName}</h2> */}
                  <p className="location-lang">
                    Reported By : {deactivatedDetail?.reportBy === "mentee" ? deactivatedDetail?.mentee?.displayName : deactivatedDetail?.mentor?.displayName}  {""} {deactivatedDetail?.reportBy}
                  </p>
                  <p className="location-lang">
                    Deactivated Account Name : {deactivatedDetail?.reportBy === "mentee" ? deactivatedDetail?.mentor?.displayName : deactivatedDetail?.mentee?.displayName}
                  </p>
                  <p className="location-lang">
                    Status : {deactivatedDetail?.status}
                  </p>
                  <p className="location-lang">
                  Subject : {deactivatedDetail?.subject}
                  </p>
                  <p className="location-lang">
                    Reported Message : {deactivatedDetail?.adminMessage}
                  </p>
                  <p className="location-lang">
                   Description : {deactivatedDetail?.description}
                  </p>
                  <p className="location-lang">
                    Country: {deactivatedDetail?.reportBy === "mentee" ? deactivatedDetail?.mentor?.country : deactivatedDetail?.mentee?.country}
                  </p>
                  
                  
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      {/* <p>Actions</p> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                          className={`btn btn-user btn-block activate-Button`}
                          onClick={() => {
                          Activate(deactivatedDetail)
                          setSuccessDisable(true)
                          }}
                          disabled={successDisable}
                        >
                          Activate
                        </Button>
                    </div>
                    {/* <div className="col-md-3">
                      <Button
                        className={`btn btn-user btn-block`}
                        onClick={() => {
                          setStatus("ignore");
                          setItem(deactivatedDetail);
                          sendReportMessage(item);
                        }}
                        disabled={successDisable}
                      >
                        Ignore
                      </Button>
                    </div>
                    <div className="col-md-3">
                      <Button
                        className={`btn btn-user btn-block`}
                        onClick={() => {
                          setReportModal(true);
                          setStatus("warning");
                          setItem(deactivatedDetail);
                        }}
                        disabled={successDisable}
                      >
                        Warning
                      </Button>
                    </div>
                    <div className="col-md-3">
                      <Button
                        className={`btn btn-user btn-block report-action`}
                        onClick={() => {
                          setReportModal(true);
                          setStatus("deactivate account");
                          setItem(deactivatedDetail);
                        }}
                        disabled={successDisable}
                      >
                        Delete Account
                      </Button>
                    </div> */}
                  </div>
                </div>
                {/* <div className="rating">
                                        <div className="star">
                                            <StarRatings
                                                rating={approvedMentors?.ratingsAverage}
                                                starDimension="20px"
                                                starRatedColor="orange"
                                                starSpacing="0px"
                                            />
                                        </div>
                                        <div>
                                            <p className="rating-number">{approvedMentors?.ratingsAverage}</p>
                                        </div>
                                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <ReportModal
        reportModal={reportModal}
        setReportModal={setReportModal}
        subject={subject}
        setSubject={setSubject}
        description={description}
        setDescription={setDescription}
        sendReportMessage={sendReportMessage}
        item={item}
        setItem={setItem}
        loading={loading}
        setLoading={loading}
      />
    </>
  );
}

export default DeActivatedAccountDetails;
