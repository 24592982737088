import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Get, Post } from "../components/Axios/AxiosFunctions";
import { nodeApiUrl, NODE_URL, baseUrl } from "../components/Config/apiURL";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";

const Stats_CSV = () => {
  const selection = ["day", "week", "month", "year"];
  const statusArray = ["completed", "resolved", "pending", "cancelled"];
  const [buttonChoice, setButtonChoice] = useState("");
  const [sheetLoading, setSheetLoading] = useState(false);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [bookingStatus, setBookingStatus] = useState(statusArray[0]);

  // start date states
  const [startDateForSignUp, setStartDateForSignUp] = useState(new Date());
  const [startDateForBooking, setStartDateForBooking] = useState(new Date());
  const [startDateForAppliedMentors, setStartDateForAppliedMentors] = useState(
    new Date()
  );

  // end date states
  const [endDateForSignUp, setEndDateForSignUp] = useState(new Date());
  const [endDateForBooking, setEndDateForBooking] = useState(new Date());
  const [endDateForAppliedMentors, setEndDateForAppliedMentors] = useState(
    new Date()
  );

  // selections states
  const [signUpCsvChoice, setSignUpCsvChoice] = useState(selection[0]);
  const [bookingCsvChoice, setBookingCsvChoice] = useState(selection[0]);
  const [appliedMentorsCsvChoice, setAppliedMentorsCsvChoice] = useState(
    selection[0]
  );

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const handleDownloadSheet = async (start, end, selection, buttonChoice) => {
    setSheetLoading(true);
    let tomorrow = new Date(start);
    let _endDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));

    let url =
      buttonChoice === "signup"
        ? "users/statistics/signup"
        : buttonChoice === "bookings"
        ? "booking/statistics"
        : "mentor/applied-for-mentor/statistics";

    const sheetURL = NODE_URL(url);

    let myStatus = [];
    if (buttonChoice === "bookings" && bookingStatus === "completed") {
      myStatus.push("resolved", bookingStatus);
    } else if (buttonChoice === "bookings") {
      myStatus.push(bookingStatus);
    }

    let params = {
      start: start,
      end: end === null ? _endDate : end,
      day: selection === "day" ? true : false,
      week: selection === "week" ? true : false,
      month: selection === "month" ? true : false,
      year: selection === "year" ? true : false,
    };

    if (buttonChoice === "bookings") {
      params._BookingStatus = myStatus;
    }
    console.log({ params });

    const responseData = await Post(sheetURL, params, authHeader);
    if (responseData !== undefined) {
      const link = document.createElement("a");
      link.href = `${baseUrl}${responseData.data.path}`;
      link.setAttribute("download", `mechanic.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setSheetLoading(false);
  };

  const getMentorProfileViewsStats = async () => {
    setSheetLoading(true);
    const sheetUrl = NODE_URL("mentor/mentors-profile-views/statistics");
    const responseData = await Get(sheetUrl, accessToken);
    if (responseData !== undefined) {
      const link = document.createElement("a");
      link.href = `${baseUrl}${responseData.data.path}`;
      link.setAttribute("mentor_profile_views", `mechanic.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setSheetLoading(false);
  };

  const DropDown = ({ array, setState, state, placeHolder }) => {
    return (
      <FormControl style={{ width: "200px" }}>
        <InputLabel id="demo-simple-select-label">{placeHolder}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state}
          label={placeHolder}
          onChange={(event) => {
            setState(event.target.value);
          }}
        >
          {array?.map((ele) => {
            return <MenuItem value={ele}>{ele}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  };

  const onChangeSignUpDates = (dates) => {
    console.log(dates);
    setStartDateForSignUp(dates[0]);
    setEndDateForSignUp(dates[1]);
  };

  const onChangeAppliedMentorsDates = (dates) => {
    console.log(dates);
    setStartDateForAppliedMentors(dates[0]);
    setEndDateForAppliedMentors(dates[1]);
  };

  const onChangeBookingDates = (dates) => {
    console.log(dates);
    setStartDateForBooking(dates[0]);
    setEndDateForBooking(dates[1]);
  };

  return (
    <div className="container-fluid stats_csv">
      <h1 className="h3 mb-2 text-gray-800">Stats CSV</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row">
            {/* ------------------ SIGN-UP STATS COLUMN --------------- */}
            <div className="col-md-3 d-flex flex-column  justify-content-start align-items-center">
              <h5>Sign-Up Statistics</h5>
              <button
                className="btn stats-btn mt-4 mb-4"
                disabled={sheetLoading}
                onClick={() => {
                  setButtonChoice("signup");
                  handleDownloadSheet(
                    startDateForBooking,
                    endDateForBooking,
                    bookingCsvChoice,
                    "signup"
                  );
                }}
              >
                {buttonChoice === "signup" && sheetLoading
                  ? "Downloading..."
                  : "Download CSV File"}
              </button>

              {/* Date Picker SIGN_UP STATS */}
              <div className="mb-4">
                <DatePicker
                  className="date-picker-stats"
                  selected={startDateForSignUp}
                  onChange={onChangeSignUpDates}
                  startDate={startDateForSignUp}
                  endDate={endDateForSignUp}
                  selectsRange
                />
              </div>

              <div className="mb-4">
                <DropDown
                  setState={setSignUpCsvChoice}
                  state={signUpCsvChoice}
                  placeHolder={"Selection By:"}
                  array={selection}
                />
              </div>
            </div>

            {/* ------------------ BOOKINGS STATS COLUMN --------------- */}
            <div className="col-md-3 d-flex flex-column align-items-center">
            <h5>Bookings Statistics</h5>
              <button
                className="btn stats-btn mt-4 mb-4"
                disabled={sheetLoading}
                onClick={() => {
                  setButtonChoice("bookings");

                  handleDownloadSheet(
                    startDateForBooking,
                    endDateForBooking,
                    bookingCsvChoice,
                    "bookings"
                  );
                }}
              >
                {buttonChoice === "bookings" && sheetLoading
                  ? "Downloading..."
                  : "Download CSV File"}
              </button>

              {/* Date Picker BOOKINGS STATS */}
              <div className="mb-4">
                <DatePicker
                  className="date-picker-stats"
                  selected={startDateForBooking}
                  onChange={onChangeBookingDates}
                  startDate={startDateForBooking}
                  endDate={endDateForBooking}
                  selectsRange
                />
              </div>

              <div className="mb-4">
                <DropDown
                  setState={setBookingCsvChoice}
                  state={bookingCsvChoice}
                  placeHolder={"Selection By:"}
                  array={selection}
                />
              </div>

              {/* Status Dropdown BOOKINGS STATS */}
              <div className="mb-4">
                <DropDown
                  setState={setBookingStatus}
                  state={bookingStatus}
                  placeHolder={"Status:"}
                  array={statusArray}
                />
              </div>
            </div>

            {/* ------------------ APPLIED MENTORS STATS COLUMN --------------- */}
            <div className="col-md-3 d-flex flex-column  justify-content-start align-items-center">
            <h5 className='text-center'>No. of Applied Mentors Statistics</h5>
              <button
                className="btn stats-btn mb-4"
                disabled={sheetLoading}
                onClick={() => {
                  setButtonChoice("applied_mentors");
                  handleDownloadSheet(
                    startDateForAppliedMentors,
                    endDateForAppliedMentors,
                    appliedMentorsCsvChoice,
                    "applied_mentors"
                  );
                }}
              >
                {buttonChoice === "applied_mentors" && sheetLoading
                  ? "Downloading..."
                  : "Download CSV File"}
              </button>

              {/* Date Picker BOOKINGS STATS */}
              <div className="mb-4">
                <DatePicker
                  className="date-picker-stats"
                  selected={startDateForAppliedMentors}
                  onChange={onChangeAppliedMentorsDates}
                  startDate={startDateForAppliedMentors}
                  endDate={endDateForAppliedMentors}
                  selectsRange
                />
              </div>

              <div className="mb-4">
                <DropDown
                  setState={setAppliedMentorsCsvChoice}
                  state={appliedMentorsCsvChoice}
                  placeHolder={"Selection By:"}
                  array={selection}
                />
              </div>
            </div>

            {/* ------------------ MENTOR PROFILE VIEWS STATS COLUMN --------------- */}
            <div className="col-md-3 d-flex flex-column  justify-content-start align-items-center">
            <h5 className='text-center'>Mentors Profile Views Statistics</h5>
              <button
                className="btn stats-btn mb-4"
                disabled={sheetLoading}
                onClick={() => {
                  setButtonChoice("mentor_profile_views");

                  getMentorProfileViewsStats();
                }}
              >
                {buttonChoice === "mentor_profile_views" && sheetLoading
                  ? "Downloading..."
                  : "Download CSV File"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats_CSV;
