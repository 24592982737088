import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CMSHowDoesItWorkModal from "./CMSHowDoesItWorkModal";
import CMSTestimonalTableModal from "./CMSTestimonalTableModal";

const CMSHowDoesItWorkTable = ({
  title,
  setTitle,
  data,
  setData,
  image,
  setImage,
  imageFile,
  setImageFile,
  description,
  setDescription,
  handleSelectImage,
  location,
  setLocation,
  handleDeleteTestimonal,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="card shadow mb-4">
      <div className="col-md-12 cms-table mt-3">
        <div className="d-flex mb-3 justify-content-between">
          <h3 className="ml-3 mr-3">How Does It Work</h3>
          <button className="btn pick-image" onClick={() => setOpenModal(true)}>
            + New Content
          </button>
        </div>
        <table class="table ml-2">
          <thead class="thead-light">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((e, i) => {
              return (
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{e?.title}</td>
                  <td>{e?.description}</td>
                  <td>
                    <img src={e?.image?.default} className="cms__tableImage" />
                  </td>
                  <td>
                    <button
                      className="btn decline-btn"
                      onClick={() => {
                        handleDeleteTestimonal(e, i);
                      }}
                    >
                      {"Remove"}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
        {/* CMSTestimonalTableModal */}
        <CMSHowDoesItWorkModal
          title={title}
          setTitle={setTitle}
          data={data}
          setData={setData}
          open={openModal}
          setOpen={setOpenModal}
          image={image}
          setImage={setImage}
          imageFile={imageFile}
          setImageFile={setImageFile}
          description={description}
          setDescription={setDescription}
          handleSelectImage={handleSelectImage}
          // handleDeleteTestimonal={handleDeleteTestimonal}
        />
      </div>
    </div>
  );
};

export default CMSHowDoesItWorkTable;
