import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";

const EditMenteeDetailsModal = ({
  type,
  editMenteeDetailModal,
  setEditMenteeDetailModal,

  editDisplayName,
  setEditDisplayName,

  editCoverPhoto,
  setEditCoverPhoto,

  editPhoto,
  setEditPhoto,

  editCountry,
  setEditCountry,

  editLanguage,
  setEditLanguage,

  editDob,
  setEditDob,

  editMenteeBalance,
  setEditMenteeBalance,

  editPhoneNumber,
  setEditPhoneNumber,

  editIntroduction,
  setEditIntroduction,

  uploadCoverImages,
  uploadDisplayImages,

  setMentorCommission,
  mentorCommission,

  item,
  index,
  editMentee,
  modalLoading,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered "
        isOpen={editMenteeDetailModal}
        toggle={() => setEditMenteeDetailModal(false)}
      >
        {/* <ModalBody> */}
        <div className="cancelModal">
          <i
            className="fas fa-times-circle"
            onClick={() => setEditMenteeDetailModal(false)}
          ></i>
        </div>
        <div
          className="addlang-modalBody editMenteeDetail-Body scrollbar"
          id="style-4"
        >
          <h2 className="text-center mb-2" style={{ color: "#b9b8b8" }}>
            Edit Mentee Details
          </h2>
          <div className="innerContainer ">
            <div className="editMenteeDetail-Body-Inner">
              {/* <div className="form-group">            
                <input
                    type="file"
                    id="InputCoverImage"
                    accept="image/png, image/jpeg"
                    onChange={(e) =>
                    uploadDisplayImages(e)
                    }
                />
            </div>
            <div className="form-group">
                <input
                    type="file"
                    id="InputCoverImage"
                    accept="image/png, image/jpeg"
                    onChange={(e) =>
                        uploadCoverImages(e)
                    }
                />
            </div> */}
              <div className="form-group">
                <label className="m-0" style={{ color: "#fff" }}>
                  Display Name
                </label>
                <input
                  type="text"
                  placeholder="Display Name"
                  value={editDisplayName}
                  onChange={(e) => setEditDisplayName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="m-0" style={{ color: "#fff" }}>
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Country"
                  value={editCountry}
                  onChange={(e) => setEditCountry(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="m-0" style={{ color: "#fff" }}>
                  Language
                </label>
                <input
                  type="text"
                  placeholder="Language"
                  value={editLanguage}
                  onChange={(e) => setEditLanguage(e.target.value)}
                />
              </div>
              {/* <div className="form-group">
              <input
                type="text"
                placeholder="Date of Birth"
                value={editDob}
                onChange={(e) => setEditDob(e.target.value)}
              />
            </div> */}

              <div className="form-group">
                <label className="m-0" style={{ color: "#fff" }}>
                  Balance
                </label>
                <input
                  type="number"
                  placeholder="Balance"
                  value={editMenteeBalance}
                  onChange={(e) => setEditMenteeBalance(e.target.value)}
                />
              </div>
              {/* <div className="form-group">
                <label className="m-0" style={{ color: "#fff" }}>
                  Phone Number
                </label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  value={editPhoneNumber}
                  onChange={(e) => setEditPhoneNumber(e.target.value)}
                />
              </div> */}
              {type !== "mentor" && (
                <div className="form-group">
                  <label className="m-0" style={{ color: "#fff" }}>
                    {" "}
                    Introduction
                  </label>
                  <textarea
                    className="editMentee-textBox"
                    type="text"
                    placeholder="Introduction"
                    value={editIntroduction}
                    onChange={(e) => setEditIntroduction(e.target.value)}
                  />
                </div>
              )}

              {type === "mentor" && (
                <div className="form-group">
                  <label className="m-0" style={{ color: "#fff" }}>
                    {" "}
                    About
                  </label>
                  <textarea
                    className="editMentee-textBox"
                    type="text"
                    placeholder="About"
                    value={editIntroduction}
                    onChange={(e) => setEditIntroduction(e.target.value)}
                  />
                </div>
              )}

              {type === "mentor" && (
                <div className="form-group">
                  <label className="m-0" style={{ color: "#fff" }}>
                    Commission
                  </label>
                  <input
                    className="editMentee-textBox"
                    type="number"
                    placeholder="Commission"
                    value={mentorCommission}
                    onChange={(e) => setMentorCommission(e.target.value)}
                  />
                </div>
              )}
            </div>

            {/* <div className="datePicker-container">    
                <div className="DatePicker">
                    <DatePicker
                        className="DatePickerBox"
                        value={editDob}
                        onChange={setEditDob}
                    />
                </div>
            </div> */}

            <div className="saveBtn-Container">
              <Button
                className="btn btn-user btn-block btn-save"
                disabled={editDisplayName?.length > 0 ? false : true}
                onClick={() => {
                  // setEditMenteeDetailModal(false);
                  editMentee(item, index);
                }}
              >
                {modalLoading ? "Loading.." : "Save"}
              </Button>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default EditMenteeDetailsModal;
