import React from 'react'

function PaymentRequestDetails() {

    return (
        <div>
            <h2>payent</h2>
        </div>
    )
}

export default PaymentRequestDetails
