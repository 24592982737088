import {
  NEW_MENTOR_REQUEST,
  MENTOR_CHANGES,
  APPROVED_MENTOR,
  REPORT_BY,
  DEACTIVATED_DETAILS,
  ALL_MENTEE,
  DISPUTES,
  UNRESOLVED_SESSIONS
  // MENTEEDETAILS
} from "../actions/actionTypes";

const initState = {
  unApprovedMentors: {},
  mentorChanges: {},
  approvedMentor: {},
  reportedBy: {},
  deactivatedDetail: {},
  menteeDetail: {},
  unresolvedDetail: {},
};
const mentorReducer = (state = initState, action) => {
  switch (action.type) {
    case NEW_MENTOR_REQUEST:
      return {
        unApprovedMentors: action?.data,
      };
    case MENTOR_CHANGES:
      console.log(action);
      return {
        mentorChanges: action?.data,
      };
    case APPROVED_MENTOR:
      console.log(action);
      return {
        approvedMentor: action?.data,
      };
    case REPORT_BY:
      console.log(action);
      return {
        reportedBy: action?.data,
      };
    case DEACTIVATED_DETAILS:
      console.log(action);
      return {
        deactivatedDetail: action?.data,
      };
    case DISPUTES:
      return {
        disputeDetail: action?.data,
      };
      case UNRESOLVED_SESSIONS :
        return {
          unresolvedDetail : action?.data
        }
    case ALL_MENTEE:
      return {
        menteeDetail: action?.data,
      };
    default:
      return state;
  }
};

export default mentorReducer;
