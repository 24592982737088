import { LOGIN_SUCCESS } from "./actionTypes";
export const islogin = (data) => {
  console.log("...",data)
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_SUCCESS, data });
  };
};
export const signUpAction = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "SIGN_UP_ACTION", data });
  };
};
export const signOutAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SIGN_OUT" });
  };
};
