import React, { useEffect, useState } from "react";
import { loginVector, coverPhoto } from "../constant/imagepath";
import StarRatings from "react-star-ratings/build/star-ratings";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import EditMenteeDetailsModal from "../components/EditMenteeDetailsModal";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import { Delete, Get, Patch } from "../components/Axios/AxiosFunctions";
import { nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import { getMentee } from "../store/actions/mentorActions";
import moment from "moment";
import ConfirmModal from "../components/ConfirmModal";
import { useHistory } from "react-router";

function MenteeDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const menteeData = useSelector((state) => state.mentorReducer.menteeDetail);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [editMenteeDetailModal, setEditMenteeDetailModal] = useState(false);
  const [editDisplayName, setEditDisplayName] = useState("");
  const [editCoverPhoto, setEditCoverPhoto] = useState("");
  const [editPhoto, setEditPhoto] = useState("");
  const [editCountry, setEditCountry] = useState("");
  const [editLanguage, setEditLanguage] = useState("");
  const [editDob, setEditDob] = useState("");
  const [editMenteeBalance, setEditMenteeBalance] = useState("");
  const [editPhoneNumber, setEditPhoneNumber] = useState("");
  const [editIntroduction, setEditIntroduction] = useState("");
  const [item, setItem] = useState({});
  const [index, setIndex] = useState("");
  const [amount, setAmount] = useState(0);
  const [modalLoading, setModalLoading] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  //   const [menteeDetail, setMenteeDetail] = useState([])

  //   const imgAuthHeader = {
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  // upload display Image
  const uploadDisplayImages = async (event) => {
    setEditPhoto(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  // upload cover Image
  const uploadCoverImages = async (event) => {
    setEditCoverPhoto(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  //   const createFormdata = async (data) => {
  //     //  console.log({data})
  //     var formData = new FormData();
  //     console.log({ formData });
  //       formData.append("displayName", editDisplayName);
  //     formData.append("_id", data?._id);
  //       formData.append( "coverPhoto", editCoverPhoto);
  //       formData.append( "photo", editPhoto);
  //       formData.append( "country", editCountry);
  //       formData.append("preferredLanguage", editLanguage);
  //       formData.append("dob", editDob);
  //       formData.append("menteeBalance", editMenteeBalance);
  //     formData.append("phoneNumber", editPhoneNumber);
  //       formData.append("introduction", editIntroduction);
  //     return formData;
  //   };

  //   // Edit Api
  const editMentee = async (item, index) => {
    console.log(item);
    setEditDisplayName(item?.displayName);
    // setEditCoverPhoto(item?.coverPhoto)
    // setEditPhoto(item?.photo)
    setEditCountry(item?.country);
    setEditLanguage(item?.preferredLanguage);
    setEditDob(item?.dob);
    setEditMenteeBalance(item?.menteeBalance);
    setEditPhoneNumber(item?.phoneNumber);
    setEditIntroduction(item?.introduction);

    const editUrl = NODE_URL(`mentor/updateMenteesOrMentors`);
    setModalLoading(true);
    let obj = {
      id: menteeData?._id,
      displayName: editDisplayName,
      UserId: menteeData?.UserId?._id,
      //   coverPhoto: editCoverPhoto,
      //   photo: editPhoto,
      country: editCountry,
      preferredLanguage: editLanguage,
      dob: editDob,
      menteeBalance: editMenteeBalance,
      phoneNumber: editPhoneNumber,
      introduction: editIntroduction,
      mode: 'mentee'
    };

    // let returnFormData = await createFormdata(obj);
    // console.log({ returnFormData });
    // const responseData = await Patch(editUrl, returnFormData, imgAuthHeader);
    const responseData = await Patch(editUrl, obj, authHeader);
    if (responseData !== undefined) {
      // menteeData(responseData?.data?.data)
      dispatch(getMentee(responseData?.data?.data));
      toast.success("Mentee updated");
      //   let oldData = addVoucher.slice();
      //   oldData.push(responseData?.data?.data)
      //   console.log({oldData})
      //   setAddVoucher(oldData)

      setEditMenteeDetailModal(false);
    }
    setModalLoading(false);
  };

  const format1 = "YYYY-MM-DD";
  console.log(menteeData);

  const deleteMentee = async () => {
    setIsApiCall(true);
    const deleteURL = NODE_URL(
      `users/delete-account/${menteeData?.UserId?._id}`
    );
    const responseData = await Delete(deleteURL, authHeader);
    if (responseData !== undefined) {
      console.log("Mentee Delete APi: ", responseData?.data?.data);
      setDeleteConfirmModal(false);
      history.push("/AllMentees");
      toast.success("Deleted Successfully!");
    } else {
      console.log("error delete api");
    }
    setIsApiCall(false);
  };

  const giveGoodwill = async () => {
    console.log(amount);
    if (amount <= 0) {
      toast.success("Please enter valid amount!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      let params = {
        userMode: "mentee",
        amount: amount,
      };
      const goodwilURL = NODE_URL(
        `admin/goodwill/individual/${menteeData?._id}`
      );
      setIsApiCall(true);
      const resData = await Patch(goodwilURL, params, authHeader);

      if (resData !== undefined) {
        toast.success(`🙌 Goodwill given to ${menteeData?.displayName}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setAmount(0);
      } else {
        console.log("error");
      }
      setIsApiCall(false);
    }
  };
  return (
    <>
      <div className="container-fluid ApprovedMentorDetails">
        <h1 className="h3 mb-2 text-gray-800">Mentee Details</h1>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">DataTables Example</h6> */}
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="edit-Btn">
                    <Button
                      className={`btn btn-user btn-block ${"btn-pending"}`}
                      onClick={() => {
                        setEditMenteeDetailModal(true);
                        setItem(item);
                        setIndex(index);
                        setEditDisplayName(menteeData.displayName);
                        //   setEditCoverPhoto(menteeData.coverPhoto);
                        //   setEditPhoto(menteeData.photo);
                        setEditCountry(menteeData.country);
                        setEditLanguage(menteeData.preferredLanguage);
                        setEditDob(menteeData.dob);
                        setEditMenteeBalance(menteeData.menteeBalance);
                        setEditPhoneNumber(menteeData.phoneNumber);
                        setEditIntroduction(menteeData.introduction);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                <div className="delete-Btn mr-3">
                  <Button
                    className={`btn btn-user btn-block ${"btn-pending"}`}
                    onClick={() => {
                      setDeleteConfirmModal(true);
                    }}
                  >
                    Delete Account
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-12">
                <div className="cover-photo">
                  <img
                    src={`${nodeImageUrl}/${menteeData?.coverPhoto}`}
                    alt=""
                    className="image-fluid"
                  />
                </div>
              </div> */}
              <div className="col-md-2">
                <div className="profile">
                  <img
                    src={`${nodeImageUrl}/${menteeData?.photo}`}
                    alt=""
                    className="image-fluid"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <h2>{menteeData?.displayName}</h2>
                  <p className="location-lang">
                    Country : {menteeData?.country}, {menteeData?.city}
                  </p>
                  <p className="location-lang">
                    Language : {menteeData?.preferredLanguage}
                  </p>
                  <p className="location-lang">
                    DOB : {moment(menteeData?.dob).format(format1)}
                  </p>
                </div>
                <div className="rating">
                  <div className="star">
                    <StarRatings
                      rating={menteeData?.ratingsAverage}
                      starDimension="20px"
                      starRatedColor="orange"
                      starSpacing="0px"
                    />
                  </div>
                  <div>
                    <p className="rating-number">
                      {menteeData?.ratingsAverage}
                    </p>
                  </div>
                </div>
                <div className="lesson-mentees">
                  <p className="menteeRated-text">
                    Balance : {menteeData?.menteeBalance}
                  </p>
                  <p className="menteeRated-text">
                    Phone Number : {menteeData?.phoneNumber}
                  </p>
                </div>

                <div className="mt-3">
                  <p className="aboutMe-text">About Me</p>
                  <p className="aboutMe-para">{menteeData?.introduction}</p>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
                <h2 className="mb-3">Enter Giveaway Amount:</h2>
                <input
                  type="number"
                  className="form-control"
                  s
                  style={{ width: "250px" }}
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />

                <button
                  type="button"
                  disabled={isApiCall}
                  className={`mt-4 btn giveaway-applied `}
                  onClick={() => {
                    giveGoodwill();
                  }}
                >
                  
                  {isApiCall ? "Please Wait" : "Send Goodwill"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <EditMenteeDetailsModal
        editMenteeDetailModal={editMenteeDetailModal}
        setEditMenteeDetailModal={setEditMenteeDetailModal}
        editDisplayName={editDisplayName}
        setEditDisplayName={setEditDisplayName}
        editCoverPhoto={editCoverPhoto}
        setEditCoverPhoto={setEditCoverPhoto}
        editPhoto={editPhoto}
        setEditPhoto={setEditPhoto}
        editCountry={editCountry}
        setEditCountry={setEditCountry}
        editLanguage={editLanguage}
        setEditLanguage={setEditLanguage}
        editDob={editDob}
        setEditDob={setEditDob}
        editMenteeBalance={editMenteeBalance}
        setEditMenteeBalance={setEditMenteeBalance}
        editPhoneNumber={editPhoneNumber}
        setEditPhoneNumber={setEditPhoneNumber}
        editIntroduction={editIntroduction}
        setEditIntroduction={setEditIntroduction}
        uploadCoverImages={uploadCoverImages}
        uploadDisplayImages={uploadDisplayImages}
        item={item}
        index={index}
        editMentee={editMentee}
        modalLoading={modalLoading}
      />

      <ConfirmModal
        isModalVisible={deleteConfirmModal}
        setIsModalVisible={setDeleteConfirmModal}
        onClickYes={() => {
          deleteMentee();
        }}
        onClickNo={() => {
          setDeleteConfirmModal(false);
        }}
        isApiCall={isApiCall}
        confirmTextLine={"Are you sure you want to delete this mentee account?"}
      />
    </>
  );
}

export default MenteeDetails;
