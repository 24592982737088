// import React from "react";
// import "../assets/css/cms.css";
// import { useState } from "react/cjs/react.development";
// import { nodeApiUrl, NODE_URL } from "../components/Config/apiURL";
// import CMSCardTable from "../components/CMSCardTable";
// import CMSTestimonalTable from "../components/CMSTestimonalTable";
// import { Delete } from "../components/Axios/AxiosFunctions";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
// import CMSHowDoesItWorkTable from "../components/CMSHowDoesItWorkTable";

// const HomeScreen = () => {
//   // Page Main states
//   const [pageMainTitle, setPageMaintitle] = useState("");
//   const [pageMainImage, setPageMainImage] = useState(null);
//   const [pageMainImageFile, setPageMainImageFile] = useState(null);

//   // last section states
//   const [lastSecTitle, setLastSectitle] = useState("");
//   const [lastSecImage, setLastSecImage] = useState(null);
//   const [lastSecImageFile, setLastSecImageFile] = useState(null);

//   //  CMS Card table
//   const [cmsCardTableTitle, setCmsCardTableTitle] = useState("");
//   const [CMSCardTableData, setCMSCardTableData] = useState(
//     CMSCardTableDummyData
//   );
//   const [cmsCardTableImage, setCmsCardTableImage] = useState(null);
//   const [cmsCardTableImageFile, setCmsCardTableImageFile] = useState(null);
//   const [cmsCardTableIcon, setCmsCardTableIcon] = useState(null);
//   const [cmsCardTableIconFile, setCmsCardTableIconFile] = useState(null);
//   const accessToken = useSelector((state) => state.globalReducer.accessToken);
//   const [isDeleting, setIsDeleting] = useState(false);

//   // CMS Testimonal states
//   const [CMSTestimonalTableData, setCMSTestimonalTableData] = useState(
//     CMSTestimonalDummyData
//   );
//   const [cmsTestimonalTableUsername, setCmsTestimonalTableUsername] =
//     useState(null);
//   const [cmsTestimonalTableDescr, setCmsTestimonalTableDescr] = useState(null);
//   const [cmsTestimonalTableImage, setCmsTestimonalTableImage] = useState(null);
//   const [cmsTestimonalTableImageFile, setCmsTestimonalTableImageFile] =
//     useState(null);
//   const [cmsTestimonalTableLoc, setCmsTestimonalTableLoc] = useState(null);

//   // CMS How Does It Work States
//   const [CMSHowDoesItWorkTableData, setCMSHowDoesItWorkTableData] = useState(
//     CMSHowDoesItWorkDummyData
//   );
//   const [cmsHowDoesItWorkTableDescr, setCmsHowDoesItWorkTableDescr] =
//     useState(null); // description
//   const [cmsHowDoesItWorkTableTitle, setCmsHowDoesItWorkTableTitle] =
//     useState(null); // title
//   const [cmsHowDoesItWorkTableImage, setCmsHowDoesItWorkTableImage] =
//     useState(null); // image
//   const [cmsHowDoesItWorkTableImageFile, setCmsHowDoesItWorkTableImageFile] = // image file
//     useState(null);

//   const authHeader = {
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//   };
//   //   handleSelectImage
//   const handleSelectImage = (event, file, state) => {
//     file(event.target.files[0]);
//     let reader = new FileReader();
//     reader.onload = (event) => {
//       state(event.target.result);
//     };
//     reader.readAsDataURL(event.target.files[0]);
//   };

//   const handleDeleteItem = async (item, index) => {
//     // setIsDeleting(true);
//     // const url = NODE_URL(`${item?._id}`);
//     // const resData = await Delete(url, authHeader);
//     // if (resData !== undefined) {
//     //   let copyData = [...CMSCardTableData];
//     //   copyData?.splice(index, 1);
//     //   setCMSCardTableData(copyData);
//     //   toast?.success("Item Removed Successfully!");
//     // } else {
//     //   console.log("error");
//     // }
//     // setIsDeleting(false);
//   };

//   const handleDeleteTestimonal = async (item, index) => {
//     // setIsDeleting(true);
//     // const url = NODE_URL(`${item?._id}`);
//     // const resData = await Delete(url, authHeader);
//     // if (resData !== undefined) {
//     //   let copyData = [...CMSTestimonalTableData];
//     //   copyData?.splice(index, 1);
//     //   setCMSTestimonalTableData(copyData);
//     //   toast?.success("Item Removed Successfully!");
//     // } else {
//     //   console.log("error");
//     // }
//     // setIsDeleting(false);
//   };
//   return (
//     <div className="p-2">
//       <h1 className="mb-4 ml-4">HOME SCREEN</h1>

//       <div className="m-2 col-md-6">
//         <div class="form-group">
//           <label for="exampleInputEmail1">Page Main Title</label>
//           <input
//             type="text"
//             className="form-control text-input-cms"
//             type="text"
//             placeholder="Add Title"
//             value={pageMainTitle}
//             onChange={(e) => setPageMaintitle(e.target.value)}
//           />
//         </div>

//         <div className="seo__imagePicker">
//           <input
//             type="file"
//             name="uploadfile"
//             id="img"
//             style={{
//               display: "none",
//             }}
//             onChange={(event) => {
//               handleSelectImage(event, setPageMainImageFile, setPageMainImage);
//             }}
//           />
//           <label for="exampleInputEmail1">Select Main Image</label>
//           <label
//             for="img"
//             className="btn ml-3 pick-image"
//             onChange={(e) => {
//               console.log(e.target.files[0]);
//             }}
//           >
//             {pageMainImage ? "Change selection" : "Pick an Image"}
//           </label>
//         </div>

//         {/* Show Image */}
//         {pageMainImage && (
//           <div>
//             <img
//               alt="not fount"
//               src={
//                 pageMainImageFile == null
//                   ? `${nodeApiUrl}/${pageMainImage}`
//                   : pageMainImage
//               }
//               className="cms__mainImage"
//             />
//           </div>
//         )}
//       </div>

//       <CMSCardTable
//         title={cmsCardTableTitle}
//         setTitle={setCmsCardTableTitle}
//         data={CMSCardTableData}
//         setData={setCMSCardTableData}
//         image={cmsCardTableImage}
//         setImage={setCmsCardTableImage}
//         imageFile={cmsCardTableImageFile}
//         setImageFile={setCmsCardTableImageFile}
//         icon={cmsCardTableIcon}
//         setIcon={setCmsCardTableIcon}
//         iconFile={cmsCardTableIconFile}
//         setIconFile={setCmsCardTableIconFile}
//         handleSelectImage={handleSelectImage}
//         handleDeleteItem={handleDeleteItem}
//       />

//       {/* testimonals */}
//       <CMSTestimonalTable
//         username={cmsTestimonalTableUsername}
//         setUsername={setCmsTestimonalTableUsername}
//         data={CMSTestimonalTableData}
//         setData={setCMSTestimonalTableData}
//         image={cmsTestimonalTableImage}
//         setImage={setCmsTestimonalTableImage}
//         imageFile={cmsTestimonalTableImageFile}
//         setImageFile={setCmsTestimonalTableImageFile}
//         description={cmsTestimonalTableDescr}
//         setDescription={setCmsTestimonalTableDescr}
//         location={cmsTestimonalTableLoc}
//         setLocation={setCmsTestimonalTableLoc}
//         handleSelectImage={handleSelectImage}
//         handleDeleteTestimonal={handleDeleteTestimonal}
//       />

//       {/* how does it work  */}
//       <CMSHowDoesItWorkTable
//         title={cmsHowDoesItWorkTableTitle}
//         setTitle={setCmsHowDoesItWorkTableTitle}
//         data={CMSHowDoesItWorkTableData}
//         setData={setCMSHowDoesItWorkTableData}
//         image={cmsHowDoesItWorkTableImage}
//         setImage={setCmsHowDoesItWorkTableImage}
//         imageFile={cmsHowDoesItWorkTableImageFile}
//         setImageFile={setCmsHowDoesItWorkTableImageFile}
//         description={cmsHowDoesItWorkTableDescr}
//         setDescription={setCmsHowDoesItWorkTableDescr}
//         handleSelectImage={handleSelectImage}
//         // handleDeleteTestimonal={handleDeleteTestimonal}
//       />
//       <div className="m-2 col-md-6 col-xm-12">
//         {/* last section title */}
//         <div class="form-group">
//           <label for="exampleInputEmail1">Last Section Title</label>
//           <input
//             type="text"
//             className="form-control text-input-cms"
//             type="text"
//             placeholder="Add Title"
//             value={lastSecTitle}
//             onChange={(e) => setLastSectitle(e.target.value)}
//           />
//         </div>

//         {/* Last Section Image */}
//         <div class="form-group" className="seo__imagePicker">
//           <input
//             type="file"
//             name="uploadfile"
//             id="img2"
//             style={{
//               display: "none",
//             }}
//             onChange={(event) => {
//               handleSelectImage(event, setLastSecImageFile, setLastSecImage);
//             }}
//           />
//           <label for="exampleInputEmail1">
//             Select Last Section Background Image
//           </label>
//           <label
//             for="img2"
//             className="btn ml-3 pick-image"
//             onChange={(e) => {
//               console.log(e.target.files[0]);
//             }}
//           >
//             {lastSecImage ? "Change selection" : "Pick an Image"}
//           </label>
//         </div>

//         {/* Shoe Image */}
//         {lastSecImage && (
//           <div>
//             <img
//               alt="not found"
//               src={
//                 lastSecImageFile == null
//                   ? `${nodeApiUrl}/${lastSecImage}`
//                   : lastSecImage
//               }
//               className="cms__mainImage"
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default HomeScreen;

// const CMSCardTableDummyData = [
//   {
//     id: 1,
//     image:
//       "https://ment-go.herokuapp.com/images/cards-images/caree-life-coach.png",
//     icon: "https://ment-go.herokuapp.com/images/icon2.png",
//     title: "Career/Life Coach",
//   },
//   {
//     id: 2,
//     image:
//       "https://ment-go.herokuapp.com/images/cards-images/spiritiuality.png",
//     icon: "https://ment-go.herokuapp.com/images/icon2.png",
//     title: "Spirituality",
//   },
//   {
//     id: 3,
//     image:
//       "https://ment-go.herokuapp.com/images/cards-images/recovery.png",
//     icon: "https://ment-go.herokuapp.com/images/icon2.png",
//     title: "Recovery",
//   },
//   {
//     id: 4,
//     image:
//       "https://ment-go.herokuapp.com/images/cards-images/identity.png",
//     icon: "https://ment-go.herokuapp.com/images/icon2.png",
//     title: "Identity",
//   },
//   {
//     id: 5,
//     image:
//       "https://ment-go.herokuapp.com/images/cards-images/health-and-beauty.png",
//     icon: "https://ment-go.herokuapp.com/images/icon2.png",
//     title: "Health And Beauty",
//   },
//   {
//     id: 6,
//     image:
//       "https://ment-go.herokuapp.com/images/cards-images/relationships.png",
//     icon: "https://ment-go.herokuapp.com/images/icon2.png",
//     title: "Relationships",
//   },
// ];

// const CMSTestimonalDummyData = [
//   {
//     id: 1,
//     description:
//       "Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum",
//     name: "SHane Brown",
//     image:
//       "https://th.bing.com/th/id/OIP.Wi3JOxPPo3dEhkKTECDQFwHaHa?pid=ImgDet&w=500&h=500&rs=1",
//     location: "New York, USA",
//   },

//   {
//     id: 2,
//     description:
//       "Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum",
//     name: "Elizabeth",
//     image:
//       "https://th.bing.com/th/id/OIP.Wi3JOxPPo3dEhkKTECDQFwHaHa?pid=ImgDet&w=500&h=500&rs=1",
//     location: "New York, USA",
//   },
//   {
//     id: 3,
//     description:
//       "Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum",
//     name: "Lina Paul",
//     image:
//       "https://th.bing.com/th/id/OIP.Wi3JOxPPo3dEhkKTECDQFwHaHa?pid=ImgDet&w=500&h=500&rs=1",
//     location: "New York, USA",
//   },
//   {
//     id: 4,
//     description:
//       "Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum",
//     name: "Batista",
//     image:
//       "https://th.bing.com/th/id/OIP.Wi3JOxPPo3dEhkKTECDQFwHaHa?pid=ImgDet&w=500&h=500&rs=1",
//     location: "New York, USA",
//   },
// ];

// const CMSHowDoesItWorkDummyData = [
//   {
//     id: 1,
//     description: "Read profiles, find a mentor that resonates with you",
//     title: "Choose your Modern Mentor",
//     image: require("../assets/images/work-1.png"),
//   },
//   {
//     id: 2,
//     description: "Schedule appointments at a time and date that work for you",
//     title: "Schedule a Consultation",
//     image: require("../assets/images/work-2.png"),
//   },
//   {
//     id: 2,
//     description: "Connect with your mentor via video chat!",
//     title: "Start your Journey!",
//     image: require("../assets/images/work-3.png"),
//   },
// ];

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NODE_URL, nodeImageUrl } from "../components/Config/apiURL";
import { Get, Patch, Post } from "../components/Axios/AxiosFunctions";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";

import { useHistory } from "react-router";
import ReactQuill from "react-quill";

function HomeScreen() {
  const dispatch = useDispatch();
  const history = useHistory();

  //redux Value
  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  //api Header And Url
  const pageKeysApiURL = NODE_URL(`general/get-system-config/home`);
  const submitApiURL = NODE_URL(`admin/update-system-config`);

  const Header = {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Page Edit State's
  const [pageKeysObject, setPageKeysObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [pageHeading, setPageHeading] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [newCoverImage, setNewCoverImage] = useState(null);

  const [mainImageALT, setMainImageALT] = useState(
    pageKeysObject?.cover_image_alt
  );
  const [sec1ImageALT, setSec1ImageALT] = useState("");
  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState("");
  const [section1Image, setSection1Image] = useState("");
  const [newSection1Image, setNewSection1Image] = useState(null);

  const [section2Title, setSection2Title] = useState("");
  const [section2Description, setSection2Description] = useState("");
  const [section2Image, setSection2Image] = useState("");
  const [newSection2Image, setNewSection2Image] = useState(null);

  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState("");
  const [section3Image, setSection3Image] = useState("");
  const [newSection3Image, setNewSection3Image] = useState(null);

  var formData = new FormData();

  const GetObjectKeysApiMethod = async () => {
    setLoading(true);
    const responseData = await Get(pageKeysApiURL, accessToken);
    if (responseData !== undefined) {
      setPageKeysObject(responseData?.data?.data);
      setPageHeading(responseData?.data?.data?.heading);
      setPageDescription(responseData?.data?.data?.description);
      setCoverImage(responseData?.data?.data?.cover_image);
      setSec1ImageALT(responseData?.data?.data?.sec1CoverImage_alt);
      setMainImageALT(responseData?.data?.data?.cover_image_alt);
      {
        responseData?.data?.data?.sec1Heading !== undefined &&
          setSection1Title(responseData?.data?.data?.sec1Heading);
        setSection1Image(responseData?.data?.data?.sec1CoverImage);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    GetObjectKeysApiMethod();
  }, []);

  const uploadImages = (event, setterFile, setterDisplay) => {
    if (event.target.files.length > 0) {
      setterFile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (event) => {
        setterDisplay(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const createFormData = () => {
    formData.append("_id", pageKeysObject?._id);
    formData.append("pageName", pageKeysObject?.pageName);
    formData.append("heading", pageHeading);
    {
      newCoverImage !== null
        ? formData.append("cover_image", newCoverImage)
        : formData.append("cover_image", coverImage);
    }
    formData.append("cover_image_alt", mainImageALT);
    return formData;
  };

  const createSection1FormData = () => {
    formData.append("sec1Heading", section1Title);
    {
      newSection1Image !== null
        ? formData.append("sec1CoverImage", newSection1Image)
        : formData.append("sec1CoverImage", section1Image);
    }
    formData.append("sec1CoverImage_alt", sec1ImageALT);

    return formData;
  };

  const submit = async () => {
    setSubmitLoading(true);
    let data = await createFormData();
    {
      pageKeysObject?.sec1Heading !== undefined &&
        (data = await createSection1FormData());
    }

    let responseData = await Post(submitApiURL, data, Header);

    if (responseData !== undefined) {
      console.log("response", responseData.data);
      toast.success(`Home Page Content has been updated Successfully!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setSubmitLoading(false);
  };
  return (
    <div className="container-fluid newMentorRequestDetails">
      <div className="d-flex align-items-center">
        <h1
          className="h3 mb-2 text-gray-800"
          style={{ textTransform: "Capitalize" }}
        >
          Home Page Details
        </h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {loading ? (
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <SpinnerRoundOutlined
                  enabled={loading}
                  thickness={200}
                  color="#AB2330"
                />{" "}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="form-group">
                  <label for="InputHeading" className="pages-label-style">
                    Heading
                  </label>
                  <ReactQuill
                    value={pageHeading}
                    onChange={(e) => setPageHeading(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label for="InputCoverImage" className="pages-label-style">
                    Cover Image
                  </label>
                </div>
                <div className="col-md-12">
                  <div className="form-group Image-Cover-Border">
                    <div className="image-upload-avatar">
                      {newCoverImage !== null ? (
                        <img
                          src={coverImage}
                          alt="User Image"
                          className="image-fluid"
                        />
                      ) : coverImage !== "" ? (
                        <img
                          src={`${nodeImageUrl}/${coverImage}`}
                          alt="User Image"
                          className="image-fluid"
                        />
                      ) : (
                        <>
                          <span>
                            <i
                              className="fa fa-upload"
                              style={{ fontSize: 50 }}
                            />
                            <p>Upload Photo</p>
                          </span>
                        </>
                      )}
                      <input
                        type="file"
                        id="InputCoverImage"
                        accept="image/png, image/jpeg"
                        onChange={(e) =>
                          uploadImages(e, setNewCoverImage, setCoverImage)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column">
                    <label
                      for="section1InputHeading"
                      className="pages-label-style"
                    >
                      ALT (Main Image)
                    </label>
                    <input
                      className={"input-field-cms"}
                      value={mainImageALT}
                      onChange={(e) => {
                        setMainImageALT(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Section 1 Start */}
              {pageKeysObject?.sec1Heading !== undefined && (
                <div className="row Section-Container mt-4">
                  <div className="col-md-12">
                    <h4 style={{ textTransform: "uppercase" }}>Section 1:</h4>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        for="section1InputHeading"
                        className="pages-label-style"
                      >
                        Heading
                      </label>

                      <ReactQuill
                        value={section1Title}
                        onChange={(e) => setSection1Title(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group Section-Image-Display">
                      <label
                        for="section1InputImage"
                        className="pages-label-style"
                      >
                        Image
                      </label>
                      <div
                        className="image-upload-avatar"
                        style={{
                          border:
                            newSection1Image !== null || section1Image !== ""
                              ? "0px solid #ced4da"
                              : "1px solid #ced4da",
                        }}
                      >
                        {newSection1Image !== null ? (
                          <img
                            src={section1Image}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : section1Image !== "" ? (
                          <img
                            src={`${nodeImageUrl}/${section1Image}`}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}

                        <input
                          type="file"
                          id="section1InputImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(
                              e,
                              setNewSection1Image,
                              setSection1Image
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column">
                      <label
                        for="section1InputHeading"
                        className="pages-label-style"
                      >
                        ALT (Section 1 Image)
                      </label>
                      <input
                        className={"input-field-cms"}
                        value={sec1ImageALT}
                        onChange={(e) => {
                          setSec1ImageALT(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* Section 1 End */}

              {/* Section 2 start  */}
              {pageKeysObject?.sec2Heading !== undefined && (
                <div className="row Section-Container">
                  <div className="col-md-12">
                    <h4 style={{ textTransform: "uppercase" }}>Section 2:</h4>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        for="section1InputHeading"
                        className="pages-label-style"
                      >
                        Heading
                      </label>

                      <ReactQuill
                        value={section2Title}
                        onChange={(e) => setSection2Title(e)}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        for="section1InputDescription"
                        className="pages-label-style"
                      >
                        Description
                      </label>

                      <ReactQuill
                        value={section2Description}
                        onChange={(e) => setSection2Description(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group Section-Image-Display">
                      <label
                        for="section1InputImage"
                        className="pages-label-style"
                      >
                        Image
                      </label>
                      <div
                        className="image-upload-avatar"
                        style={{
                          border:
                            newSection2Image !== null || section2Image !== ""
                              ? "0px solid #ced4da"
                              : "1px solid #ced4da",
                        }}
                      >
                        {newSection2Image !== null ? (
                          <img
                            src={section2Image}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : section2Image !== "" ? (
                          <img
                            src={`${nodeImageUrl}/${section2Image}`}
                            alt="User Image"
                            className="image-fluid"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}

                        <input
                          type="file"
                          id="section1InputImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(
                              e,
                              setNewSection2Image,
                              setSection2Image
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Section 2 End */}
              <div className="row">
                <div className="col-md-12">
                  <div className="save-container">
                    <Button
                      className="btn btn-user btn-block btn-save"
                      onClick={() => submitLoading == false && submit()}
                      disabled={submitLoading}
                    >
                      {submitLoading == true ? "loading" : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
