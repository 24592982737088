import {
  NEW_MENTOR_REQUEST,
  MENTOR_CHANGES,
  APPROVED_MENTOR,
  REPORT_BY,
  DEACTIVATED_DETAILS,
  ALL_MENTEE,
  DISPUTES,
  UNRESOLVED_SESSIONS,
} from "./actionTypes";

export const getNewMentors = (data) => {
  return (dispatch) => {
    dispatch({ type: NEW_MENTOR_REQUEST, data: data });
  };
};

export const getMentorChanges = (data) => {
  return (dispatch) => {
    dispatch({ type: MENTOR_CHANGES, data: data });
  };
};

export const getApprovedMentor = (data) => {
  return (dispatch) => {
    dispatch({ type: APPROVED_MENTOR, data: data });
  };
};

export const getReportedBy = (data) => {
  return (dispatch) => {
    dispatch({ type: REPORT_BY, data: data });
  };
};

export const getDeactivatedDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: DEACTIVATED_DETAILS, data: data });
  };
};

export const getMentee = (data) => {
  return (dispatch) => {
    dispatch({ type: ALL_MENTEE, data: data });
  };
};

export const getDisputeDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: DISPUTES, data: data });
  };
};

export const getUnresolvedSessionDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: UNRESOLVED_SESSIONS, data: data });
  };
};
