import React, { useState, useEffects } from "react";
import { loginVector, coverPhoto } from "../constant/imagepath";
import StarRatings from "react-star-ratings/build/star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { nodeImageUrl } from "../components/Config/apiURL";
import { NODE_URL, baseUrl } from "../components/Config/apiURL";
import { getReportedBy } from "../store/actions/mentorActions";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import ReportModal from "../components/ReportModal";
import { ToastContainer, toast } from "react-toastify";

function ReportDetails() {
  const reportedByDetail = useSelector(
    (state) => state.mentorReducer.reportedBy
  );

  const [reportModal, setReportModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [successDisable, setSuccessDisable] = useState(false);

  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  //   let keywords = JSON.parse(approvedMentors.keywords);
    console.log(reportedByDetail, " reportedByDetail");

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const sendReportMessage = async (item) => {
    console.log({ item });
    let obj = {
      status: status,
      reportedBy: item.reportBy,
      subject: subject,
      adminMessage: description,
      userId: item?.reportBy === "mentee" ? item?.mentorUser : item?.menteeUser,
      displayName:
        item?.reportBy === "mentee"
          ? item?.mentor?.displayName
          : item?.mentee?.displayName,
    };
    console.log(obj);
    const sendReportUrl = NODE_URL(`admin/admin-resolve-report/${reportedByDetail._id}`);
    setLoading(true);
    const responseData = await Post(sendReportUrl, obj, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      toast.success("success");
      setReportModal(false);
      // setSuccessDisable(true);
      //   let arr = languages.slice();
      //   arr.push(responseData?.data?.data);
      //   console.log(arr, "4444");
      //   setLanguages(arr);
    } else {
      console.log("error");
      toast.error("Error")
    }
    setLoading(false);
  };

  console.log("reportedBy Detail ", reportedByDetail);
  return (
    <>
      <div className="container-fluid ApprovedMentorDetails">
        <h1 className="h3 mb-2 text-gray-800">Reported By Details</h1>
        {/* <p className="mb-4"> Ipsum is a placeholder text commonly</p> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="cover-photo">
                                        <img
                                            src={`${nodeImageUrl}${approvedMentors?.coverPhoto}`}
                                            alt="cover"
                                            className="image-fluid"
                                        />
                                        </div> */}
              </div>
              <div className="col-md-2">
                <div className="profile">
                                    <img
                                        src={`${nodeImageUrl}${reportedByDetail.reportBy === "mentee" ? reportedByDetail?.mentor?.photo : reportedByDetail?.mentee?.photo}`}
                                        alt=""
                                        className="image-fluid"
                                        style={{borderRadius: '50%'}}
                                    />
                                    </div>
              </div>
              <div className="col-md-8">
                <div>
                  {/* <h2>{reportedByDetail?.displayName}</h2> */}
                  <p className="location-lang">
                    Reported By : {reportedByDetail?.reportBy}
                  </p>
                  <p className="location-lang">
                    Mentee Name : {reportedByDetail?.mentee?.displayName}
                  </p>
                  <p className="location-lang">
                    Mentor Name : {reportedByDetail?.mentor?.displayName}
                  </p>
                  <p className="location-lang">
                    Reported Message : {reportedByDetail?.description}
                  </p>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <p>Actions</p>
                    </div>
                    <div className="col-md-3">
                      <Button
                        className={`btn btn-user btn-block`}
                        onClick={() => {
                          setStatus("ignore");
                          setItem(reportedByDetail);
                          sendReportMessage(item);
                          setSuccessDisable(true);
                        }}
                        disabled={successDisable}
                      >
                        Ignore
                      </Button>
                    </div>
                    <div className="col-md-3">
                      <Button
                        className={`btn btn-user btn-block`}
                        onClick={() => {
                          setReportModal(true);
                          setStatus("warning");
                          setItem(reportedByDetail);
                          setSuccessDisable(true);
                        }}
                        disabled={successDisable}
                      >
                        Warning
                      </Button>
                    </div>
                    <div className="col-md-3">
                      <Button
                        className={`btn btn-user btn-block report-action`}
                        onClick={() => {
                          setReportModal(true);
                          setStatus("deactivate account");
                          setItem(reportedByDetail);
                          setSuccessDisable(true);
                        }}
                        disabled={successDisable}
                      >
                        Delete Account
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="rating">
                                        <div className="star">
                                            <StarRatings
                                                rating={approvedMentors?.ratingsAverage}
                                                starDimension="20px"
                                                starRatedColor="orange"
                                                starSpacing="0px"
                                            />
                                        </div>
                                        <div>
                                            <p className="rating-number">{approvedMentors?.ratingsAverage}</p>
                                        </div>
                                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      <ReportModal
        reportModal={reportModal}
        setReportModal={setReportModal}
        subject={subject}
        setSubject={setSubject}
        description={description}
        setDescription={setDescription}
        sendReportMessage={sendReportMessage}
        item={item}
        setItem={setItem}
        loading={loading}
        setLoading={loading}
      />
    </>
  );
}

export default ReportDetails;
