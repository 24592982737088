import React, { useState, useEffect } from "react";
import Pagination from "../components/Pagination";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Post, Patch } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";
import { SpinnerRoundOutlined } from "spinners-react";
import moment from "moment";
import { useHistory } from "react-router";
import { getDisputeDetails, getUnresolvedSessionDetails } from "../store/actions/mentorActions";

function UnresolvedSessions(props) {
  const { setChoice } = props;
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [unresolvedSessions, setUnresolvedSessions] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const getAllUnresolvedSessions = async () => {
    const URL = NODE_URL(
      // `general/dispute-data?limit=${limit}&page=${pageNumber}`
      `general/outstanding-disputes`
    );
    setIsFetchingData(true);
    const responseData = await Get(URL, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      fetchedData = [...responseData?.data?.data];
      setUnresolvedSessions(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getAllUnresolvedSessions();
  }, []);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid disputedSession">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Unresolved Sessions</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3"></div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : unresolvedSessions?.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Mentor Name</th>
                      <th>Mentee Name</th>
                      <th>Date</th>
                      <th>Price</th>
                      <th>Dispute Created by</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {unresolvedSessions.map((item) => {
                      let disputeCreatedBy = (item.BookingStatus =
                        "there was a problem for mentee" ? "mentee" : "mentor");
                      // let disputeCreatedBy =
                      //   item?.timeLine?.timeLineArray[
                      //     item?.timeLine?.timeLineArray?.length - 2
                      //   ]?.message?.split(" ")[0];
                      let name =
                        disputeCreatedBy?.toLowerCase() === "mentor"
                          ? item?.mentor?.displayName
                          : item?.mentee?.displayName;
                      return (
                        <>
                          <tr key={item._id}>
                            <td>{item?.mentor?.displayName}</td>
                            <td>{item?.mentee?.displayName}</td>
                            <td>
                              {moment(item?.sessionStartTime).format(
                                "MMM, DD YYYY hh:mm A"
                              )}
                            </td>
                            <td>{`$${item?.lessonAmount?.toFixed(2)}`}</td>
                            <td>{`${disputeCreatedBy} (${name})`}</td>

                            <td>
                              <Button
                                className={`btn btn-user btn-block btn-ViewDetails`}
                                onClick={() => {
                                  // dispatch(getUnresolvedSessionDetails(item));
                                  dispatch(getDisputeDetails(item));
                                  history.push("/UnresolvedSessionsDetails");
                                }}
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                  // maxLastLength={maxLength}
                  // array={approvedMentors}
                  // setPageNumber={setPageNumber}
                  // pageNumber={pageNumber}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-12 m-5 d-flex justify-content-center align-items-center">
                <h2>No Unresolved Sessions</h2>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default UnresolvedSessions;
