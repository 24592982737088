import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

const TipsAndPromotionsModal = ({
  title,
  setTitle,
  descriptionField,
  setDescriptionField,
  addModal,
  setAddModal,
  createTipsAndPromotions,
  uploadImages,
  modalLoading,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={addModal}
        toggle={() => setAddModal(false)}
      >
        {/* <ModalBody> */}
        <div className="cancelModal">
          <i className="fas fa-times-circle"
            onClick={()=>setAddModal(false)}
          ></i>
        </div>
        <div className="tipsAndPro-modalBody">
          <h2 className="text-center">Add Tips And Promotions</h2>
          <div className="innerContainer">
            <div className="form-group">
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Add Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                type="text"
                className="form-control form-text-area"
                rows={5}
                placeholder="Add Description"
                value={descriptionField}
                onChange={(e) => setDescriptionField(e.target.value)}
              />

              <input
                style={{ width: "100%" }}
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => uploadImages(e)}
              />
            </div>

            <Button
              className="btn btn-user btn-block modal-savebtn"
              disabled={title.length > 0 ? false : true}
              onClick={() => {
                // setAddModal(false);
                createTipsAndPromotions();
              }}
            >
              {modalLoading ? "Loading" : "Save"}
            </Button>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default TipsAndPromotionsModal;
