import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { NODE_URL } from "../components/Config/apiURL";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SpinnerRoundOutlined } from "spinners-react";
import { useHistory } from "react-router-dom";
import {getReportedBy } from "../store/actions/mentorActions";

function Report(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [reportRequests, setReportRequests] = useState([]);
  const [limit, setLimit] = useState(10);
  const [reportedBy, setReportedBy] = useState("mentor");
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);
  
  const reportedByDetail = useSelector(
    (state) => state.mentorReducer.reportedBy
  );

const dispatch = useDispatch();
let history = useHistory();

  useEffect(() => {
    // dispatch(getNewMentors());
    var config = {
      method: "get",
      url: NODE_URL(
        `admin/all-reported-users?limit=${limit}&page=${pageNumber}&reportdBy=${reportedBy}`
      ),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        setIsFetchingData(true);
        let maxLastLength =
          pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
        setMaxLength(maxLastLength);
        setReportRequests(response.data.data);
        setIsFetchingData(false);
        console.log(response, "=================================");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pageNumber, reportedBy]);

  useEffect(() => {
    console.log({ reportedBy });
    setIsFetchingData(true)
  }, [reportedBy]);

//   const approve = (id) => {
//     console.log(id);
//     var config = {
//       method: "get",
//       url: NODE_URL(
//         `transaction/clear-pending-list?withdrawType=${withdrawType}`
//       ),
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };
//     axios(config)
//       .then(function (response) {
//         console.log(response);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
  const decline = (id) => {
    console.log(id);
    // var config = {
    //   method: "get",
    //   url: NODE_URL(`mentor/decline-mentor/${id}`),
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // };
    // axios(config)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const PaymentRequestData = [
    {
      id: 1,
      name: "Daniyal",
      m_id: "00126u7565876",
      balance: "$1000",
      noOfSessios: 5,
      noOfMentees: 11,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 2,
      name: "Ahsan",
      m_id: "00189u7371160",
      balance: "$16000",
      noOfSessios: 7,
      noOfMentees: 10,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 3,
      name: "Qasim",
      m_id: "0033124u73788g8",
      balance: "$1300",
      noOfSessios: 4,
      noOfMentees: 13,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 4,
      name: "Ehtisham",
      m_id: "00173124u73786",
      balance: "$1800",
      noOfSessios: 8,
      noOfMentees: 10,
      country: "Germany",
      data: "Edinburgh",
    },
    {
      id: 5,
      name: "Basit",
      m_id: "001ff3124u73800",
      balance: "$2200",
      noOfSessios: 10,
      noOfMentees: 7,
      country: "Germany",
      data: "Edinburgh",
    },
  ];

  console.log("check reportRequests", reportRequests)

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Report</h1>
        {/* <p className="mb-4">Report List</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex ">
            <h6 className="m-0 font-weight-bold text-primary m-2">
             Reported By
            </h6>
            <DropdownButton
              id="dropdown-basic-button"
              title={`${reportedBy}`}
              onSelect={(e) => {
                setReportedBy(e);
                console.log(reportedBy);
              }}
            >
              <Dropdown.Item eventKey="mentee">Mentee's</Dropdown.Item>
              <Dropdown.Item eventKey="mentor">Mentor's</Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="card-body">
          {isFetchingData ?
              (
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={isFetchingData}
                    thickness={200}
                    color="#044d81"
                  />{" "}
                </div>
              ) : ( 
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    {/* <th>Name</th> */}
                    <th>S.NO</th>
                    <th>Mentee</th>
                    <th>Mentor</th>
                    <th>Reported By</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
                {reportRequests?.map((item, index) => {
                          return (
                            <>

                              <tr
                                key={index}
                              >
                                <td>{index+1}</td>
                                <td>{item?.mentee?.displayName}</td>
                                <td>{item?.mentor?.displayName}</td>
                                <td>{item.reportBy}</td>
                                {/* <td>{item.pending.amount}</td>
                                <td>{item.pending.setReportedBy}</td> */}
                                <td>
                                <Button
                                  className={`btn btn-user btn-block`}
                                  onClick={() => {
                                    history.push("/ReportDetails");
                                    dispatch(getReportedBy(item));
                                    console.log(item);
                                  }}
                                >
                                  View Detail
                                </Button>
                                </td>
                              </tr>
                            </>
                          );
                  })}
                </tbody>
              </table>
             
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={reportRequests}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
                ) 
              
                }
          </div>

        </div>
      </div>
     
    </>
  );
}

export default Report;
