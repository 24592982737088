import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
// contant image used in tooltip
import QUS from "../assets/images/question-mark.png";

// tooltip styling function
const customStyling = makeStyles(() => ({
  tooltip: {
    backgroundColor: "rgba(101,101,101,92%)",
    padding: "10px",
  },
}));

function CustomToolTip({ tipInfo, tipPlacement }) {
  const classes = customStyling();
  return (
    <Tooltip
      placement={tipPlacement}
      classes={classes}
      arrow
      title={
        <small style={{ fontSize: "14px", fontFamily: "Nexa Light" }}>
          {tipInfo}
        </small>
      }
    >
      <img src={QUS} width="15px" height="15px" />
    </Tooltip>
  );
}

export default CustomToolTip;
