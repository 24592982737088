import React, { useState } from "react";

import { loginVector, logo } from "../constant/imagepath";
import { NODE_URL } from "./Config/apiURL";
import { Get, Post } from "./Axios/AxiosFunctions";
import { useDispatch } from "react-redux";
import { islogin } from "../store/actions/globalActions";
import { Button } from "react-bootstrap";
import cookie from "js-cookie";
import ForgotPassModal from "./ForgotPassModal";
import Header from "./Header";
import { toast } from "react-toastify";

function Login(props) {
  const { setUserLogin } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");
  const [showForgetPassModal, setShowForgetPassModal] = useState(false);
  const dispatch = useDispatch();
  const authHeader = {
    headers: {
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const loginHandle = async () => {
    const adminLoginURL = NODE_URL("users/loginAdmin");
    let params = {
      email: email,
      password: password,
      role: "admin",
    };
    setLoading(true);
    const responseData = await Post(adminLoginURL, params, authHeader);
    if (responseData !== undefined) {
      cookie.set("accessToken", responseData?.data?.token, { expires: 30 });
      console.log(responseData?.data?.data);
      dispatch(islogin(responseData?.data));
    } else {
      console.log("error");
    }
    setLoading(false);
  };

  const handlePasswordChange = async () => {
    setLoading(true);
    const resetPassURL = NODE_URL("users/forgotPassword");
    let params = {
      email: emailForgot,
    };
    const resData = await Post(resetPassURL, params, authHeader);

    if (resData !== undefined) {
      toast.success(
        "A Reset Password Email has been sent to this email-address."
      );
      setShowForgetPassModal(false)
      setEmailForgot("");
    }
    setLoading(false);
  };
  return (
    <>
      <div className="container login">
        {/* <!-- Outer Row --> */}
        <div className="row justify-content-center custom-alignment">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image">
                    <img src={loginVector} alt="" className="image-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <img src={logo} alt="" className="image-fluid logo" />
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form className="user">
                        <div className="form-group">
                          <input
                            className="form-control form-control-user"
                            type="email"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email Address..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control form-control-user"
                            type="password"
                            id="exampleInputPassword"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        {/* <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input 
                                                            className="custom-control-input" 
                                                            type="checkbox"
                                                            id="customCheck" 
                                                        />
                                                        <label className="custom-control-label" for="customCheck">RememberMe</label>
                                                    </div>
                                                </div> */}
                        <Button
                          className="btn btn-primary-custom btn-user btn-block"
                          onClick={() => loginHandle()}
                          disabled={loading}
                        >
                          Login
                        </Button>
                        <p
                          className="mt-3 forgot-password-login"
                          onClick={() => setShowForgetPassModal(true)}
                        >
                          Forgot Password?
                        </p>
                      </form>
                      {/* <div className="text-center">
                                                <a className="small" href="forgot-password.html">Forgot Password?</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="small" href="register.html">Create an Account!</a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ForgotPassModal
          isModalVisible={showForgetPassModal}
          setIsModalVisible={setShowForgetPassModal}
          onClickCancel={() => {
            setShowForgetPassModal(false);
          }}
          onClickSave={handlePasswordChange}
          textLine={"Forgot Password"}
          isApiCall={loading}
          emailForgot={emailForgot}
          setEmailForgot={setEmailForgot}
        />
      </div>
    </>
  );
}

export default Login;
