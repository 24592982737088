import React, { useState } from "react";
import { useEffect } from "react";
import { Get } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";
import { SpinnerRoundOutlined } from "spinners-react";
import { getApprovedMentor } from "../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import CustomToolTip from "../components/CustomToolTip";

function ApprovedMentor(props) {
  const newMentors = useSelector((state) => state.mentorReducer.approvedMentor);
  const dispatch = useDispatch();
  let history = useHistory();

  const { setChoice } = props;
  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);

  const [approvedMentors, setApprovedMentors] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  //   const authHeader = {
  //     headers: {
  //       Accept: "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //     },
  //   };

  const getAllApprovedMentors = async () => {
    // const allApprovedMentorsApiURL = NODE_URL(
    //   `mentor/get-approved-mentors?limit=${limit}&page=${pageNumber}`
    // );
    const allApprovedMentorsApiURL = NODE_URL(
      `mentor/getMenteesOrMentors?mode=mentor&limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(allApprovedMentorsApiURL, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setApprovedMentors(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAllApprovedMentors();
  }, [pageNumber]);

  return (
    <>
      <div className="container-fluid newMentorRequests">
        <h1 className="h3 mb-2 text-gray-800">Approved Mentors</h1>
        {/* <p className="mb-4">Approved Mentors</p> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">
              DataTables Example
            </h6> */}
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>User Id</th>
                      <th>Name</th>
                      
                      <th>Email</th>
                      <th>Gender</th>
                      {/* <th>Mentees</th> */}
                      <th>Sessions</th>
                      <th>Country</th>
                      <th>Commission</th>
                      <th>No. of Logins</th>

                      <th>Total Balance</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedMentors.map((item, index) => {
                      console.log(item)
                      return (
                        <>
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>
                              {item?._id ? item?._id : "---"}
                            </td>
                            <td>
                              {item?.displayName ? item?.displayName : "---"}
                            </td>
                            <td>{item?.UserId?.email ? item?.UserId?.email : "---"}</td>
                            <td>{item?.gender ? item?.gender : "---"}</td>

                            <td>
                              {item?.sessionsTaught
                                ? item?.sessionsTaught
                                : "---"}
                            </td>
                            <td>{item?.country ? item?.country : "---"}</td>
                            <td>
                              {item?.commission || item?.commission == 0 ? (
                                item?.commission *100
                              ) : (
                                // <CustomToolTip
                                //   tipPlacement={"top"}
                                //   tipInfo="Default commission set from Features ➜ Admin Control ➜ Commission"
                                // />
                                0
                              )}
                            </td>
                            <td>{item?.logins?.length}</td>

                            <td>
                              {item?.mentorBalance
                                ? item?.mentorBalance.toFixed(2)
                                : "---"}
                            </td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block`}
                                onClick={() => {
                                  history.push("/ApprovedMentorDetails");
                                  dispatch(getApprovedMentor(item));
                                  console.log(item);
                                }}
                              >
                                View Detail
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={approvedMentors}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default ApprovedMentor;
