import React from "react";
import { logo } from "../constant/imagepath";
import NewMentorRequests from "../screens/NewMentorRequests";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

function SideNavbar({ setChoice }) {
  return (
    <>
      {/* <!-- Sidebar --> */}
      <ul
        className="navbar-nav  custom-sidebar bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <div className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon logo">
            {/* <i className="fas fa-laugh-wink"></i> rotate-n-15 */}
            <img src={logo} alt="" className="image-fluid" />
          </div>
          {/* <div className="sidebar-brand-text mx-3">Mentgo <sup>2</sup></div> */}
        </div>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        {/* <li className="nav-item active">
          <a className="nav-link">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li> */}

        {/* <!-- Divider --> */}
        {/* <hr className="sidebar-divider" /> */}

        {/* <!-- Heading --> */}
        {/* <div className="sidebar-heading">
                Interface
            </div> */}

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        {/* <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Components</span>
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Custom Components:</h6>
                        <a className="collapse-item" href="buttons.html">Buttons</a>
                        <a className="collapse-item" href="cards.html">Cards</a>
                    </div>
                </div>
            </li> */}

        {/* <!-- Nav Item - Utilities Collapse Menu --> */}
        {/* <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i className="fas fa-fw fa-wrench"></i>
                    <span>Utilities</span>
                </a>
                <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Custom Utilities:</h6>
                        <a className="collapse-item" href="utilities-color.html">Colors</a>
                        <a className="collapse-item" href="utilities-border.html">Borders</a>
                        <a className="collapse-item" href="utilities-animation.html">Animations</a>
                        <a className="collapse-item" href="utilities-other.html">Other</a>
                    </div>
                </div>
            </li> */}

        {/* <!-- Divider --> */}
        {/* <hr className="sidebar-divider" /> */}

        {/* <!-- Heading --> */}
        {/* <div className="sidebar-heading">Addons</div> */}

        {/* <!-- Nav Item - Charts --> */}

        {/* <li className="nav-item">
                <a className="nav-link" href="charts.html">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Charts</span></a>
            </li> */}

        {/* <!-- Nav Item - Tables --> */}

        <li className="nav-item">
          <Link
            to="/NewMentorRequests"
            className="nav-link mouse-cursor"
            // onClick={() => setChoice("NewMentorRequests")}
          >
            <i className="fas fa-fw fa-table"></i>
            <span>New Mentor Requests</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/ApprovedMentor"
            className="nav-link mouse-cursor"
            // onClick={() => setChoice("ApprovedMentor")}
          >
            <i className="fas fa-fw fa-table"></i>
            <span>Approved Mentor</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/AllMentees"
            className="nav-link mouse-cursor"
            // onClick={() => setChoice("AllMentees")}
          >
            <i className="fas fa-fw fa-table"></i>
            <span>All Mentees</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/MentorChanges"
            className="nav-link mouse-cursor"
            // onClick={() => setChoice("MentorChanges")}
          >
            <i className="fas fa-fw fa-table"></i>
            <span>Mentor Changes</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/BlockedList"
            className="nav-link mouse-cursor"
            // onClick={() => setChoice("MentorChanges")}
          >
            <i className="fas fa-fw fa-table"></i>
            <span>Blocked Users</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/PaymentRequest" className="nav-link mouse-cursor">
            <i className="fas fa-fw fa-table"></i>
            <span>Payment Request</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/DisputedSessions" className="nav-link mouse-cursor">
            <i className="fas fa-fw fa-table"></i>
            <span>Disputed Sessions</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/UnresolvedSessions" className="nav-link mouse-cursor">
            <i className="fas fa-fw fa-table"></i>
            <span>Unresolved Sessions</span>
          </Link>
        </li>

        {/* DefaultPrices */}
        <li className="nav-item">
          <Link to="/DefaultPrices" className="nav-link mouse-cursor">
            <i className="fas fa-fw fa-table"></i>
            <span>Default Mentor Prices</span>
          </Link>
        </li>

        {/* AdminMain Community */}
        {/* <li className="nav-item">
          <Link to="/AdminMain" className="nav-link mouse-cursor">
            <i className="fas fa-fw fa-table"></i>
            <span>Community</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePages2"
            aria-expanded="true"
            aria-controls="collapsePages2"
          >
            <i className="fas fa-fw fa-folder"></i>
            <span>CMS</span>
          </a>
          <div
            id="collapsePages2"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/HomeScreen" className="collapse-item">
                Home Page
              </Link>
              <Link to="/BecomeAMentor" className="collapse-item">
                Become A Mentor
              </Link>
              <Link to="/Services" className="collapse-item">
                Services
              </Link>
              <Link to="/Testimonial" className="collapse-item">
                Testimonial
              </Link>
              <Link to="/HowItsWork" className="collapse-item">
                How Its Work
              </Link>
              <Link to="/Category" className="collapse-item">
                Category
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePages"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-folder"></i>
            <span>Features</span>
          </a>
          <div
            id="collapsePages"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/sessionsearch" className="collapse-item">
                Sessions by Id
              </Link>
              <Link to="/FeatureAddLanguages" className="collapse-item">
                Add Languages
              </Link>
              <Link to="/TipsAndPromotions" className="collapse-item">
                Tips and Promotions
              </Link>
              <Link to="/AdvanceNotice" className="collapse-item">
                Admin Control
              </Link>
              <Link to="/Report" className="collapse-item">
                Report
              </Link>
              <Link to="/DeActivatedAccount" className="collapse-item">
                Deactivated Accounts
              </Link>

              <Link to="/AdminGoodwill" className="collapse-item">
                Admin Goodwill
              </Link>
              <Link to="/SessionProblems" className="collapse-item">
                Problems Questions
              </Link>

              <Link to="/Stats_CSV" className="collapse-item">
                Stats(CSV)
              </Link>

              <Link to="/community" className="collapse-item">
                Community Requests
              </Link>

              <Link to="/changePassword" className="collapse-item">
                Change Password
              </Link>

              {/* <a className="collapse-item" href="register.html">Register</a>
                        <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                        <div className="collapse-divider"></div>
                        <h6 className="collapse-header">Other Pages:</h6>
                        <a className="collapse-item" href="404.html">404 Page</a>
                        <a className="collapse-item" href="blank.html">Blank Page</a> */}
            </div>
            {/* <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/TipsAndPromotions"
                className="collapse-item"
                // onClick={() => setChoice("TipsAndPromotions")}
              >
                Tips and Promotions
              </Link>
            </div> */}
          </div>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        {/* <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
          ></button>
        </div> */}

        {/* <!-- Sidebar Message --> */}
        {/* <div className="sidebar-card d-none d-lg-flex">
                <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..." />
                <p className="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
                <a className="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
            </div> */}
      </ul>
      {/* <!-- End of Sidebar --> */}
      {/* <hr />   */}
    </>
  );
}

export default SideNavbar;
