import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ResolveDisputeModal = ({
  isShowModal,
  setIsShowModal,
  onClick,
  isApiCall,
  title,
  favorFor,
  data,
}) => {
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const [menteeAmount, setMenteeAmount] = useState(0);
  const [mentorAmount, setMentorAmount] = useState(0);

  return (
    <Modal
      className="modal-dialog-centered add-sess-prob-modal disp-res"
      isOpen={isShowModal}
      // toggle={() => setIsShowModal(false)}
    >
      {/* <ModalBody> */}
      <div className="cancelModal">
        <i
          className="fas fa-times-circle"
          onClick={() => setIsShowModal(false)}
        ></i>
      </div>
      <div className="addlang-modalBody dispute-res-Modal">
        <h2 className="text-center">{title}</h2>
        <div className="innerContainer">
          <p className="pref-opt mt-1 mb-0">For Mentee</p>
          <textarea
            className="TextBox"
            type="text"
            rows={1}
            placeholder="Message for mentee"
            value={message1}
            onChange={(e) => setMessage1(e.target.value)}
          />
          {favorFor === "both" && (
            <>
              <h6 style={{ color: "white", margin: 0 }}>Amount For Mentee </h6>
              <input
                type={"number"}
                value={menteeAmount}
                onChange={(e) => setMenteeAmount(e.target.value)}
              />
            </>
          )}

          <p className="pref-opt mt-1 mb-0">For Mentor</p>
          <textarea
            rows={1}
            className="TextBox"
            type="text"
            placeholder="Message for mentor"
            value={message2}
            onChange={(e) => setMessage2(e.target.value)}
          />
          {favorFor === "both" && (
            <>
              <h6 style={{ color: "white", margin: 0 }}>Amount For Mentor </h6>
              <input
                type={"number"}
                value={mentorAmount}
                onChange={(e) => setMentorAmount(e.target.value)}
              />
            </>
          )}
          <div className="text-center">
            <Button
              className="btn btn-user btn-block btn-save"
              disabled={
                message1.length > 0 && message2.length > 0 && !isApiCall
                  ? false
                  : true
              }
              onClick={() => {
                // if (
                //   mentorAmount * 1 + menteeAmount * 1 <=
                //   data?.lessonAmount * 1
                // ) {
                  onClick(message1, message2, mentorAmount, menteeAmount);
                // } 
                // else {
                //   toast.info(
                //     "Sum of amount shared to parties is greater than session amount.",
                //     {
                //       position: "top-center",
                //       autoClose: 2000,
                //       hideProgressBar: false,
                //       closeOnClick: true,
                //       pauseOnHover: true,
                //       draggable: false,
                //       progress: undefined,
                //     }
                //   );
                // }
              }}
            >
              {isApiCall ? "Please Wait" : "Send"}
            </Button>
          </div>
        </div>
      </div>
      {/* </ModalBody> */}
    </Modal>
  );
};

export default ResolveDisputeModal;
