import React from "react";

function Pagination({  maxLastLength, array, setPageNumber, pageNumber }) {
  return (
    <>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {pageNumber > 1 && (
            <li
              className="page-item"
              onClick={() => {
                setPageNumber((prev) => prev - 1);
           
              }}
            >
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
          )}
          {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li> */}
          {/* {maxLastLength === array?.length && ( */}
            <li
              className="page-item"
              onClick={() => {
                setPageNumber((prev) => prev + 1);
            
              }}
            >
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          {/* )} */}
        </ul>
      </nav>
    </>
  );
}

export default Pagination;
