import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { NODE_URL, baseUrl,nodeImageUrl } from "../components/Config/apiURL";
import { useEffect } from "react";
import { Get } from "./Axios/AxiosFunctions";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "20px",
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  return (
    <>
      <div className="col-md-4  card-deck">
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              // image={`${baseUrl}/img/tips_and_promotion/${props.item.URL}`}
              image={`${nodeImageUrl}/${props.item.URL}`}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {props.item.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.item.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() =>
                props.activeInactive(
                  props.item._id,
                  props.item.isActive,
                  props.index
                )
              }
            >
              {props.item.isActive ? "Inactive" : "Active"}
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() =>
                props.deleteRecord(
                  props.item._id,
                  props.item.isActive,
                  props.index
                )
              }
              disabled={props.loading}
            >
              {props.loading === props.item._id ? "Loading" : "Delete"}
            </Button>
          </CardActions>
        </Card>
      </div>
    </>
  );
}
