import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { nodeImageUrl } from "./Config/apiURL";

const AddAndEditCategoryModal = ({
  title,
  visibleModal,
  itemTitle,
  setItemTitle,
  image,
  imageDisplay,
  icon,
  iconDisplay,
  onPress,
  onPressCancel,
  itemLoading,
  uploadImages,
  uploadIcons,
  imageAlt,
  setImageAlt,
  setIconAlt,
  iconAlt,
}) => {
  return (
    <Modal
      className="modal-dialog-centered custom-addLang-modal"
      isOpen={visibleModal}
      toggle={onPressCancel}
    >
      <div className="modal-Header">
        <h2 className="text-center">{title}</h2>
      </div>
      <div className="addlang-modalBody">
        {itemTitle !== undefined && (
          <div className="innerContainer">
            <div className="form-group">
              <input
                type="text"
                placeholder="Add Title"
                value={itemTitle}
                onChange={(e) => setItemTitle(e.target.value)}
              />
            </div>
          </div>
        )}
        {image !== undefined && (
          <>
            <div className="innerContainer">
              <div className="form-group">
                <div className="icon-upload-avatar">
                  {image == "" ? (
                    <>
                      <span>
                        <i className="fa fa-upload" style={{ fontSize: 50 }} />
                        <p>Upload Image</p>
                      </span>
                    </>
                  ) : imageDisplay !== null ? (
                    <img
                      src={imageDisplay}
                      alt="User Image"
                      className="image-fluid"
                    />
                  ) : (
                    <img
                      src={`${nodeImageUrl}/${image}`}
                      alt="User Image"
                      className="image-fluid"
                    />
                  )}
                  <input
                    type="file"
                    id="InputCoverImage"
                    accept="image/png, image/jpeg"
                    onChange={(e) => uploadImages(e)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="innerContainer">
                <label for="section1InputHeading" className="pages-label-style">
                  ALT (Image)
                </label>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Add Image ALT"
                    value={imageAlt}
                    onChange={(e) => setImageAlt(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {icon !== undefined && (
          <>
            <div className="innerContainer">
              <div className="form-group">
                <div className="icon-upload-avatar">
                  {icon == "" ? (
                    <>
                      <span>
                        <i className="fa fa-upload" style={{ fontSize: 50 }} />
                        <p>Upload Icon</p>
                      </span>
                    </>
                  ) : iconDisplay !== null ? (
                    <img
                      src={iconDisplay}
                      alt="User Image"
                      className="image-fluid"
                    />
                  ) : (
                    <img
                      src={`${nodeImageUrl}/${icon}`}
                      alt="User Image"
                      className="image-fluid"
                    />
                  )}
                  <input
                    type="file"
                    id="InputCoverImage"
                    accept="image/png, image/jpeg"
                    onChange={(e) => uploadIcons(e)}
                  />
                </div>
              </div>
            </div>
           
          </>
        )}
        <div className="modal-footer footer-div">
          <div>
            <Button
              className="btn btn-user btn-block btn-cancel"
              onClick={onPressCancel}
            >
              Cancel
            </Button>
          </div>

          <div>
            <Button
              className="btn btn-user btn-block btn-save"
              onClick={onPress}
              disabled={itemLoading}
            >
              {itemLoading ? "Loading.." : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAndEditCategoryModal;
