import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { loginVector, coverPhoto } from "../constant/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { nodeImageUrl } from "../components/Config/apiURL";
import { NODE_URL, baseUrl } from "../components/Config/apiURL";
import axios from "axios";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function NewMentorRequestDetails() {
  const newMentors = useSelector(
    (state) => state.mentorReducer.unApprovedMentors
  );
  const [loading, setLoading] = useState(false);
  const [decLoading, setDecLoading] = useState(false);
  const history = useHistory();

  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  console.log(newMentors.keywords);
  let keywords =
    newMentors.keywords.length > 0 ? JSON.parse(newMentors?.keywords) : [];

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const approve = (id) => {
    setLoading(true);
    var config = {
      method: "patch",
      url: NODE_URL(`admin/approve-mentor/${id}`),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(true);
        history.push("/NewMentorRequests");
        toast.success("Mentor Approved");
        console.log(response);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };

  // const approve = async (id) => {
  //   const approveUrl = NODE_URL(`admin/approve-mentor/${id}`);
  //   const responseData = await Patch(approveUrl, authHeader);
  //   setLoading(true)
  //   if (responseData !== undefined) {
  //     toast.success("Mentor Approved")
  //     console.log(responseData);
  //     // let arr = languages.slice();
  //     // arr.splice(index, 1, responseData?.data?.data);
  //     // setLanguages(arr);
  //   } else {
  //     console.log("error");
  //     toast.error("Error")
  //   }
  //   setLoading(false)
  // };

  const decline = (id) => {
    setLoading(true);

    var config = {
      method: "patch",
      url: NODE_URL(`admin/decline-mentor/${id}`),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(true);
        console.log(response);
        history.push("/NewMentorRequests");
        toast.info("Mentor Declined");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  console.log(newMentors);

  const format1 = "YYYY-MM-DD";

  return (
    <>
      <div className="container-fluid newMentorRequestDetails">
        <h1 className="h3 mb-2 text-gray-800">New Mentor Request Details</h1>
        {/* <p className="mb-4">New mentor requests list</p> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-md-12">
                <div className="cover-photo">
                  <img
                    src={`${nodeImageUrl}/${newMentors?.coverPhoto}`}
                    alt="cover"
                    className="image-fluid"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <div className="profile-newMentor">
                  <img
                    src={`${nodeImageUrl}/${newMentors?.photo}`}
                    alt=""
                    className="image-fluid"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <h2>{newMentors?.displayName}</h2>
                  <p className="location-lang">
                    {newMentors?.city} {newMentors?.country}
                  </p>
                  <p className="location-lang">
                    Language: {newMentors?.UserId?.language}
                  </p>
                </div>
                <div>
                  <p className="bio-text">Email: {newMentors?.UserId?.email}</p>
                  <p className="bio-text">Gender {newMentors?.gender}</p>
                  <p className="bio-text">
                    DOB: {moment(newMentors?.dob).format(format1)}
                  </p>
                  <p className="aboutMe-text">About Me</p>
                  <p className="aboutMe-para">{newMentors?.aboutMe}</p>
                  {/* <p className="Mentor-kind">
                    Kind of Mentor :
                    {JSON.parse(newMentors?.mentorKinds).map((e, i) => {
                      return (
                        <span key={i} className="kind-text">
                          {e}
                        </span>
                      );
                    })}
                  </p> */}
                  <p className="keyword">Keywords</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {keywords?.map((e, i) => {
                      return (
                        <div key={i} className="key-box">
                          <span>{e?.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div
                  className="pdf-container"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      `${baseUrl}/mentor-docs/${newMentors?.documents}`
                    );
                  }}
                >
                  <i class="fas fa-file-pdf "></i>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="approved-pending">
                  <div>
                    <Button
                      className="btn btn-user btn-block btn-approved"
                      onClick={() => {
                        // setLoading(true)
                        approve(newMentors?.UserId?._id);
                      }}
                      disabled={loading}
                    >
                      {loading ? "Approving..." : "Approve"}
                      {/* Approve */}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="btn btn-user btn-block btn-pending decline-color"
                      onClick={() => {
                        // setLoading(true)
                        decline(newMentors?.UserId?._id);
                      }}
                      disabled={loading}
                    >
                      {decLoading ? "Declining..." : "Decline"}
                      {/* Decline */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewMentorRequestDetails;
