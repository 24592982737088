import { SIGN_OUT, LOGIN_SUCCESS } from "../actions/actionTypes";

const initState = {
  userType: "",
  isLogin: false,
  accessToken: null,
  user: null,
};
const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case SIGN_OUT:
      return {
        ...state,
        userType: "",
        isLogin: false,
        accessToken: null,
        user: null,
      };
    case LOGIN_SUCCESS:
      console.log(action)
      return {
        ...state,
        userType: action?.data?.data?.user?.role,
        isLogin: true,
        accessToken: action?.data?.token,
        user: action?.data?.data?.user,
      };
    default:
      return state;
  }
};

export default globalReducer;
