import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";

function DefaultPrices() {
  const token = useSelector((state) => state.globalReducer.accessToken);

  const [getToKnowYou, setGetToKnow] = useState(0);
  const [generalConsultation30Min, setGeneralConsultation30min] = useState(0);
  const [generalConsultation45Min, setGeneralConsultation45min] = useState(0);
  const [generalConsultation60Min, setGeneralConsultation60min] = useState(0);
  const [generalConsultation90Min, setGeneralConsultation90min] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [id, setId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    getData();
  }, []);

  //   getData
  const getData = async () => {
    const url = NODE_URL("general/app-details");
    setIsLoading(true);
    const response = await Get(url, token);
    setIsLoading(false);
    if (response != undefined) {
      console.log(response.data);
      setGetToKnow(
        response.data?.data?.sessionAmounts?.sessionAmounts?.getToKnowYou
      );
      setGeneralConsultation30min(
        response.data?.data?.sessionAmounts?.sessionAmounts
          ?.generalConsultation30Min
      );
      setGeneralConsultation45min(
        response.data?.data?.sessionAmounts?.sessionAmounts
          ?.generalConsultation45Min
      );
      setGeneralConsultation60min(
        response.data?.data?.sessionAmounts?.sessionAmounts
          ?.generalConsultation60Min
      );
      setGeneralConsultation90min(
        response.data?.data?.sessionAmounts?.sessionAmounts
          ?.generalConsultation90Min
      );

      setId(response.data?.data?.sessionAmounts?._id);
    }
  };

  //   postData
  const postData = async () => {
    const params = {
      sessionAmounts: {
        getToKnowYou: getToKnowYou * 1,
        generalConsultation30Min: generalConsultation30Min * 1,
        generalConsultation45Min: generalConsultation45Min * 1,
        generalConsultation60Min: generalConsultation60Min * 1,
        generalConsultation90Min: generalConsultation90Min * 1,
      },
    };
    console.log({ params });
    let isAllNotEqualToZero = false;

    Object.keys(params?.sessionAmounts).forEach(function (key) {
      if (params?.sessionAmounts[key] <= 0) {
        isAllNotEqualToZero = true;
      }
    });
    if (isAllNotEqualToZero) {
      toast.info("Input value should be greater than 1.");
    } else {
      const url = NODE_URL(`admin/defaultPrices/${id}`);
      setIsLoading(true);
      const response = await Patch(url, params, authHeader);
      setIsLoading(false);
      if (response !== undefined) {
        toast.info("Default Mentor Amounts updated successfully.");
      }
    }
  };

  return (
    <div className="container-fluid disputedSession">
      {/* <!-- Page Heading --> */}
      <h1 className="h3 mb-2 text-gray-800 ml-2">Default Mentor Amount</h1>

      <div className="col-md-8 mt-5">
        {/* Get to knowe you */}
        <div className="form-group">
          <label>Get to know you</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for Get to know you"
            value={getToKnowYou}
            onChange={(e) => setGetToKnow(e.target.value * 1)}
          />
        </div>

        {/* General Consultation (30 min) */}
        <div className="form-group">
          <label>General Consultation (30 min)</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for General Consultation (30 min)"
            value={generalConsultation30Min}
            onChange={(e) => setGeneralConsultation30min(e.target.value)}
          />
        </div>

        {/* General Consultation (45 min) */}
        <div className="form-group">
          <label>General Consultation (45 min)</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for General Consultation (45 min)"
            value={generalConsultation45Min}
            onChange={(e) => setGeneralConsultation45min(e.target.value)}
          />
        </div>

        {/* General Consultation (60 min) */}
        <div className="form-group">
          <label>General Consultation (60 min)</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for General Consultation (60 min)"
            value={generalConsultation60Min}
            onChange={(e) => setGeneralConsultation60min(e.target.value)}
          />
        </div>

        {/* General Consultation (90 min) */}
        <div className="form-group">
          <label>General Consultation (90 min)</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for General Consultation (90 min)"
            value={generalConsultation90Min}
            onChange={(e) => setGeneralConsultation90min(e.target.value)}
          />
        </div>

      
        {/* <div className="form-group">
          <label>Minimum Price</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for General Consultation (90 min)"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />
        </div>

   
        <div className="form-group">
          <label>Maximum Price</label>
          <input
            type="Number"
            className="form-control"
            placeholder="Enter price for General Consultation (90 min)"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </div> */}
        {/* Button */}
        <button
          type="button"
          className="btn btn-primary btn-lg btn-block"
          disabled={isLoading}
          onClick={postData}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default DefaultPrices;
