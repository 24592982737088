import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { IconButton, withStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const RenderOptions = (props) => {
  const {
    option,
    removeOption,
    setQuestion,
    options,
    index,
    isUpdating,
    setDefaultCase,
    defaultCase,
  } = props;

  useEffect(() => {
    if (isUpdating) {
      setDefaultCase(option?.defaultCase);
    } else {
      setDefaultCase("mentee");
    }
  }, [option]);

  const handleChangeDefaultCase = (event) => {
    setDefaultCase(event.target.value);
    let copyOptions = [...options];
    copyOptions[index].defaultCase = event.target.value;
    setQuestion((prev) => {
      return {
        ...prev,
        options: copyOptions,
      };
    });
  };

  const updateOptionText = (value) => {
    let copyOptions = [...options];
    copyOptions[index].opt = value;

    setQuestion((prev) => {
      return {
        ...prev,
        options: copyOptions,
      };
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-11 mySocialAccount-input">
          <input
            style={{ borderRadius: 3 }}
            placeholder="Option"
            onChange={(e) => {
              updateOptionText(e.target.value);
            }}
            value={option?.opt}
            type={"text"}
            className="form-control inputText input-text mb-2"
          />
        </div>
        <div
          className="col-md-1 d-flex align-items-center "
          onClick={() => removeOption(option)}
        >
          <IconButton
            style={{
              outline: "none",
              padding: "10px",
            }}
          >
            <i className="fas fa-trash-alt" style={{ color: "white" }}></i>
          </IconButton>
        </div>
        <div className="col-md-12 d-flex flex-row ">
          <p className="default-case mr-3 mt-2">Default Case: </p>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="person"
              name="person1"
              value={defaultCase}
              onChange={(e) => handleChangeDefaultCase(e)}
            >
              <div className="d-flex flex-row">
                <FormControlLabel
                  value="mentor"
                  control={<Radio color="secondary" />}
                  label="Mentor"
                  style={{ color: "white" }}
                />
                <FormControlLabel
                  value="mentee"
                  control={<Radio color="secondary" />}
                  label="Mentee"
                  style={{ color: "white" }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default RenderOptions;
