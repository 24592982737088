import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete, Get, Patch } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const Community = () => {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get data
  const getData = async () => {
    const url = NODE_URL("general/community");
    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);
    if (response !== undefined) {
      console.log(response?.data);
      setData(response?.data?.data);
    }
  };

  const handleDelete = async (id) => {
    const url = NODE_URL(`general/community?id=${id}`);
    setIsLoading(true);
    const response = await Patch(url, accessToken);
    setIsLoading(false);
    if (response !== undefined) {
      toast.success('Deleted successfully')
      setData(response?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <div className="container">
        <h1>Community Requests</h1>
        <p>Please wait</p>
      </div>
    );
  }

  return (
    <div className="container">
      <h1>Community Requests</h1>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Email</th>
            <th scope="col">Date</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((e, i) => (
            <tr>
              <th scope="row">{++i}</th>
              <td>{e?.email}</td>
              <td>{moment(e?.createdAt).format("ddd, DD MMM YYYY hh:mm A")}</td>
              <td>
                <Button onClick={async () => await handleDelete(e?._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Community;
