import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

const ForgotPassModal = ({
  isModalVisible,
  setIsModalVisible,
  onClickCancel,
  onClickSave,
  textLine,
  isApiCall,
  emailForgot,
  setEmailForgot,
}) => {
  return (
    <Modal
      className="modal-dialog-centered forgot-modal p-4"
      isOpen={isModalVisible}
    >
      <div className="forgot-modal-body d-flex flex-column justify-content-start align-items-start">
        <h4 className="align-self-center forgot-pass-label">Forgot Password</h4>

        <label className="email-forgot">Email Address</label>

        <input
          className="input-forgot-email"
          type="text"
          placeholder="Enter email-address"
          value={emailForgot}
          onChange={(e) => setEmailForgot(e.target.value)}
        />
      </div>
      {/* footer  */}
      <div className="saveBtn-Container mb-3">
        <Button
          className="btn btn-save mr-3"
          onClick={() => {
            onClickSave();
          }}
          disabled={isApiCall}
        >
          {isApiCall ? "Please Wait" : "Submit"}
        </Button>
        <Button
          className="btn btn-save"
          onClick={() => {
            onClickCancel();
          }}
          disabled={isApiCall}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ForgotPassModal;
