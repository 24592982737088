import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Patch, Post } from "../components/Axios/AxiosFunctions";
import { nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import { loginVector, coverPhoto } from "../constant/imagepath";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";

function MentorChangesDetails() {
  const [message, setMessage] = useState("");
  const [userName, setUserName] = useState("Ehtisham Tahir");
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmit, setSubmit] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const mentorChanges = useSelector(
    (state) => state.mentorReducer.mentorChanges
  );
  console.log({ mentorChanges });
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [gotResponse, setGotResponse] = useState(false);
  const history = useHistory();

  const [approveLoading, setApproveLoading] = useState(false);

  const handleChanges = async (isApproved) => {
    const url = NODE_URL("admin/approve-reject-mentor-profile-changes");
    let params = {
      isApproved: isApproved,
      mentor: mentorChanges?._id,
      message,
    };
    console.log({ params });

    const authHeader = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    {
      message ? setIsSending(true) : setApproveLoading(true);
      setSubmit(true);
    }

    const responseData = await Patch(url, params, authHeader);
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      toast.info("Changes Successfully Updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setGotResponse(true);
      setIsSending(false);
      history.push("/MentorChanges");
    } else {
      console.log("error");
    }
    setApproveLoading(false);
    setSubmit(false);
    setIsOpenModal(false);
  };

  const handleModal = async () => {
    setIsOpenModal(true);
  };
  console.log(mentorChanges);
  return (
    <>
      <div className="container-fluid MentorChangesDetails">
        <h1 className="h3 mb-2 text-gray-800">Mentor Changes Details</h1>
        {/* <p className="mb-4"> Ipsum is a placeholder text commonly</p> */}

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <h2 className="mainHeading">Current Details</h2>
              {/* <div className="col-md-12">
                <div className="cover-photo">
                  <img
                    src={`${nodeImageUrl}/${mentorChanges?.coverPhoto}`}
                    alt="cover"
                    className="image-fluid"
                  />
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="displayPhoto">
                  <img
                    src={`${nodeImageUrl}/${mentorChanges?.photo}`}
                    alt="photo"
                    className="image-fluid"
                  />
                </div>
              </div>
              <div className="col-md-9">
                <p className="mentorChange-name">
                  {mentorChanges?.displayName}
                </p>
                <p style={{ fontSize: "13px" }}>{mentorChanges?.aboutMe}</p>
                <p className="mentorChange-p">
                  Country : {mentorChanges?.country}
                </p>
                {/* <p className="mentorChange-p">Gender : {mentorChanges?.gender}</p> */}
                <p className="mentorChange-p">
                  Location : {mentorChanges?.location}
                </p>
                <p className="mentorChange-p">
                  Language : {mentorChanges?.preferredLanguage}
                </p>
              </div>

              <h2 className="mainHeading mt-5">Changes Detail</h2>
              {/* 
              {mentorChanges?.secondaryCoverPhoto?.path !== "" &&
                mentorChanges?.secondaryCoverPhoto?.path !== "null" &&
                mentorChanges?.secondaryCoverPhoto?.path !== "undefined" &&
                mentorChanges?.secondaryCoverPhoto?.path !== undefined &&
                mentorChanges?.secondaryCoverPhoto?.path !== null && (
                  <div className="col-md-12">
                    {mentorChanges?.secondaryCoverPhoto?.path ? (
                      <>
                        <div className="cover-photo">
                          <img
                            src={`${nodeImageUrl}${mentorChanges?.secondaryCoverPhoto?.path}`}
                            alt="cover"
                            className="image-fluid"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                )} */}
              {(mentorChanges?.secondaryPhoto?.path !== "" ||
                mentorChanges?.secondaryPhoto?.path !== "null" ||
                mentorChanges?.secondaryPhoto?.path !== "undefined" ||
                mentorChanges?.secondaryPhoto?.path !== undefined ||
                mentorChanges?.secondaryPhoto?.path !== null) && (
                <div className="col-md-12">
                  {mentorChanges?.secondaryPhoto?.path ? (
                    <>
                      <div className="displayPhoto">
                        <img
                          src={`${nodeImageUrl}/${mentorChanges?.secondaryPhoto?.path}`}
                          alt="photo"
                          className="image-fluid"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              )}
              {(mentorChanges?.secondaryDisplayName?.name !== "" ||
                mentorChanges?.secondaryDisplayName?.name !== "null" ||
                mentorChanges?.secondaryDisplayName?.name !== "undefined" ||
                mentorChanges?.secondaryDisplayName?.name !== undefined ||
                mentorChanges?.secondaryDisplayName?.name !== null) && (
                <div className="col-md-12">
                  {mentorChanges?.secondaryDisplayName?.name ? (
                    <>
                      <div className="editName-container">
                        <h2 className="mentorChange-name">
                          Display Name :{" "}
                          {mentorChanges?.secondaryDisplayName?.name}
                        </h2>
                        {/* <input
                        type="text"
                        className="form-control input-text inputText input-placeholder text-capitalize inputEdit-text"
                        placeholder={"mentor new name"}
                        value={mentorChanges?.secondaryDisplayName?.name}
                        onChange={(e) => setUserName(e.target.value)}
                      /> */}
                      </div>
                    </>
                  ) : null}
                </div>
              )}
              <div className="col-md-12 d-flex flex-row justify-content-end">
                <div>
                  <Button
                    className="btn btn-user btn-block btn-save"
                    onClick={() => {
                      handleChanges(true);
                    }}
                    disabled={isLoading ? isLoading : gotResponse}
                  >
                    {approveLoading ? "Loading.." : "Approve"}
                  </Button>
                </div>
                <div className="ml-4">
                  <Button
                    className="btn btn-user btn-block btn-save"
                    onClick={() => {
                      //modal message
                      handleModal();
                    }}
                    disabled={isLoading ? isLoading : gotResponse}
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </div>

            <Modal
              className="modal-dialog-centered custom-addLang-modal"
              isOpen={isOpenModal}
              toggle={() => setIsOpenModal(false)}
            >
              <div className="addlang-modalBody">
                <h2 className="text-center">Please write message</h2>
                <div className="innerContainer">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder="Add Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>

                  <Button
                    className="btn btn-user btn-block btn-save"
                    disabled={isSending}
                    onClick={() => {
                      handleChanges(false);
                    }}
                  >
                    {isSending ? "loading..." : "Save"}
                  </Button>
                </div>
              </div>
              {/* </ModalBody> */}
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default MentorChangesDetails;
