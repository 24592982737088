import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get } from "../components/Axios/AxiosFunctions";
import { imageUrl, nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";
import { getMentorChanges } from "../store/actions/mentorActions";
import { SpinnerRoundOutlined } from "spinners-react";
import { useHistory } from "react-router-dom";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";

function MentorChanges(props) {
  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [mentorChangeReqs, setMentorChangeReqs] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const getAllMentorChangeRequests = async () => {
    const allMentorChangeReqApiURL = NODE_URL(
      `mentor/mentor-profile-changes?limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(allMentorChangeReqApiURL, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setMentorChangeReqs(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAllMentorChangeRequests();
  }, [pageNumber]);

  console.log({ mentorChangeReqs });
  return (
    <>
      <div className="container-fluid newMentorRequests">
        <h1 className="h3 mb-2 text-gray-800">Mentor Changes</h1>
        {/* <p className="mb-4">Mentor Changes List</p> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">
              DataTables Example
            </h6> */}
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : (

              mentorChangeReqs?.length > 0 ?
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Current Name</th>
                      <th>Changed Name</th>
                      <th>Country</th>
                      <th>Changed Photo</th>
                      {/* <th>Changed Cover Photo</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mentorChangeReqs?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{index + 1}</td>
                            <td>{item?.displayName}</td>
                            <td>
                              {item?.secondaryDisplayName?.name
                                ? item?.secondaryDisplayName?.name
                                : "---"}
                            </td>
                            <td>{item?.country}</td>
                            <td className="d-flex justify-content-center">
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                                src={`${nodeImageUrl}/${
                                  item?.secondaryPhoto?.path
                                    ? item?.secondaryPhoto?.path
                                    : "---"
                                }`}
                              />
                            </td>
                            {/* <td>
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                                src={`${nodeImageUrl}${
                                  item?.secondaryCoverPhoto?.path
                                    ? item?.secondaryCoverPhoto?.path
                                    : "---"
                                }`}
                              />
                            </td> */}
                            <td>
                              <Button
                                className={`btn btn-user btn-block`}
                                onClick={() => {
                                  console.log("pressed");
                                  dispatch(getMentorChanges(item));
                                  history.push("/MentorChangesDetails");
                                }}
                              >
                                View Changes
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={mentorChangeReqs}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>: <div className="col-md-12 m-5 d-flex justify-content-center align-items-center">
                <h2>No Mentor Changes</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MentorChanges;
