import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

const AddLanguageModal = ({
  language,
  setLanguage,
  addLanguageModal,
  setAddLanguageModal,
  createLanguages,
  modalLoading,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={addLanguageModal}
        toggle={() => setAddLanguageModal(false)}
      >
        {/* <ModalBody> */}
        <div className="cancelModal">
          <i className="fas fa-times-circle"
            onClick={()=>setAddLanguageModal(false)}
          ></i>
        </div>
        <div className="addlang-modalBody">
          <h2 className="text-center">Add Language Modal</h2>
          <div className="innerContainer">
            <div className="form-group">
              <input
                type="text"
                placeholder="Add Language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              />
            </div>

            <Button
              className="btn btn-user btn-block btn-save"
              disabled={language.length > 0 ? false : true}
              onClick={() => {
                // setAddLanguageModal(false);
                createLanguages();
              }}
            >
              {modalLoading ? "Loading.." : "Save"}
            </Button>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default AddLanguageModal;
