import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

const UpdateEmailModal = ({
  isModalVisible,
  setIsModalVisible,
  onClickNo,
  onClickYes,
  confirmTextLine,
  isApiCall,
  state,
  setState,
}) => {
  return (
    <Modal
      className="modal-dialog-centered delete-modal p-4"
      isOpen={isModalVisible}
    >
      <div className="delete-modal-body dlfex flex-column">
        <h4 className="text-center confirmText">{confirmTextLine}</h4>
        <input
          className="block-input mr-4"
          value={state}
          onChange={(e) => {
            setState(e.target.value);
          }}
          disabled={isApiCall}
        />
      </div>
      {/* footer  */}
      <div className="saveBtn-Container mb-3">
        <Button
          className="btn btn-save mr-3"
          onClick={() => {
            onClickYes();
          }}
          disabled={isApiCall}
        >
          {isApiCall ? "Updating..." : "Update"}
        </Button>
        <Button
          className="btn btn-save"
          onClick={() => {
            onClickNo();
          }}
          disabled={isApiCall}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateEmailModal;
