import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

const ReportModal = ({
    reportModal,
    setReportModal,
    subject,
    setSubject,
    description,
    setDescription,
 
    sendReportMessage,
    item, setItem,
    loading,
    setLoading,
}) => {
    console.log(item)
  return (
    <>
      <Modal
        className="modal-dialog-centered custom-addLang-modal"
        isOpen={reportModal}
        // toggle={() => setReportModal(false)}
      >
        {/* <ModalBody> */}
        <div className="cancelModal">
          <i className="fas fa-times-circle"
            onClick={()=>setReportModal(false)}
          ></i>
        </div>
        <div className="addlang-modalBody Report-Modal">
          <h2 className="text-center">Report</h2>
          <div className="innerContainer">
            <div className="form-group">
              <input
                className="inputBox"
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
              <textarea
                className="TextBox"
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

            <Button
              className="btn btn-user btn-block btn-save"
              disabled={subject.length > 0 ? false : true}
              onClick={() => {
                // setReportModal(false);
                sendReportMessage(item);
              }}
            >
              {loading ? "Loading" : "Send" }
            </Button>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};

export default ReportModal;
