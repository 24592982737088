import React from "react";
import "../assets/css/cms.css";

import { useState } from "react/cjs/react.development";
import { nodeApiUrl, NODE_URL } from "../components/Config/apiURL";
import CMSCardTable from "../components/CMSCardTable";
import CMSTestimonalTable from "../components/CMSTestimonalTable";
import { Delete } from "../components/Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CMSHowDoesItWorkTable from "../components/CMSHowDoesItWorkTable";

const CMs = () => {
  // Page Main states
  const [pageMainTitle, setPageMaintitle] = useState("");
  const [pageMainImage, setPageMainImage] = useState(null);
  const [pageMainImageFile, setPageMainImageFile] = useState(null);

  // last section states
  const [lastSecTitle, setLastSectitle] = useState("");
  const [lastSecImage, setLastSecImage] = useState(null);
  const [lastSecImageFile, setLastSecImageFile] = useState(null);

  //  CMS Card table
  const [cmsCardTableTitle, setCmsCardTableTitle] = useState("");
  const [CMSCardTableData, setCMSCardTableData] = useState(
    CMSCardTableDummyData
  );
  const [cmsCardTableImage, setCmsCardTableImage] = useState(null);
  const [cmsCardTableImageFile, setCmsCardTableImageFile] = useState(null);
  const [cmsCardTableIcon, setCmsCardTableIcon] = useState(null);
  const [cmsCardTableIconFile, setCmsCardTableIconFile] = useState(null);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [isDeleting, setIsDeleting] = useState(false);

  // CMS Testimonal states
  const [CMSTestimonalTableData, setCMSTestimonalTableData] = useState(
    CMSTestimonalDummyData
  );
  const [cmsTestimonalTableUsername, setCmsTestimonalTableUsername] =
    useState(null);
  const [cmsTestimonalTableDescr, setCmsTestimonalTableDescr] = useState(null);
  const [cmsTestimonalTableImage, setCmsTestimonalTableImage] = useState(null);
  const [cmsTestimonalTableImageFile, setCmsTestimonalTableImageFile] =
    useState(null);
  const [cmsTestimonalTableLoc, setCmsTestimonalTableLoc] = useState(null);

  // CMS How Does It Work States
  const [CMSHowDoesItWorkTableData, setCMSHowDoesItWorkTableData] = useState(
    CMSHowDoesItWorkDummyData
  );
  const [cmsHowDoesItWorkTableDescr, setCmsHowDoesItWorkTableDescr] =
    useState(null); // description
  const [cmsHowDoesItWorkTableTitle, setCmsHowDoesItWorkTableTitle] =
    useState(null); // title
  const [cmsHowDoesItWorkTableImage, setCmsHowDoesItWorkTableImage] =
    useState(null); // image
  const [cmsHowDoesItWorkTableImageFile, setCmsHowDoesItWorkTableImageFile] = // image file
    useState(null);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //   handleSelectImage
  const handleSelectImage = (event, file, state) => {
    file(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      state(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleDeleteItem = async (item, index) => {
    // setIsDeleting(true);
    // const url = NODE_URL(`${item?._id}`);
    // const resData = await Delete(url, authHeader);
    // if (resData !== undefined) {
    //   let copyData = [...CMSCardTableData];
    //   copyData?.splice(index, 1);
    //   setCMSCardTableData(copyData);
    //   toast?.success("Item Removed Successfully!");
    // } else {
    //   console.log("error");
    // }
    // setIsDeleting(false);
  };

  const handleDeleteTestimonal = async (item, index) => {
    // setIsDeleting(true);
    // const url = NODE_URL(`${item?._id}`);
    // const resData = await Delete(url, authHeader);
    // if (resData !== undefined) {
    //   let copyData = [...CMSTestimonalTableData];
    //   copyData?.splice(index, 1);
    //   setCMSTestimonalTableData(copyData);
    //   toast?.success("Item Removed Successfully!");
    // } else {
    //   console.log("error");
    // }
    // setIsDeleting(false);
  };
  return (
    <div className="p-2">
      <h1 className="mb-4 ml-4">CMS</h1>

      <div className="m-2 col-md-6">
        {/* page main title */}
        <div class="form-group">
          <label for="exampleInputEmail1">Page Main Title</label>
          <input
            type="text"
            className="form-control text-input-cms"
            type="text"
            placeholder="Add Title"
            value={pageMainTitle}
            onChange={(e) => setPageMaintitle(e.target.value)}
          />
        </div>

        {/* Page main image */}
        <div className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event, setPageMainImageFile, setPageMainImage);
            }}
          />
          <label for="exampleInputEmail1">Select Main Image</label>
          <label
            for="img"
            className="btn ml-3 pick-image"
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {pageMainImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {pageMainImage && (
          <div>
            <img
              alt="not fount"
              src={
                pageMainImageFile == null
                  ? `${nodeApiUrl}/${pageMainImage}`
                  : pageMainImage
              }
              className="cms__mainImage"
            />
          </div>
        )}
      </div>

      {/* <hr className="mb-5 mt-5" style={{ height: "5px" }} /> */}
      {/* CMs Card table */}
      <CMSCardTable
        title={cmsCardTableTitle}
        setTitle={setCmsCardTableTitle}
        data={CMSCardTableData}
        setData={setCMSCardTableData}
        image={cmsCardTableImage}
        setImage={setCmsCardTableImage}
        imageFile={cmsCardTableImageFile}
        setImageFile={setCmsCardTableImageFile}
        icon={cmsCardTableIcon}
        setIcon={setCmsCardTableIcon}
        iconFile={cmsCardTableIconFile}
        setIconFile={setCmsCardTableIconFile}
        handleSelectImage={handleSelectImage}
        handleDeleteItem={handleDeleteItem}
      />

      {/* testimonals */}
      <CMSTestimonalTable
        username={cmsTestimonalTableUsername}
        setUsername={setCmsTestimonalTableUsername}
        data={CMSTestimonalTableData}
        setData={setCMSTestimonalTableData}
        image={cmsTestimonalTableImage}
        setImage={setCmsTestimonalTableImage}
        imageFile={cmsTestimonalTableImageFile}
        setImageFile={setCmsTestimonalTableImageFile}
        description={cmsTestimonalTableDescr}
        setDescription={setCmsTestimonalTableDescr}
        location={cmsTestimonalTableLoc}
        setLocation={setCmsTestimonalTableLoc}
        handleSelectImage={handleSelectImage}
        handleDeleteTestimonal={handleDeleteTestimonal}
      />

      {/* how does it work  */}
      <CMSHowDoesItWorkTable
        title={cmsHowDoesItWorkTableTitle}
        setTitle={setCmsHowDoesItWorkTableTitle}
        data={CMSHowDoesItWorkTableData}
        setData={setCMSHowDoesItWorkTableData}
        image={cmsHowDoesItWorkTableImage}
        setImage={setCmsHowDoesItWorkTableImage}
        imageFile={cmsHowDoesItWorkTableImageFile}
        setImageFile={setCmsHowDoesItWorkTableImageFile}
        description={cmsHowDoesItWorkTableDescr}
        setDescription={setCmsHowDoesItWorkTableDescr}
        handleSelectImage={handleSelectImage}
        // handleDeleteTestimonal={handleDeleteTestimonal}
      />
      <div className="m-2 col-md-6 col-xm-12">
        {/* page maion title */}
        <div class="form-group">
          <label for="exampleInputEmail1">Last Section Title</label>
          <input
            type="text"
            className="form-control text-input-cms"
            type="text"
            placeholder="Add Title"
            value={pageMainTitle}
            onChange={(e) => setPageMaintitle(e.target.value)}
          />
        </div>

        {/* Last Section Image */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event, setLastSecImageFile, setLastSecImage);
            }}
          />
          <label for="exampleInputEmail1">
            Select Last Section Background Image
          </label>
          <label
            for="img"
            className="btn ml-3 pick-image"
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {lastSecImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {lastSecImage && (
          <div>
            <img
              alt="not found"
              src={
                lastSecImageFile == null
                  ? `${nodeApiUrl}/${lastSecImage}`
                  : lastSecImage
              }
              className="cms__mainImage"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CMs;

const CMSCardTableDummyData = [
  {
    id: 1,
    image:
      "https://ment-go.herokuapp.com/images/cards-images/relationships.png",
    icon: "https://ment-go.herokuapp.com/images/icon2.png",
    title: "Relationships",
  },
];

const CMSTestimonalDummyData = [
  {
    id: 1,
    description:
      "Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum Lorem ipseum",
    name: "Elizabeth",
    image:
      "https://ment-go.herokuapp.com/images/cards-images/relationships.png",
    location: "New York, USA",
  },
];

const CMSHowDoesItWorkDummyData = [
  {
    id: 1,
    description: "Read profiles, find a mentor that resonates with you",
    title: "Choose your Modern Mentor",
    image: require("../assets/images/work-1.png"),
  },
  {
    id: 2,
    description: "Schedule appointments at a time and date that work for you",
    title: "Schedule a Consultation",
    image: require("../assets/images/work-2.png"),
  },
  {
    id: 2,
    description: "Connect with your mentor via video chat!",
    title: "Start your Journey!",
    image: require("../assets/images/work-3.png"),
  },
];
