import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get, Post } from "../components/Axios/AxiosFunctions";
import { imageUrl, nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";
import { SpinnerRoundOutlined } from "spinners-react";
import RadioButton from "../components/RadioButton";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const BookingStatistics = () => {
  const [limit, setLimit] = useState(250);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [bookings, setBookings] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [allStatusToggle, setAllStatusToggle] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bookingStatus, setBookingStatus] = useState([
    {
      id: 1,
      status: "Waiting",
      check: false,
      statusToMatch: ["pending"],
    },
    {
      id: 2,
      status: "Accepted",
      check: false,
      statusToMatch: ["upcoming"],
    },
    {
      id: 3,
      status: "Action Required (Mentee)",
      check: false,
      statusToMatch: [
        "mentee action required",
        "pending, mentee action required",
      ],
    },
    {
      id: 4,
      status: "Action Required (Mentor)",
      check: false,
      statusToMatch: ["mentor action required"],
    },
    {
      id: 5,
      status: "Disputes",
      check: false,
      statusToMatch: ["disputed"],
    },
    {
      id: 6,
      status: "Completed",
      check: false,
      statusToMatch: ["completed"],
    },
    {
      id: 7,
      status: "There was a problem",
      check: false,
      statusToMatch: [
        "there was a problem for mentee",
        "there was a problem for mentor",
      ],
    },
    {
      id: 8,
      status: "Cancelled",
      check: false,
      statusToMatch: ["cancelled"],
    },
  ]);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getBookingStatistics = async (oldData, pageNo, allStatus) => {
    const allBookingStatisticsURL = NODE_URL(
      `booking/statistics?limit=${limit}&page=${pageNo}`
    );
    setIsFetchingData(true);

    let statusArray = [];

    allStatus?.map((ele) => {
      if (ele?.check === true) {
        statusArray.push(...ele?.statusToMatch);
      }
    });

    console.log({ statusArray });
    let tomorrow = new Date(startDate);
    let _endDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
    let params = {
      _BookingStatus: statusArray,
      start: startDate,
      end: endDate === null ? _endDate : endDate,
      week: true,
      month: true,
      year: true,
    };

    const responseData = await Post(
      allBookingStatisticsURL,
      params,
      authHeader
    );
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength = pageNo === 1 ? pageNo * limit : (pageNo - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...oldData, ...responseData?.data?.data];
      setBookings(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  const onChange = (dates) => {
    console.log(dates);
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getBookingStatistics([], pageNumber, bookingStatus);
  }, [pageNumber]);
  return (
    <>
      <div className="container-fluid newMentorRequests">
        <h1 className="h3 mb-2 mt-2 text-gray-800">Booking Statistics</h1>
        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h4>Select Status</h4>
              </div>
              <div className="col-md-3">
                <FormControlLabel
                  onClick={() => {
                    let copyState = [...bookingStatus];
                    copyState = copyState?.map((ele) => {
                      let prev = { ...ele };
                      prev.check = !allStatusToggle;
                      return prev;
                    });
                    setAllStatusToggle(!allStatusToggle);
                    setBookingStatus(copyState);
                    getBookingStatistics([], 1, copyState);
                  }}
                  value={"All"}
                  disabled={isFetchingData}
                  control={<Radio checked={allStatusToggle} />}
                  label={"All"}
                />
              </div>
              {bookingStatus?.map((ele, index) => {
                return (
                  <RadioButton
                    callApi={getBookingStatistics}
                    isApiCall={isFetchingData}
                    checked={ele?.check}
                    label={ele?.status}
                    index={index}
                    state={bookingStatus}
                    setState={setBookingStatus}
                  />
                );
              })}
              <div className="col-md-12 mt-2 text-center">
                <h4>Select Date</h4>
              </div>
              <div className="col-md-12 mt-3 mb-2 text-center">
                <ReactDatePicker
                  className="testing"
                  selected={startDate}
                  // selected={new Date(startDate)}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                  monthsShown={2}
                  style={{ borderLeft: "1px solid red" }}
                />
              </div>
              <div className="col-md-12 mt-3 mb-4 text-center">
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#044d81",
                    color: "white",
                    width: "250px",
                    height: "50px",
                  }}
                  disabled={isFetchingData}
                  onClick={() => {
                    getBookingStatistics([], 1, bookingStatus);
                  }}
                >
                  Search By Calender Date
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Month</th>
                    <th>Week</th>
                    <th>Year</th>
                    <th>Booking Status</th>
                    <th>No. of Sessions</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{item?._id?.month}</td>
                          <td>{item?._id?.week}</td>
                          <td>{item?._id?.year}</td>
                          {item?._id?.BookingStatus === "pending" && (
                            <td>{"Waiting"}</td>
                          )}
                          {item?._id?.BookingStatus === "cancelled" && (
                            <td>{"Cancelled"}</td>
                          )}
                          {item?._id?.BookingStatus === "completed" && (
                            <td>{"Completed"}</td>
                          )}
                          {item?._id?.BookingStatus === "upcoming" && (
                            <td>{"Accepted"}</td>
                          )}
                          {item?._id?.BookingStatus ===
                            "pending, mentee action required" && (
                            <td>{"Action Required (Mentee)"}</td>
                          )}
                          {item?._id?.BookingStatus ===
                            "mentee action required" && (
                            <td>{"Action Required (Mentee)"}</td>
                          )}
                          {item?._id?.BookingStatus ===
                            "mentor action required" && (
                            <td>{"Action Required (Mentor)"}</td>
                          )}
                          {item?._id?.BookingStatus === "disputed" && (
                            <td>{"Disputed"}</td>
                          )}
                          {item?._id?.BookingStatus ===
                            "there was a problem or mentee" && (
                            <td>{"There was a problem"}</td>
                          )}
                          {item?._id?.BookingStatus ===
                            "there was a problem or mentor" && (
                            <td>{"There was a problem"}</td>
                          )}
                          <td>{item?.numSessions}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={bookings}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingStatistics;
