import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "reactstrap";
import RenderOptions from "./RenderOptions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { IconButton, withStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
const AddSessionProblemModal = ({
  setIsShowModal,
  isShowModal,
  isApiCall,
  title,
  buttonText,
  onClick,
  isUpdating,
  questionToUpdate,
}) => {
  const [probCase, setProbCase] = useState("mentee");
  const [text, setText] = useState("");
  const [defaultCase, setDefaultCase] = useState("mentee");
  const [question, setQuestion] = useState({
    options: [
      {
        opt: "",
        defaultCase: "mentee",
      },
    ],
    question: text,
    isActive: true,
    for: "mentee",
  });

  useEffect(() => {
    if (isUpdating) {
      setQuestion(questionToUpdate);
      setProbCase(questionToUpdate?.for);
      setText(questionToUpdate?.question);
    } else {
      setProbCase("mentee");
    }
  }, [isShowModal]);

  const handleChangeProbCase = (event) => {
    setProbCase(event.target.value);
    setQuestion((prev) => {
      return {
        ...prev,
        for: event.target.value,
      };
    });
  };

  const addNewOption = (e) => {
    setQuestion((prevState) => {
      return {
        ...prevState,
        options: [
          ...prevState.options,
          {
            opt: "",
            defaultCase: "mentee",
          },
        ],
      };
    });
  };

  const removeOption = (record) => {
    setQuestion((prev) => {
      return {
        ...prev,
        options: question?.options?.filter((r) => r !== record),
      };
    });
  };

  const resetStates = () => {
    setQuestion({
      options: [
        {
          opt: "",
          defaultCase: "mentee",
        },
      ],
      question: "",
      isActive: true,
      for: "mentee",
    });
    setProbCase("mentee");
    setText("");
    setDefaultCase("mentee");
  };

  return (
    <Modal
      className="modal-dialog-centered add-sess-prob-modal"
      isOpen={isShowModal}
    >
      <div className="addlang-modalBody">
        <h2 className="text-center">{title}</h2>
        <div className="innerContainer">
          <textarea
            style={{ whiteSpace: "pre-line" }}
            className="form-control"
            type="text"
            rows={2}
            placeholder="Enter particular problem"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              setQuestion((prev) => {
                return {
                  ...prev,
                  question: e.target.value,
                };
              });
            }}
          />

          <div className="row">
            <div className="col-md-12">
              <p className="pref-opt mt-4">Preferred Options</p>
            </div>
            {question?.options?.map((option, idx) => {
              return (
                <RenderOptions
                  key={`answer-${idx}`}
                  index={idx}
                  removeOption={removeOption}
                  option={option}
                  setQuestion={setQuestion}
                  options={question?.options}
                  isUpdating={isUpdating}
                  setDefaultCase={setDefaultCase}
                  defaultCase={defaultCase}
                />
              );
            })}
            <div className="col-md-12 mt-2">
              {question?.options?.length < 3 ? (
                <div
                  className="add-div"
                  onClick={() => {
                    addNewOption();
                  }}
                >
                  <i
                    style={{
                      fontSize: "20px",
                      color: "white",
                      cursor: "pointer",
                    }}
                    className="fas fa-plus"
                  ></i>
                  <p
                    className="ml-2 mb-0"
                    style={{ color: "white", cursor: "pointer" }}
                  >
                    Add an option
                  </p>
                </div>
              ) : null}
            </div>

            <div className="col-md-12 d-flex flex-row mt-4">
              <p className="default-case mr-3 mt-2">
                Problem Case Refers For:{" "}
              </p>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="probCase"
                  name="probCase1"
                  value={probCase}
                  onChange={(e) => handleChangeProbCase(e)}
                >
                  <div className="d-flex flex-row">
                    <FormControlLabel
                      value="mentor"
                      control={<Radio color="secondary" />}
                      label="Mentor"
                      style={{ color: "white" }}
                    />
                    <FormControlLabel
                      value="mentee"
                      control={<Radio color="secondary" />}
                      label="Mentee"
                      style={{ color: "white" }}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>

            <div className="col-md-12 mt-4">
              <button
                className="btn add-confirm-ques mr-4"
                disabled={isApiCall}
                onClick={async () => {
                  await onClick(question);
                  resetStates();
                }}
              >
                {isApiCall ? "Please Wait" : buttonText}
              </button>

              <button
                className="btn cancel-confirm-ques"
                disabled={isApiCall}
                onClick={() => {
                  resetStates();
                  setIsShowModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSessionProblemModal;
