import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { NODE_URL } from "../components/Config/apiURL";
import { Delete, Get, Post } from "../components/Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SpinnerRoundOutlined } from "spinners-react";
import { ToastContainer, toast } from "react-toastify";
import { CSVLink, CSVDownload } from "react-csv";
import CustomCheckbox from "../components/CustomCheckbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function PaymentRequest(props) {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [withdrawType, setWithdrawType] = useState("regular");
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selected, setSelected] = useState([]);

  const getPaymentApi = () => {
    var config = {
      method: "get",
      url: NODE_URL(
        `transaction/pending-withdraws?withdrawType=${withdrawType}`
      ),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        setIsFetchingData(true);

        setPaymentRequests(response?.data?.data);
        let arr = [["S.No", "email", "Withdraw Amount", "Withdraw Type"]];
        response?.data?.data.map((item, index) => {
          arr.push([
            index + 1,
            item.UserId.email,
            item.pending.amount,
            item.pending.withdrawType,
          ]);
        });
        setCsvData((p) => [...p, ...arr]);
        setIsFetchingData(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log({ withdrawType });
    setIsFetchingData(true);
  }, [withdrawType]);

  useEffect(() => {
    getPaymentApi();
  }, [withdrawType]);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const formDataHeader = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const approvePaymentRequests = async () => {
    var config = {
      method: "get",
      url: NODE_URL(
        `transaction/clear-pending-list?withdrawType=${withdrawType}`
      ),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    // setIsFetchingData(true);
    setLoading(true);
    axios(config)
      .then(function (response) {
        toast.success("Successs");
        setPaymentRequests([]);
        // setPaymentRequests(response?.data?.data);
        // let arr = paymentRequests.slice();
        // arr.splice(index);
        // setPaymentRequests(arr)
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
    setLoading(false);
    // setIsFetchingData(false);
  };

  // const createFormData = (file) => {
  //   let data = new FormData();
  //   file?.selectedIds.forEach((item) => {
  //     data.append("mentorIds", item);
  //   });
  //   data.append("withdrawType", file.withdrawType);
  //   data.append("approve", file.approve);
  //   return data;
  // };

  const acceptDeclinedPaymentRequests = async (item, index, flag) => {
    console.log(item?._id)
    console.log(paymentRequests)

   

    const url = NODE_URL(`transaction/approve-decline-withdraws-request`);
    let params = {
      mentorId: item?._id,
      approve: flag,
      withdrawType: withdrawType,
      // there is no transaction id in this parameter is wrong just :(   Imignoring it at the backend (basit)
      transactionId: item?._id,
    };
    setLoading(true);

    const responseData = await Post(url, params, authHeader);
    console.log(responseData)


    if(responseData?.status === 200 ){
      paymentRequests.splice(item?._id)
      //  paymentRequests.filter((item,index)=>{
      //   return item?._id !== item?._id
      // })
    console.log(paymentRequests)

    }

    if (responseData?.status === 200) {
      console.log(item)
      toast.success(`Payment Request ${flag ? "Approved" : "Declined"}!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      getPaymentApi();
    } else {
      console.log("error");
    }
    setLoading(false);
  };

  // const handleCheckbox = (id, index) => {
  //   let copyData = [...selected];

  //   if (index !== -1) {
  //     copyData?.splice(index, 1);
  //   } else {
  //     copyData.push(id);
  //   }
  //   setSelected(copyData);
  // };

  // const declinePaymentRequest = async (item, index) => {
  //   const deleteURL = NODE_URL(`transaction/decline-payments`);
  //   let params = {
  //     mentorId: item?._id,
  //     withdrawType: withdrawType,
  //   };
  //   setLoading(true);
  //   const resData = await Post(deleteURL, params, authHeader);

  //   if (resData !== undefined) {
  //     let copyData = [...paymentRequests];
  //     copyData?.splice(index, 1);
  //     setPaymentRequests(copyData);
  //     console.log({ copyData });
  //     toast.success("Payment Request Declined.", {
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: false,
  //       progress: undefined,
  //     });
  //   } else {
  //     console.log("error");
  //   }
  //   setLoading(false);
  // };

  
  return (
    <>
      <div className="container-fluid payment-req">
        <h1 className="h3 mb-2 text-gray-800">Payment Requests</h1>

        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex ">
            <h6 className="m-0 font-weight-bold text-primary m-2">
              Withdraw Requests
            </h6>
            {isFetchingData ? (
              <p className="wait-call-pay mt-1">Please Wait...</p>
            ) : (
              <DropdownButton
                id="dropdown-basic-button"
                title={`${withdrawType}`}
                onSelect={(e) => {
                  setWithdrawType(e);
                  console.log(withdrawType);
                }}
              >
                <Dropdown.Item eventKey="regular">regular</Dropdown.Item>
                <Dropdown.Item eventKey="express">express</Dropdown.Item>
              </DropdownButton>
            )}
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : paymentRequests?.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      {/* <th>Name</th> */}
                      {/* <th>Selection</th> */}
                      <th>S.NO</th>
                      <th>User Id</th>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Withdraw Amount</th>
                      <th>Withdraw Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentRequests?.map((item, key) => {
                      let index = selected?.findIndex((x) => x == item?._id);

                      return (
                        <>
                          <tr key={item._id}>
                            {/* <td>
                              <input
                                className="ml-4"
                                defaultChecked={index !== -1 ? true : false}
                                type="checkbox"
                                id={`check-box-t${item?._id}`}
                                value={item?._id}
                                onClick={() => handleCheckbox(item?._id, index)}
                              />
                            </td> */}
                            <td>{key + 1}</td>
                            <td>{item?._id}</td>
                            <td>{item?.PaypalEmail}</td>
                            <td>{item?.displayName}</td>
                            <td>{`$${item?.pending?.amount}`}</td>
                            <td>
                              {item?.pending?.withdrawType?.toUpperCase()}
                            </td>
                            <td>
                              <button
                                className="btn decline-btn mt-4 mb-4 mr-4"
                                disabled={loading}
                                onClick={() => {
                                  acceptDeclinedPaymentRequests(
                                    item,
                                    index,
                                    false
                                  );
                                }}
                              >
                                {"Decline"}
                              </button>
                          
                              <button
                                className="btn accept-btn mt-4 mb-4"
                                disabled={loading}
                                onClick={() => {
                                  acceptDeclinedPaymentRequests(
                                    item,
                                    index,
                                    true
                                  );
                                }}
                              >
                                {"Approve"}
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {/* <button
                    className="btn stats-btn mt-4 mb-4"
                    disabled={loading}
                    onClick={() => approvePaymentRequests()}
                  >
                    {loading ? "Loading.." : "Approve All"}
                  </button> */}

                  {/* <button
                    className="btn stats-btn mt-4 mb-4"
                    disabled={loading}
                    onClick={() => {
                      acceptSelectedPaymentRequests(true);
                    }}
                  >
                    {loading ? "Please Wait" : "Approve Selected"}
                  </button> */}

                  {/* <button
                    className="btn stats-btn mt-4 mb-4"
                    disabled={loading}
                  >
                    <CSVLink
                      data={csvData}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Download CSV (All Records)
                    </CSVLink>
                  </button> */}
                </div>
              </div>
            ) : (
              <div className="col-md-12 m-5 d-flex justify-content-center align-items-center">
                <h2>No Payment Requests</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentRequest;
