import React, { useState } from "react";
import { useSelector } from "react-redux";
import { nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import GOODWILL from "../assets/images/admin-giveaway.png";
import { Patch } from "../components/Axios/AxiosFunctions";
import { toast } from "react-toastify";

var numberRegex = "/^$?[0-9]+.?[0-9]*$/";
const AdminGoodwill = () => {
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [isApiCall, setIsApiCall] = useState(false);
  const [selectedUserMode, setSelectedUserMode] = useState("mentees");
  const [amount, setAmount] = useState(0);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const giveGoodwill = async (userMode) => {
    console.log(amount)
    if (amount <= 0) {
      toast.success("Please enter valid amount!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      let params = {
        userMode: userMode,
        amount: amount,
      };
      const goodwilURL = NODE_URL("admin/goodwill");
      setIsApiCall(true);
      const resData = await Patch(goodwilURL, params, authHeader);

      if (resData !== undefined) {
        if (userMode === "mentees") {
          setSelectedUserMode("mentees");
          toast.success("🙌 Goodwill given to all mentees!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        } else {
          setSelectedUserMode("mentors");
          toast.success("🙌 Goodwill given to all mentors!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      } else {
        console.log("error");
      }
      setIsApiCall(false);
    }
  };
  return (
    <div className="container-fluid admin-goodwill">
      <h1 className="h3 mb-2 text-gray-800">Admin Goodwill</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row">
            <div className="col-md-12">
              <div className="cover-photo">
                <img
                  src={`${GOODWILL}`}
                  alt="cover"
                  style={{ height: "300px", width: "100%" }}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
                <h2 className="mb-3">Enter Giveaway Amount:</h2>
              <input
                type="number"
                className="form-control"
                style={{ width: "250px" }}
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);

                  //   if (
                  //     e.target.value.length == 1 &&
                  //     typeof e.target.value == Number
                  //   ) {
                  //     setAmount(e.target.value);
                  //   } else if (
                  //     typeof e.target.value[e.target.value.length - 1] == Number
                  //   ) {
                  //     setAmount(e.target.value);
                  //   }
                }}
              />
              <div className="d-flex flex-row mt-3">
                <button
                  type="button"
                  disabled={isApiCall}
                  className={`mr-2 btn ${
                    selectedUserMode === "mentees"
                      ? "giveaway-applied"
                      : "giveaway-not-applied"
                  }`}
                  onClick={() => {
                    giveGoodwill("mentees");
                  }}
                >
                  To All Mentees
                </button>
                <button
                  type="button"
                  disabled={isApiCall}
                  className={`btn ${
                    selectedUserMode === "mentors"
                      ? "giveaway-applied"
                      : "giveaway-not-applied"
                  }`}
                  onClick={() => {
                    giveGoodwill("mentors");
                  }}
                >
                  To All Mentors
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminGoodwill;
