import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";
import { getNewMentors } from "../store/actions/mentorActions";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";

function NewMentorRequests(props) {
  const { setChoice } = props;
  const newMentors = useSelector(
    (state) => state.mentorReducer.unApprovedMentors
  );
  const [unApprovedMentors, setUnApprovedMentors] = useState([]);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [name, setName] = useState("");
  // const [name, setName] = useState("");
  const [userid, setUserid] = useState("");
  const [itemIndex, setItemIndex] = useState("");
  const [isSending, setIsSending] = useState("");
  const [textMessage, setTextMessage] = useState(``);

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    var config = {
      method: "get",
      url: NODE_URL(`mentor/pending?limit=${limit}&page=${pageNumber}`),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setIsFetchingData(true);
        let maxLastLength =
          pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
        setMaxLength(maxLastLength);

        // let oldData = unApprovedMentors.slice();
        // oldData.splice(response?.data?.data)

        setUnApprovedMentors(response?.data?.data);
        setIsFetchingData(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pageNumber]);

  const approve = (id, index) => {
    var config = {
      method: "patch",
      url: NODE_URL(`admin/approve-mentor/${id}`),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        console.log(response);
        // toast.success("Approved");
        let arr = unApprovedMentors.slice();
        arr.splice(index, 1);
        setUnApprovedMentors(arr);
        toast.success("Mentor Request Approved!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error);
        // toast.error(error);
      });
    setLoading(false);
  };

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const decline = async (id, index) => {
    let obj = {
      textMessage,
    };
    const collector = NODE_URL(`admin/decline-mentor/${id}`);
    const responseData = await Patch(collector, obj, authHeader);

    setDeclineLoading(true);
    if (responseData?.data) {
      let arr = unApprovedMentors.slice();
      arr.splice(index, 1);
      setUnApprovedMentors(arr);
      // console.log(declineLoading)
      toast.success("Mentor Request Declined!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setIsOpenModal(false);
    } else {
      toast.error("Error");
    }
    setDeclineLoading(false);
  };

  const viewHandler = () => {
    setChoice("NewMentorRequestDetails");
  };

  const handleSubmit = () => {
    decline(userid, itemIndex);
  };
  console.log("test-data ", unApprovedMentors);
  console.log("test-name ", name);

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">New Mentor Requests</h1>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">
              New Mentor Request
            </h6> */}
          </div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : unApprovedMentors?.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Photo</th>
                      {/* <th>Id</th> */}
                      <th>DOB</th>
                      <th>Language</th>
                      <th>Location</th>
                      <th>Timezone</th>
                      <th>Rating Avg</th>
                      <th>Total Balance</th>
                      <th></th>
                      <th>Approve</th>
                      <th>Decline</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unApprovedMentors.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{item?.displayName}</td>
                            <td>
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                                src={`${nodeImageUrl}/${item?.photo}`}
                              />
                            </td>
                            <td>{moment(item?.dob).format("MMM/DD/YYYY")}</td>
                            {/* <td>{item._id}</td> */}
                            <td>{item?.preferredLanguage}</td>
                            <td>{`${item?.city}/${item?.country}`}</td>
                            <td>{item?.timezone}</td>
                            <td>{item?.ratingsAverage}</td>
                            <td>{item?.mentorBalance}</td>
                            {/* <td>View Detail</td> */}
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${"btn-pending"}`}
                                onClick={() => {
                                  history.push("/NewMentorRequestDetails");
                                  dispatch(getNewMentors(item));
                                  console.log(item);
                                }}
                              >
                                View Detail
                              </Button>
                            </td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => approve(item.UserId._id, index)}
                                disabled={loading}
                              >
                                {/* {item.active ? "Pending" : "Approve"} */}
                                {loading ? "Approving..." : " Approve"}
                              </Button>
                            </td>
                            <td>
                              <Button
                                className={`btn btn-user btn-block decline-color ${
                                  item.active ? "btn-approved" : "btn-pending"
                                }`}
                                onClick={() => {
                                  // setName(item?.displayName)
                                  setTextMessage(`
From: MentGo
Subject:  Your Application to MentGo
Dear ${item?.displayName},

Thank you for your application to become a Mentor on MentGo!  Unfortunately, your application was declined.
Thank you again for your consideration.

Sincerely,
MentGo
`);
                                  setIsOpenModal(true);
                                  setItemIndex(index);
                                  setUserid(item.UserId._id);
                                }}
                                disabled={declineLoading}
                              >
                                {/* {item.active ? "Pending" : "Decline"} */}

                                {declineLoading ? "Declining..." : " Decline"}
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <Modal
                  className="modal-dialog-centered custom-addLang-modal1"
                  isOpen={isOpenModal}
                >
                  <div className="addlang-modalBody">
                    <h2 className="text-center">Text message</h2>
                    <div className="innerContainer">
                      <div className="form-group">
                        <textarea
                          style={{ whiteSpace: "pre-line" }}
                          className="form-control"
                          type="text"
                          rows={13}
                          placeholder="Add Message"
                          value={textMessage}
                          onChange={(e) => setTextMessage(e.target.value)}
                        />
                      </div>

                      <Button
                        className="btn btn-user btn-block btn-save mr-2"
                        disabled={declineLoading}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {declineLoading ? "Please Wait" : "Submit"}
                      </Button>
                      <Button
                        className="btn btn-user btn-block btn-save"
                        style={{ marginLeft: "3px", marginBottom: "9px" }}
                        disabled={declineLoading}
                        onClick={() => {
                          setIsOpenModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Modal>
                <div className="pagination">
                  <Pagination
                    maxLastLength={maxLength}
                    array={unApprovedMentors}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-12 m-5 d-flex justify-content-center align-items-center">
                <h2>No New Mentor Requests</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewMentorRequests;

// return (
//   <>
//     {/* <!-- DataTales Example --> */}
//     <div className="card shadow mb-4">
//       <div className="card-header py-3">
//         <h6 className="m-0 font-weight-bold text-primary">
//           DataTables Example
//         </h6>
//       </div>
//       <div className="card-body">
//         <div className="table-responsive">
//           <table
//             className="table table-bordered"
//             id="dataTable"
//             width="100%"
//             cellspacing="0"
//           >
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Id</th>
//                 <th>data</th>
//                 <th>data</th>
//                 <th>Total Balance</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {NewMentorRequest.map((item) => {
//                 return (
//                   <>
//                     <tr key={item.id} onClick={() => viewHandler()}>
//                       <td>{item.name}</td>
//                       <td>{item.m_id}</td>
//                       <td>{item.data}</td>
//                       <td>{item.country}</td>
//                       <td>{item.balance}</td>
//                       <td>
//                         <Button
//                           className={`btn btn-user btn-block ${
//                             item.status ? "btn-approved" : "btn-pending"
//                           }`}
//                           onClick={console.log("approved")}
//                         >
//                           {item.status ? "Approved" : "Pending"}
//                         </Button>
//                       </td>
//                     </tr>
//                   </>
//                 );
//               })}
//             </tbody>
//           </table>
//           <div className="pagination">
//             <Pagination />
//           </div>
//         </div>
//       </div>
//     </div>

//     {/* <!-- /.container-fluid --> */}
