export const SIGN_OUT = "SIGN_OUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const NEW_MENTOR_REQUEST = "NEW_MENTOR_REQUEST";
export const MENTOR_CHANGES = "MENTOR_CHANGES";
export const  APPROVED_MENTOR = " APPROVED_MENTOR";
export const  REPORT_BY = " REPORT_BY";
export const  DEACTIVATED_DETAILS = " DEACTIVATED_DETAILS";
export const  ALL_MENTEE = " ALL_MENTEE";
export const DISPUTES = " DISPUTES";
export const UNRESOLVED_SESSIONS = "UNRESOLVED_SESSIONS";
// export const MENTEEDETAILS = "MENTEEDETAILS";

