import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const DeactivateMentor = ({
  isModalVisible,
  setIsModalVisible,
  onClickYes,
  isApiCall,
}) => {
  const [deactivateText, setDeactvateText] = React.useState("");

  return (
    <Modal
      className="modal-dialog-centered delete-modal p-4"
      isOpen={isModalVisible}
    >
      <div className="delete-modal-body">
        <h4 className="text-center confirmText">Deactivate Mentor</h4>
      </div>
      {/* Inpout */}
      <div
        className="form-group"
        style={{
          margin: "10px 20px",
        }}
      >
        <label for="exampleFormControlTextarea1">Enter Message Here</label>
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          value={deactivateText}
          onChange={(e) => setDeactvateText(e.target.value)}
        ></textarea>
      </div>
      {/* footer  */}
      <div className="saveBtn-Container mb-3">
        <Button
          className="btn btn-save mr-3"
          onClick={async() => {
            if (deactivateText.trim() == "") {
              return toast.error("Please enter your message.");
            }
            await onClickYes({
              textMessage: deactivateText,
            });
          }}
          disabled={isApiCall}
        >
          {isApiCall ? "Please wait..." : "Yes"}
        </Button>
        <Button
          className="btn btn-save"
          onClick={() => setIsModalVisible(false)}
          disabled={isApiCall}
        >
          No
        </Button>
      </div>
    </Modal>
  );
};

export default DeactivateMentor;
