import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import TipsAndPromotionsModal from "../components/TipsAndPromotionsModal";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { NODE_URL, baseUrl } from "../components/Config/apiURL";
import MediaCard from "../components/TPCard";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";

function TipsAndPromotions(props) {
  const { setChoice } = props;
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [tips, setTips] = useState([]);
  const [language, setLanguage] = useState([]);
  const [img, setImg] = useState({});
  const [title, setTitle] = useState("");
  const [descriptionField, setDescriptionField] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [loading, setLoading] = useState("");
  const [modalLoading, setModalLoading] = useState(false);

  const [addModal, setAddModal] = useState(false);
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const imgAuthHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const getAlltips = async () => {
    const collector = NODE_URL(
      `tipsAndPromotions?limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(collector, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];

      setTips(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAlltips();
  }, [pageNumber]);

  const createFormdata = async (data) => {
    let formData = new FormData();
    formData.append("URL", data?.img);
    formData.append("title", data?.title);
    formData.append("description", data?.descriptionField);
    formData.append("test", "test");
    console.log({ formData });
    return formData;
  };

  const createTipsAndPromotions = async () => {
    let obj = {
      img,
      title,
      descriptionField,
    };
    let formData = await createFormdata(obj);

    Promise.all(formData).then((v) => (formData = v));
    const collector = NODE_URL(`tipsAndPromotions`);
    const responseData = await Post(collector, formData, imgAuthHeader);
    if (responseData !== undefined) {
      setAddModal(false)
      toast.success("Successfully Create new tips and promotion")
      console.log(responseData?.data?.data);
      let arr = tips.slice();
      arr.push(responseData?.data?.data);
      setTips(arr);
    } else {
      console.log("error");
      toast.error("Error")
    }
  };

  const activeInactive = async (id, status, index) => {
    let obj = {
      isActive: !status,
    };
    const collector = NODE_URL(`tipsAndPromotions/${id}`);
    const responseData = await Patch(collector, obj, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let arr = tips.slice();
      arr.splice(index, 1, responseData?.data?.data);
      setTips(arr);
    } else {
      console.log("error");
      toast.error("Error")
    }
  };
  const deleteRecord = async (id, status, index) => {
    const collector = NODE_URL(`tipsAndPromotions/${id}`);
    setLoading(id);
    const responseData = await Delete(collector, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      toast.success("Successfully Delete Record")
      let arr = tips.slice();
      arr.splice(index, 1);
      setTips(arr);
    } else {
      console.log("error");
      toast.error("Error")
    }
    setLoading("")
  };

  const uploadImages = async (event) => {
    setImg(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  return (
    <>
      <div className="card shadow mb-4 featureLang tipsAndPromo">
        <div className="card-header py-3 ">
          <div className="d-flex align-items-center justify-contnet-between">
            <div>
              <h6 className="m-0 font-weight-bold text-primary m-2">
                Add Tips And Promotions
              </h6>
            </div>
            <div className="addBtn text-center">
              <i
                className="fas fa-plus-circle"
                onClick={() => setAddModal(true)}
              ></i>
            </div>
          </div>
        </div>
        <div className="card-body">
        {isFetchingData ?
              (
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={isFetchingData}
                    thickness={200}
                    color="#044d81"
                  />{" "}
                </div>
              ) : (
          <div className="table-responsive">
            <div className="row">
            {tips.map((item, i) => {
              return (
                <>
                  <MediaCard
                    item={item}
                    index={i}
                    activeInactive={activeInactive}
                    deleteRecord={deleteRecord}
                    loading={loading}
                    modalLoading={modalLoading}
                  />
                </>
              );
            })}
            </div>
          </div>
          )}
        </div>
      </div>

      <TipsAndPromotionsModal
        title={title}
        setTitle={setTitle}
        descriptionField={descriptionField}
        setDescriptionField={setDescriptionField}
        setAddModal={setAddModal}
        addModal={addModal}
        uploadImages={uploadImages}
        createTipsAndPromotions={createTipsAndPromotions}
      />
    </>
  );
}

export default TipsAndPromotions;

// <table
//   className="table table-bordered"
//   id="dataTable"
//   width="100%"
//   cellspacing="0"
// >
//   <thead>
//     <tr>
//       <th>Title</th>
//       <th>Description</th>
//       <th>Status</th>
//       <th></th>
//       <th>Delete</th>
//       {/* <th></th> */}
//     </tr>
//   </thead>
//   <tbody>
//     {tips.map((item, i) => {
//       return (
//         <>
//           <MediaCard
//             item={item}
//             activeInactive={activeInactive}
//             deleteRecord={deleteRecord}
//           />
//           {/* <tr>
//             <img
//               src={`${baseUrl}/img/tips_and_promotion/${item.URL}`}
//             />
//           </tr>
//           <tr key={item._id}>
//             <td>{item.title}</td>
//             <td>{item.description}</td>
//             <td>{item.isActive ? "Active" : "Inactive"}</td>
//             <td className="text-center">
//               <Button
//                 className={`btn btn-user btn-block ${
//                   item.isActive ? "inactiveBtn" : "activeBtn"
//                 }`}
//                 onClick={() =>
//                   activeInactive(item._id, item.isActive, i)
//                 }
//               >
//                 {item.isActive ? "Inactive" : "Active"}
//               </Button>
//             </td>
//             <td className="text-center">
//               <Button
//                 className={`btn btn-user btn-block activeBtn`}
//                 onClick={() =>
//                   deleteRecord(item._id, item.isActive, i)
//                 }
//               >
//                 Delete
//               </Button>
//             </td>
//           </tr> */}
//         </>
//       );
//     })}
//   </tbody>
// </table>
