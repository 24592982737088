export const baseUrl = "https://backend.mentgo.com";
// export const baseUrl = "https://b809-103-244-178-85.ngrok.io";

// export const baseUrl = "https://5abb-2400-adc1-418-9a00-a58c-236c-d8a7-9b43.ngrok.io"; // qasim
// export const baseUrl ="https://5fe5-2400-adc1-418-9a00-c534-3fbd-5607-42c7.ngrok.io"; // basit
// export const baseUrl = "https://cefa-103-244-178-85.ngrok.io"; // Hamza

// stripe public
export const stripe_public_key =
  "pk_test_51J6GopJgrefmqo19p8Q09Td5DK3VGXXBxO0rZIZR4issnMtWLFY1HUfATJQnHOoqkxUkRQG2ig9Pjyqu7AcZtfxX00eIdpdjD3";

export const nodeApiUrl = `${baseUrl}/api/v1`;
export const nodeImageUrl = `${baseUrl}/api/images`;
export const iCalenderUrl = `${baseUrl}`;
// export var numberRegex = "/^$?[0-9]+.?[0-9]*$/";
export const NODE_URL = (link) => {
  return `${nodeApiUrl}/${link}`;
};
