import React from 'react'

function Footer() {
    return (
        <>
              {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                      <span>Copyright &copy; Mentgo 2021</span>
                  </div>
              </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </>
    )
}

export default Footer
