import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Get } from "../components/Axios/AxiosFunctions";
import { nodeImageUrl, NODE_URL } from "../components/Config/apiURL";
import Pagination from "../components/Pagination";
import { SpinnerRoundOutlined } from "spinners-react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getMentee } from "../store/actions/mentorActions";
import { toast } from "react-toastify";

function AllMentees(props) {
  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [approvedMentees, setApprovedMentees] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);

  
  const dispatch = useDispatch();
  let history = useHistory();

  const getAllApprovedMentee = async () => {
    const allApprovedMenteeApiURL = NODE_URL(
      `admin/get-every-mentee?limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(allApprovedMenteeApiURL, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      if (responseData?.data?.data?.length === 0) {
    setIsFetchingData(false);

        return toast.info('No more records')
      }
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setApprovedMentees(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAllApprovedMentee();
  }, [pageNumber]);

console.log({approvedMentees})

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid newMentorRequests">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">All Mentees</h1>
        {/* <p className="mb-4">All Mentees list</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {/* <h6 className="m-0 font-weight-bold text-primary">
              DataTables Example
            </h6> */}
          </div>
          <div className="card-body">
          {isFetchingData ?
              (
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={isFetchingData}
                    thickness={200}
                    color="#044d81"
                  />{" "}
                </div>
              ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Photo</th>
                    <th>Language</th>
                    <th>Country</th>
                    <th>Total Balance</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {approvedMentees?.map((item,index) => {
                    console.log("this is item ====>" ,item)
                    return (
                      <>
                        <tr
                          key={item?._id}
                        >
                          <td>{index+1}</td>
                          <td>{item?._id}</td>

                          <td>{item?.displayName}</td>
                          <td>{item?.UserId?.email}</td>

                          <td>
                            <img
                              style={{ width: "50px", height: "50px" , borderRadius: '50%'}}
                              src={`${nodeImageUrl}/${item?.photo}`}
                            />
                          </td>
                          <td>{item?.preferredLanguage}</td>
                          <td>{item?.country ? item?.country : "---" }</td>
                          {/* <td>{Math.ceil(item?.menteeBalance)}</td> */}
                          <td>{item?.menteeBalance.toFixed(2)}</td>
                          <td>
                          <Button
                            className={`btn btn-user btn-block ${ "btn-pending"}`}
                            onClick={() => {
                              history.push("/MenteeDetails");
                              dispatch(getMentee(item));
                              console.log(item);
                            }}
                          >
                            View Detail
                          </Button>
                        </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination
                  maxLastLength={maxLength}
                  array={approvedMentees}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default AllMentees;
