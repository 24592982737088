import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

const RadioButton = ({
  index,
  label,
  checked,
  state,
  setState,
  callApi,
  isApiCall,
}) => {
  return (
    <div className="col-md-3">
      <FormControlLabel
        onClick={() => {
          let copyState = [...state];
          copyState[index].check = !state[index]?.check;
          setState(copyState);
          callApi([],1,copyState);
        }}
        value={label}
        control={<Radio checked={checked} disabled={isApiCall} />}
        label={label}
      />
    </div>
  );
};
export default RadioButton;
