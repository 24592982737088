import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { nodeApiUrl } from "./Config/apiURL";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 10,
};

export default function CMSCardTableModal({
  open,
  setOpen,
  image,
  imageFile,
  setImage,
  setImageFile,
  icon,
  setIcon,
  iconFile,
  setIconFile,
  handleSelectImage,
  title,
  setTitle,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="col-md-12">
              <h2>Add Category</h2>
              {/* page maion title */}
              <div class="form-group col-md-12 col-xm-12">
                <label for="exampleInputEmail1">Cards title</label>
                <input
                  type="text"
                  class="form-control"
                  type="text"
                  placeholder="Add Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              {/* Image */}
              <div
                class="form-group form-group col-md-12 col-lg-12 col-sm-12"
                className="seo__imagePicker"
              >
                <input
                  type="file"
                  name="uploadfile"
                  id="img__table"
                  style={{
                    display: "none",
                  }}
                  onChange={(event) => {
                    handleSelectImage(event, setImageFile, setImage);
                  }}
                />
                <label for="exampleInputEmail1">Select Card Image</label>
                <label
                  for="img__table"
                  className="btn btn-primary btn-lg btn-block"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                  }}
                >
                  {image ? "Change selection" : "Pick an Image"}
                </label>
                {/* Shoe Image */}
                {image && (
                  <div>
                    <img
                      alt="not fount"
                      src={imageFile == null ? `${nodeApiUrl}/${image}` : image}
                      className="cms__TableIcon"
                    />
                  </div>
                )}
              </div>

              {/* Icon */}
              <div
                class="form-group form-group col-md-12 col-lg-12 col-sm-12 col-12"
                className="seo__imagePicker"
              >
                <input
                  type="file"
                  name="uploadfile"
                  id="icon__table"
                  style={{
                    display: "none",
                  }}
                  onChange={(event) => {
                    handleSelectImage(event, setIconFile, setIcon);
                  }}
                />
                <label for="exampleInputEmail1">Select Card Icon</label>
                <label
                  for="icon__table"
                  className="btn btn-primary btn-lg btn-block"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                  }}
                >
                  {icon ? "Change selection" : "Pick an Icon"}
                </label>
                {/* Shoe Image */}
                {icon && (
                  <div>
                    <img
                      alt="not fount"
                      src={iconFile == null ? `${nodeApiUrl}/${icon}` : icon}
                      className="cms__TableIcon"
                    />
                  </div>
                )}
              </div>
            </div>
            {/* button */}
            <div className="d-flex justify-content-center align-items-center">
              <button className="btn submit-btn mt-5">Submit</button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
