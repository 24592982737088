import React from "react";
import { Button } from "react-bootstrap";
import { useState } from "react/cjs/react.development";
import "../assets/css/cms.css";
import CMSCardTableModal from "./CMSCardTableModal";
import { nodeApiUrl } from "./Config/apiURL";

const CMSCardTable = ({
  title,
  setTitle,
  data,
  setData,
  image,
  setImage,
  imageFile,
  setImageFile,
  icon,
  setIcon,
  iconFile,
  setIconFile,
  handleSelectImage,
  handleDeleteItem,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="card shadow mb-4">
      <div className="col-md-12 cms-table mt-3">
        <div className="d-flex mb-3 justify-content-between">
          <h3 className="ml-3 mr-3">Categories</h3>
          <button className="btn pick-image" onClick={() => setOpenModal(true)}>
            {" "}
            + Add Category
          </button>
        </div>

        <table class="table ml-2">
          <thead class="thead-light">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Title</th>
              <th scope="col">Image</th>
              <th scope="col">Icon</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((e, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{e?.title}</td>
                <td>
                  <img src={e?.image} className="cms__tableImage" />
                </td>
                <td>
                  <img src={e?.icon} className="cms__tableImage" />
                </td>

                <td>
                  <button
                    className="btn decline-btn"
                    onClick={() => {
                      handleDeleteItem(e, i);
                    }}
                  >
                    {"Remove"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* CMSCardTableModal */}
        <CMSCardTableModal
          open={openModal}
          setOpen={setOpenModal}
          image={image}
          imageFile={imageFile}
          setImage={setImage}
          setImageFile={setImageFile}
          icon={icon}
          setIcon={setIcon}
          setIconFile={setIconFile}
          iconFile={iconFile}
          handleSelectImage={handleSelectImage}
          title={title}
          setTitle={setTitle}
        />
      </div>
    </div>
  );
};

export default CMSCardTable;
