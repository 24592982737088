import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import TipsAndPromotionsModal from "../components/TipsAndPromotionsModal";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { NODE_URL, baseUrl } from "../components/Config/apiURL";
import MediaCard from "../components/TPCard";
import { SpinnerRoundOutlined } from "spinners-react";
import { notice } from "../constant/imagepath";
import { ToastContainer, toast } from "react-toastify";

function AdvanceNotice(props) {
  const { setChoice } = props;
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [tips, setTips] = useState([]);
  const [language, setLanguage] = useState([]);
  const [img, setImg] = useState({});
  const [title, setTitle] = useState("");
  const [descriptionField, setDescriptionField] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hours, setHours] = useState(0);
  const [commission, setCommission] = useState(0);
  const [commissionId, setCommissionId] = useState(0);
  const [commissionLoading, setCommissionLoading] = useState(false);
  const [commissionUpdateLoading, setCommissionUpdateLoading] = useState("");

  const [advanceNoticeId, setAdvanceNoticeId] = useState(0);
  const [admin, setAdmin] = useState(true);
  const [mentor, setMentor] = useState(false);

  const [addModal, setAddModal] = useState(false);

  const [text, setText] = useState("");
  const [tableCommission, setTableCommission] = useState(0);
  const [mentorCommissionData, setMentorCommissionData] = useState([]);

  const [processingCent, setProcessingCent] = useState("");
  const [processingRate, setProcessingRate] = useState(0);
  const [processingLoading, setProcessingLoading] = useState(false);
  const [processingId, setProcessingId] = useState("");
  
  const [expressRate, setExpressRate] = useState(0);
  var [comB, setComB] = useState({});
  const [regularRate, setRegularRate] = useState(0);
  const [withdrawCommissionId, setWithdrawCommissionId] = useState("");
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const getAdvanceNotice = async () => {
    const advanceNoticeURL = NODE_URL(`general/app-details`);
    setIsFetchingData(true);
    const responseData = await Get(advanceNoticeURL, accessToken);
    // let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      setCommission(responseData?.data?.data?.commission?.commission?.rate);
      setCommissionId(responseData?.data?.data?.commission?._id);
      setProcessingRate(responseData?.data?.data?.processingFee?.processingFee?.rate);
      setProcessingCent(responseData?.data?.data?.processingFee?.processingFee?.cent);
      setExpressRate(responseData?.data?.data?.withdrawCommission?.withdrawCommission?.expressRate)
      setRegularRate(responseData?.data?.data?.withdrawCommission?.withdrawCommission?.regularRate)
      setWithdrawCommissionId(responseData?.data?.data?.withdrawCommission?._id);
      setProcessingId(responseData?.data?.data?.processingFee?._id);
      console.log(commissionId);
      console.log(commission);
      console.log(processingRate)
      console.log(processingCent)
      let priority =
        responseData?.data?.data?.advanceNotice?.advanceNotice?.priority;
      if (priority === "admin") {
        setAdmin(true);
        setMentor(false);
      } else {
        setMentor(true);
        setAdmin(false);
      }
      setHours(responseData?.data?.data?.advanceNotice?.advanceNotice?.hours);
      setAdvanceNoticeId(responseData?.data?.data?.advanceNotice?._id);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  const updatePriority = async () => {
    const updatePrioURL = NODE_URL(
      `general/change-advance-notice/${advanceNoticeId}`
    );
    let params = {
      hours: hours,
      priority: admin === true ? "admin" : "mentor",
    };
    setIsUpdating(true);
    const responseData = await Patch(updatePrioURL, params, authHeader);
    if (responseData !== undefined) {
      console.log(responseData)
      setHours(responseData?.data?.data?.advanceNotice?.hours);
    } else {
      console.log("error");
    }
    setIsUpdating(false);
  };

  // update Commission
  const updateCommission = async () => {
    const updatePrioURL = NODE_URL(
      `general/change-commissions/${commissionId}`
    );
    let params = {
      rate: commission,
    };
    setCommissionLoading(true);
    const responseData = await Patch(updatePrioURL, params, authHeader);
    if (responseData !== undefined) {
      setCommission(responseData?.data?.data?.commission?.rate);
      console.log(responseData)
    } else {
      console.log("error");
    }
    setCommissionLoading(false);
  };

  useEffect(() => {
    getAdvanceNotice();
  }, []);

  // ==== Search
  // const onSubmitSearch = evt => {
  //   evt.preventDefault();
  //   if (text === "") {
  //     alert("Please enter something!");
  //   } else {
  //     alert(text);
  //     setText("");
  //   }
  // };

  // search get api
  const onSubmitSearch = async (e) => {
    e.preventDefault();
    if (text === "") {
      alert("Please enter something!");
    } else {
      const searchURL = NODE_URL(
        `general/search-mentor-for-commissions?q=${text}`
      );
      setIsFetchingData(true);
      const responseData = await Get(searchURL, accessToken);
      // let fetchedData = [];
      if (responseData !== undefined) {
        console.log(responseData);
        setMentorCommissionData(responseData?.data?.data);
        // console.log(responseData?.data?.data);
        // setCommission(responseData?.data?.data?.commission?.commission?.rate)
        // setCommissionId(responseData?.data?.data?.commission?._id);
        // console.log(commissionId)
        // console.log(commission)
        // let priority =
        //   responseData?.data?.data?.advanceNotice?.advanceNotice?.priority;
        // if (priority === "admin") {
        //   setAdmin(true);
        //   setMentor(false);
        // } else {
        //   setMentor(true);
        //   setAdmin(false);
        // }

        // setHours(responseData?.data?.data?.advanceNotice?.advanceNotice?.hours);
        // setAdvanceNoticeId(responseData?.data?.data?.advanceNotice?._id);
      } else {
        console.log("error");
      }
      setIsFetchingData(false);
    }
  };

  // const onChangeSearch = evt => setText(evt.target.value);

  // update Mentor Commission
  const updateMentorCommission = async (e) => {
    const updateURL = NODE_URL(`general/change-mentor-commissions/${e}`);
    console.log(tableCommission);
    let params = {
      rate: tableCommission / 100,
      applayDefaultCommission: false,
      // priority : admin === true ? "admin" : "mentor",
    };
    setCommissionUpdateLoading(e);
    const responseData = await Patch(updateURL, params, authHeader);
    if (responseData !== undefined) {
    } else {
      console.log("error");
    }
    setCommissionUpdateLoading("");
  };

  useEffect(() => {
    console.log({ mentorCommissionData });
  }, [mentorCommissionData]);


    // update processing
    const updateProcessing = async () => {
      if(processingCent < 0  || processingCent >= 100){
        alert("please enter amount between 0 - 99 cent")
        // setProcessingCent(" ")
      }
      else{

        const updateProcessingUrl = NODE_URL(
          `general/change-processing-fee/${processingId}`
        );
        let params = {
          rate: processingRate,
          cent: processingCent,
        };
        setProcessingLoading(true);
        const responseData = await Patch(updateProcessingUrl, params, authHeader);
        if (responseData !== undefined) {
          console.log(responseData)
          setProcessingRate(responseData?.data?.data?.processingFee?.rate);
          setProcessingCent(responseData?.data?.data?.processingFee?.cent);
          console.log(responseData?.data?.data?.processingFee?.cent)
        } else {
          console.log("error");
        }

      }
      setProcessingLoading(false);
    };

      // update withdraw commission
      const updateWithdrawCommission = async () => {
        if(expressRate > 50  || regularRate > 50){
          alert("please enter withraw commission rate between 0 - 50 ")
        }
        else{
  
          const updateUrl = NODE_URL(
            `general/change-withdraw-commission/${withdrawCommissionId}`
          );
          let params = {
            expressRate: expressRate,
            regularRate: regularRate,
           
          };
          setWithdrawLoading(true);
          const responseData = await Patch(updateUrl, params, authHeader);
          if (responseData !== undefined) {
            toast.success("Success")
            console.log(responseData)
            setExpressRate(responseData?.data?.data?.withdrawCommission?.expressRate)
            setRegularRate(responseData?.data?.data?.withdrawCommission?.regularRate)
          } else {
            console.log("error");
           
          }
  
        }
       
        setWithdrawLoading(false);
      };
  




//       mentorCommissionData.map((item , i)=>{
//         console.log("this is render ===>",item.commission)
//         comB = item.commission
//       })
// console.log("this is comB ===>" + comB)








  return (
    <>
      <div className=" shadow mb-4 featureLang advanceNotice">
        <div className="card-header py-3 ">
          <div className="d-flex align-items-center justify-contnet-between">
            <div>
              <h6 className="m-0 font-weight-bold text-primary m-2">
                Advance Notice
              </h6>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={notice}
                alt="notice"
                className="image-fluid notice-img"
              />
            </div>
            <p className="commission-text"
              style={{  marginTop: "22px"}}
            >
             Hours
              </p>
            <div
              style={{
                marginBottom: "24px",
                marginTop: "12px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <input
                className="form-control form-control-advNotice"
                type="number"
                min="0"
                max="100"
                placeholder="Hours"
                value={hours}
                onChange={(e) => setHours(e.target.value)}
              />
            </div>
            <div style={{ float: "right" }}>
              <Button
                className="btn btn-user btn-block adv-update"
                disabled={hours > 0 ? false : true}
                onClick={() => updatePriority()}
              >
                {isUpdating ? "Updating" : "Update"}
              </Button>
            </div>
            {/* <h2 className="priority-title">Give priority To,</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "35px",
              }}
            >
              <div>
                <Button
                  style={{ marginRight: "20px" }}
                  className="btn btn-user btn-block prioritybtn"
                  disabled={admin ? true : false}
                  onClick={() => {
                    setAdmin(true);
                    setMentor(false);
                  }}
                >
                  Admin
                </Button>
              </div>
              <div>
                <Button
                  className="btn btn-user btn-block prioritybtn"
                  disabled={mentor ? true : false}
                  onClick={() => {
                    setAdmin(false);
                    setMentor(true);
                  }}
                >
                  Mentor
                </Button>
              </div>
            </div> */}
            {/* <div style={{ float: "right" }}>
              <Button
                className="btn btn-user btn-block adv-update"
                disabled={admin || mentor ? false : true}
                onClick={() => updatePriority()}
              >
                {isUpdating ? "Applying" : "Apply"}
              </Button>
            </div> */}


            <div className="commission-sec">
              <hr />
              <p className="commission-title">Processing Fee</p>
            
              <p className="commission-text">
               Rate 
              </p>
              <div
                style={{
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  className="form-control form-control-advNotice"
                  type="number"
                  min="0"
                  max="50"
                  placeholder="rate"
                  value={processingRate}
                  onChange={(e) => setProcessingRate(e.target.value)}
                />
              </div>
              <p className="commission-text">
               Cent 
              </p>
              <div
                style={{
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  className="form-control form-control-advNotice"
                  type="number"
                  min="0"
                  max="99"
                  placeholder="Cent"
                  pattern="^(\d+(\.\d{0,2})?|\.?\d{1,2})$"
                  step="0"
                  value={Math.ceil(processingCent)}
                  onChange={(e) => setProcessingCent(e.target.value)}
                />
              </div>
              <div style={{ float: "right" }}>
                <Button
                  className="btn btn-user btn-block adv-update"
                  disabled={processingCent > 0 ? false : true}
                  // disabled={hours > 0 ? false : true}
                  onClick={() => updateProcessing()}
                >
                  {processingLoading ? "Updating" : "Update"}
                </Button>
              </div>
            </div>


            <div className="commission-sec">
              <hr />
              <p className="commission-title">Commission</p>
              <p className="commission-text">
                Current Commission Rate : <span style={{fontWeight: 'bold'}}>{commission}</span>
              </p>
              <div
                style={{
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  className="form-control form-control-advNotice"
                  type="number"
                  min="0"
                  max="50"
                  placeholder="Commission"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                />
              </div>
              <div style={{ float: "right" }}>
                <Button
                  className="btn btn-user btn-block adv-update"
                  disabled={commission > 0 ? false : true}
                  // disabled={hours > 0 ? false : true}
                  onClick={() => updateCommission()}
                >
                  {commissionLoading ? "Updating" : "Update"}
                </Button>
              </div>
            </div>

                {/* Withdraw commission section */}
                <div className="commission-sec">
                  <hr />
                  <p className="commission-title">Withdraw Commission</p>      
                    <p className="commission-text">
                      Express Rate 
                    </p>
                    <div
                      style={{
                        marginBottom: "24px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        className="form-control form-control-advNotice"
                        type="number"
                        min="0"
                        max="50"
                        placeholder="Express Rate"
                        pattern="^(\d+(\.\d{0,2})?|\.?\d{1,2})$"
                        step="0.2"
                        value={expressRate}
                        onChange={(e) => setExpressRate(e.target.value)}
                      />
                    </div>
                      <p className="commission-text">
                        Regular Rate
                      </p>
                    <div
                      style={{
                        marginBottom: "24px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        className="form-control form-control-advNotice"
                        type="number"
                        min="0"
                        max="50"
                        placeholder=" Regular Rate"
                        pattern="^(\d+(\.\d{0,2})?|\.?\d{1,2})$"
                        step="0.2"
                        value={regularRate}
                        onChange={(e) => setRegularRate(e.target.value)}
                      />
                      </div>
                      <div style={{ float: "right" }}>
                        <Button
                          className="btn btn-user btn-block adv-update"
                          onClick={() => updateWithdrawCommission()}
                        >
                          {withdrawLoading ? "Updating.." : "Update"}
                        </Button>
                      </div>
                  </div>
          

            {/* Mentor Commission section*/}
            <div className="commission-sec">
              <hr />
              <p className="commission-title">Mentor Commission</p>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              {/* <p className="commission-text">Current Commission Rate : {commission}</p> */}

              <div style={{textAlign: 'center'}}>
                <form onSubmit={onSubmitSearch} className="p-5">
                  <input
                    type="text"
                    name="text"
                    placeholder="search users..."
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="bg-white p-2 w-3/4 outline-none advanceNotice-searchBox"
                  />
                  <button
                    type="submit"
                    className="p-2 text-center text-blue-500 w-1/4 bg-white border-l advanceNotice-searchBtn"
                  >
                    Search
                  </button>
                </form>
              </div>

              {mentorCommissionData.length > 0 ? (
                
                <>
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Commission</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {mentorCommissionData.map((item, index) => {
                        var b = item?.commission *100
                        {console.log("comision ====>" +item?.commission)}
                        {console.log("comision ====> bbb" +b  )}
                        
                        return (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td style={{textTransform: 'capitalize'}}>{item.displayName}</td>
                              <td>{item?.UserId?.email}</td>
                              <td>
                                <input
                                  className="form-control tableinput-advNotice"
                                  type="number"
                                  min="0"
                                  max="50"
                                  placeholder="Commission"
                                  // value={item?.commission == null ? "0" : item?.commission  }
                                  value={item?.commission}
                                  // value={parseFloat(item?.commission * 100).toFixed(2)}
                                  onChange={(e) => {
                                    let copyData = [...mentorCommissionData];
                                    // copyData[index].commission= Number(e.target.value)
                                    // setMentorCommissionData(copyData)

                                    setTableCommission(
                                      (copyData[index].commission = Number(
                                        e.target.value
                                      ))
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Button
                                  className="btn btn-user btn-block"
                                  onClick={() =>
                                    updateMentorCommission(item._id)
                                  }
                                  // onClick={() => {setEditProductsModal(true);
                                  //   setItem(item)
                                  //   setIndex(index)
                                  //   setEditDescriptionText(item.description)
                                  // }
                                  //   }
                                >
                                  {commissionUpdateLoading == item._id
                                    ? "Updating"
                                    : "Update"}
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <div className="notFound notFound-Container">
                    <i className="fas fa-exclamation-triangle"></i>
                  </div>
                  <p className="text-notFound">No Record Found</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvanceNotice;
