import React, { useEffect, useState } from "react";
import { loginVector, coverPhoto } from "../constant/imagepath";
import StarRatings from "react-star-ratings/build/star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { nodeImageUrl } from "../components/Config/apiURL";
import { NODE_URL, baseUrl } from "../components/Config/apiURL";
import moment from "moment";
import { Delete, Get, Patch } from "../components/Axios/AxiosFunctions";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import ConfirmModal from "../components/ConfirmModal";
import { getApprovedMentor } from "../store/actions/mentorActions";

import EditMenteeDetailsModal from "../components/EditMenteeDetailsModal";
import DeactivateMentor from "../components/DeactivateMentor";

function ApprovedMentorDetails() {
  const format1 = "YYYY-MM-DD";
  const history = useHistory();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const approvedMentor = useSelector(
    (state) => state.mentorReducer.approvedMentor
  );
  const [amount, setAmount] = useState(0);
  const [editMentorDetailModal, setEditMentorDetailModal] = useState(false);
  const [editDisplayName, setEditDisplayName] = useState("");
  const [editCoverPhoto, setEditCoverPhoto] = useState("");
  const [editPhoto, setEditPhoto] = useState("");
  const [editCountry, setEditCountry] = useState("");
  const [editLanguage, setEditLanguage] = useState("");
  const [editDob, setEditDob] = useState("");
  const [editMentorBalance, setEditMentorBalance] = useState("");
  const [editPhoneNumber, setEditPhoneNumber] = useState("");
  const [editIntroduction, setEditIntroduction] = useState("");
  const [item, setItem] = useState({});
  const [isDeletingReview, setIsDeletingReview] = useState(false);
  const [mentorReviews, setMentorReviews] = useState([]);
  const [showReviews, setShowReviews] = useState(false);
  const [index, setIndex] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [isFetchingMentorReviews, setIsFetchingMentorReviews] = useState(false);

  // Deactivate Modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoadingDeactivate, setIsLoadingDeactivate] = useState(false);

  const [mentorCommission, setMentorCommission] = useState(0);
  let keywords = JSON.parse(approvedMentor?.keywords);

  const loginHisatory = approvedMentor?.UserId?.loginHistory.filter(
    (e) => e?.logintype != "logout"
  );
  console.log("approved ment", loginHisatory);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const deleteMentor = async () => {
    setIsApiCall(true);
    const deleteURL = NODE_URL(
      `users/delete-account/${approvedMentor?.UserId?._id}`
    );
    const responseData = await Delete(deleteURL, authHeader);
    if (responseData !== undefined) {
      setDeleteConfirmModal(false);
      history.push("/ApprovedMentor");
      toast.success("Deleted Successfully!");
    } else {
      console.log("error delete api");
    }
    setIsApiCall(false);
  };

  // upload display Image
  const uploadDisplayImages = async (event) => {
    setEditPhoto(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  // upload cover Image
  const uploadCoverImages = async (event) => {
    setEditCoverPhoto(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const editMentor = async (item, index) => {
    console.log(item);
    setEditDisplayName(item?.displayName);
    // setEditCoverPhoto(item?.coverPhoto)
    // setEditPhoto(item?.photo)
    setEditCountry(item?.country);
    setEditLanguage(item?.preferredLanguage);
    setEditDob(item?.dob);
    setEditMentorBalance(item?.mentorBalance);
    setEditPhoneNumber(item?.phoneNumber);
    setEditIntroduction(item?.introduction);
    setModalLoading(true);

    const editUrl = NODE_URL(`mentor/updateMenteesOrMentors`);
    let obj = {
      UserId: approvedMentor?.UserId?._id,
      mode: "mentor",
      displayName: editDisplayName,
      //   coverPhoto: editCoverPhoto,
      //   photo: editPhoto,
      country: editCountry,
      commission: Number(mentorCommission),
      preferredLanguage: editLanguage,
      dob: editDob,
      mentorBalance: editMentorBalance,
      phoneNumber: editPhoneNumber,
      introduction: editIntroduction,
    };

    // let returnFormData = await createFormdata(obj);
    // console.log({ returnFormData });
    // const responseData = await Patch(editUrl, returnFormData, imgAuthHeader);
    const responseData = await Patch(editUrl, obj, authHeader);
    if (responseData !== undefined) {
      // menteeData(responseData?.data?.data)
      // dispatch(getApprovedMentor(responseData?.data?.data));
      toast.success("Mentor Updated Succesfully!");
      //   let oldData = addVoucher.slice();
      //   oldData.push(responseData?.data?.data)
      //   console.log({oldData})
      //   setAddVoucher(oldData)

      setEditMentorDetailModal(false);
    }
    setModalLoading(false);
  };

  const giveGoodwill = async () => {
    console.log(amount);
    if (amount <= 0) {
      toast.success("Please enter valid amount!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      let params = {
        userMode: "mentor",
        amount: amount,
      };
      const goodwilURL = NODE_URL(
        `admin/goodwill/individual/${approvedMentor?._id}`
      );
      setIsApiCall(true);
      const resData = await Patch(goodwilURL, params, authHeader);

      if (resData !== undefined) {
        toast.success(`🙌 Goodwill given to ${approvedMentor?.displayName}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setAmount(0);
      } else {
        console.log("error");
      }
      setIsApiCall(false);
    }
  };

  const reviewsURL = NODE_URL(`reviews/all/mentor/${approvedMentor?._id}`);

  const getMentorReviews = async () => {
    let fetched_data = [];
    setIsFetchingMentorReviews(true);
    const reviewsApi = await Get(reviewsURL, "accessToken", false);
    setIsFetchingMentorReviews(false);
    if (reviewsApi !== undefined) {
      if (reviewsApi?.data?.data.length > 0) {
        fetched_data = [...reviewsApi?.data?.data];
      }
      setMentorReviews(fetched_data);
      setShowReviews(true);
    } else {
      console.log("Error! Couldn't fetch mentor reviews.");
      setIsFetchingMentorReviews(false);
    }
  };

  const deleteThisReview = async (item, index) => {
    console.log({ item });
    const deleteURL = NODE_URL(`reviews/${item?.id}`);

    setIsDeletingReview(true);
    const resData = await Delete(deleteURL, null, authHeader);

    if (resData !== undefined) {
      let copyData = [...mentorReviews];
      copyData?.splice(index, 1);
      setMentorReviews(copyData);
      console.log({ copyData });
      toast.success("Review Deleted Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      console.log("error");
    }
    setIsDeletingReview(false);
  };

  useEffect(() => {
    console.log({ mentorReviews });
  }, [mentorReviews]);
  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid ApprovedMentorDetails">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Approved Mentor Details</h1>

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="delete-Btn ml-3">
                    <Button
                      style={{
                        width: "160px",
                      }}
                      className={`btn btn-user btn-block ${"btn-pending"}`}
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Deactivate Account
                    </Button>
                  </div>

                  <div className="">
                    <div className="edit-Btn">
                      <Button
                        className={`btn btn-user btn-block ${"btn-pending"}`}
                        onClick={() => {
                          setEditMentorDetailModal(true);
                          setItem(item);
                          setIndex(index);
                          setEditDisplayName(approvedMentor.displayName);
                          //   setEditCoverPhoto(menteeData.coverPhoto);
                          //   setEditPhoto(menteeData.photo);
                          setEditCountry(approvedMentor.country);
                          setEditLanguage(approvedMentor.preferredLanguage);
                          setEditDob(approvedMentor.dob);
                          setEditMentorBalance(approvedMentor.mentorBalance);
                          setEditPhoneNumber(approvedMentor.phoneNumber);
                          setEditIntroduction(approvedMentor.aboutMe);
                          setMentorCommission(approvedMentor?.commission);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>

                  <div className="delete-Btn mr-3">
                    <Button
                      className={`btn btn-user btn-block ${"btn-pending"}`}
                      onClick={() => {
                        setDeleteConfirmModal(true);
                      }}
                    >
                      Delete Account
                    </Button>
                  </div>
                </div>
                <div className="cover-photo">
                  <img
                    src={`${nodeImageUrl}/${approvedMentor?.coverPhoto}`}
                    alt="cover"
                    className="image-fluid"
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="profile">
                  <img
                    src={`${nodeImageUrl}/${approvedMentor?.photo}`}
                    alt=""
                    className="img-fluid shadow"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="ml-2">
                  <div>
                    <h2>{approvedMentor?.displayName}</h2>
                    <p className="location-lang">
                      {" "}
                      {approvedMentor?.city} , {approvedMentor?.country}
                    </p>
                    <p className="location-lang">
                      {" "}
                      Language: {approvedMentor?.preferredLanguage}
                    </p>
                  </div>
                  <div className="rating">
                    <div className="star">
                      <StarRatings
                        rating={approvedMentor?.ratingsAverage}
                        starDimension="20px"
                        starRatedColor="orange"
                        starSpacing="0px"
                      />
                    </div>
                    <div>
                      <p className="rating-number">
                        {approvedMentor?.ratingsAverage}
                      </p>
                    </div>
                  </div>
                  {/* <div className="lesson-mentees">
                                        <p className="menteeRated-text">16 Lessions</p>
                                        <p className="menteeRated-text">8 Mentees</p>
                                    </div> */}

                  <div className="mt-3">
                    {/* <p className="bio-text">Email: {approvedMentor?.email}</p> */}
                    <p className="bio-text text-capitalize">
                      Gender: {approvedMentor?.gender}
                    </p>
                    <p className="bio-text">
                      DOB: {moment(approvedMentor?.dob).format(format1)}
                    </p>
                    <p className="aboutMe-text">About Me</p>
                    <p className="aboutMe-para">{approvedMentor?.aboutMe}</p>
                    {/* <p className="Mentor-kind">
                                            Kind of Mentor :
                                            {JSON.parse(approvedMentor?.mentorKinds).map((e, i) => {
                                                return (
                                                    <span key={i} className="kind-text">
                                                    {e}
                                                    </span>
                                                );
                                                })}
                                        </p> */}

                    <p className="keyword">Keywords</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {keywords.map((e, i) => {
                        return (
                          <div key={i} className="key-box">
                            <span>{e?.label}</span>
                          </div>
                        );
                      })}
                    </div>
                    {/* Reviews start  */}
                    {!showReviews ? (
                      <div className="text-center">
                        <Button
                          disabled={isFetchingMentorReviews}
                          className={`btn ${"btn-pending"}`}
                          onClick={() => {
                            getMentorReviews();
                          }}
                        >
                          {isFetchingMentorReviews
                            ? "Please Wait"
                            : "Show Mentor Reviews"}
                        </Button>
                      </div>
                    ) : (
                      <>
                        <h3 className="mt-3">Reviews</h3>
                        <table class="table table-striped mt-3 mb-5 table-responsive">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Review Given By</th>
                              <th scope="col">Review Message</th>
                              <th scope="col">Rating</th>
                              <th scope="col">Location</th>
                              <th scope="col">Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mentorReviews?.map((e, i) => {
                              console.log({ e });
                              return (
                                <tr>
                                  <th scope="row">{i + 1}</th>
                                  <td>{e?.mentee?.displayName}</td>
                                  <td>
                                    {e?.reviewMessage?.length > 30
                                      ? e?.reviewMessage?.substring(0, 30)
                                      : e?.reviewMessage}
                                  </td>
                                  <td>{e?.rating}</td>
                                  <td>{e?.mentee?.location}</td>

                                  <td>
                                    {moment(e?.createdAt).format(
                                      "DD-MMM-YYYY, hh:mm a"
                                    )}
                                  </td>
                                  <td>
                                    <div className="delete-Btn mr-3">
                                      <Button
                                        className={`btn btn-user btn-block ${"btn-pending"}`}
                                        disabled={isDeletingReview}
                                        onClick={() => {
                                          deleteThisReview(e, i);
                                        }}
                                      >
                                        Delete Review
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>{" "}
                      </>
                    )}

                    {/* Reviews End  */}

                    {/* loginhistory start */}
                    <table class="table table-striped mt-5 mb-5">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">City</th>
                          <th scope="col">Country</th>
                          <th scope="col">Region</th>
                          <th scope="col">Timezone</th>
                          <th scope="col">IP</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loginHisatory?.map((e, i) => (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{e?.city}</td>
                            <td>{e?.country}</td>
                            <td>{e?.region}</td>
                            <td>{e?.timezone}</td>
                            <td>{e?.ip}</td>
                            <td>
                              {moment(e?.date).format(
                                "ddd, DD MMM YYYY, hh:mm A"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* login history Ends */}
                    <div className="col-md-12 d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
                      <h2 className="mb-3">Enter Giveaway Amount:</h2>
                      <input
                        type="number"
                        className="form-control"
                        s
                        style={{ width: "250px" }}
                        placeholder="Enter amount"
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />

                      <button
                        type="button"
                        disabled={isApiCall}
                        className={`mt-4 btn giveaway-applied `}
                        onClick={() => {
                          giveGoodwill();
                        }}
                      >
                        {isApiCall ? "Please Wait" : "Send Goodwill"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}

      {/* Deactivate */}
      <DeactivateMentor
        isModalVisible={isDeleteModalOpen}
        setIsModalVisible={setIsDeleteModalOpen}
        isApiCall={isLoadingDeactivate}
        onClickYes={async (params) => {
          const url = NODE_URL(`admin/disable-mentor/${approvedMentor?.UserId?._id}`);
          setIsLoadingDeactivate(true);
          const response = await Patch(url, params, authHeader);
          setIsLoadingDeactivate(false);

          if (response !== undefined) {
            toast.success("Accout Deactivated successfully.");
            history.goBack();
          }
        }}
      />

      <ConfirmModal
        isModalVisible={deleteConfirmModal}
        setIsModalVisible={setDeleteConfirmModal}
        onClickYes={() => {
          deleteMentor();
        }}
        onClickNo={() => {
          setDeleteConfirmModal(false);
        }}
        isApiCall={isApiCall}
        confirmTextLine={"Are you sure you want to delete this mentee account?"}
      />

      {/* same modal using for mentor as well */}
      <EditMenteeDetailsModal
        type="mentor"
        editMenteeDetailModal={editMentorDetailModal}
        setEditMenteeDetailModal={setEditMentorDetailModal}
        editDisplayName={editDisplayName}
        setEditDisplayName={setEditDisplayName}
        editCoverPhoto={editCoverPhoto}
        setEditCoverPhoto={setEditCoverPhoto}
        editPhoto={editPhoto}
        setEditPhoto={setEditPhoto}
        editCountry={editCountry}
        setEditCountry={setEditCountry}
        editLanguage={editLanguage}
        setEditLanguage={setEditLanguage}
        editDob={editDob}
        setEditDob={setEditDob}
        editMenteeBalance={editMentorBalance}
        setEditMenteeBalance={setEditMentorBalance}
        editPhoneNumber={editPhoneNumber}
        setEditPhoneNumber={setEditPhoneNumber}
        editIntroduction={editIntroduction}
        setEditIntroduction={setEditIntroduction}
        uploadCoverImages={uploadCoverImages}
        uploadDisplayImages={uploadDisplayImages}
        item={item}
        index={index}
        editMentee={editMentor}
        modalLoading={modalLoading}
        setMentorCommission={setMentorCommission}
        mentorCommission={mentorCommission}
      />
    </>
  );
}

export default ApprovedMentorDetails;
