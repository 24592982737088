import React, { useState, useEffect } from "react";
import Pagination from "../components/Pagination";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Post, Patch } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";
import { getDisputeDetails, getNewMentors } from "../store/actions/mentorActions";
import { SpinnerRoundOutlined } from "spinners-react";
import moment from "moment";
import { useHistory } from "react-router";

function DisputedSessions(props) {
  const { setChoice } = props;
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [disputedSessions, setDisputedSessions] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.globalReducer.accessToken);

  const getAllDisputedSessions = async () => {
    // const collector = NODE_URL(
    //   `booking/all/session-problems?limit=${limit}&page=${pageNumber}`
    // );

    const collector = NODE_URL(
      `general/dispute-data?limit=${limit}&page=${pageNumber}`
    );
    setIsFetchingData(true);
    const responseData = await Get(collector, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setDisputedSessions(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAllDisputedSessions();
  }, [pageNumber]);

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const accept = async (bookingId, inFavour) => {
    console.log(bookingId);
    let args = {
      bookingId,
      inFavour,
    };
    const collector = NODE_URL(`booking/resolve-dispute`);
    const responseData = await Patch(collector, args, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
    } else {
      console.log("error");
    }
  };

  return (
    <>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid disputedSession">
        {/* <!-- Page Heading --> */}
        <h1 className="h3 mb-2 text-gray-800">Disputed Sessions</h1>
        {/* <p className="mb-4">Disputed Session list</p> */}

        {/* <!-- DataTales Example --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3"></div>
          <div className="card-body">
            {isFetchingData ? (
              <div className="col-md-12 darken-newMechanic on-loading">
                <SpinnerRoundOutlined
                  enabled={isFetchingData}
                  thickness={200}
                  color="#044d81"
                />{" "}
              </div>
            ) : disputedSessions?.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      {/* <th>Session Id</th> */}
                      <th>Mentor Name</th>
                      <th>Mentee Name</th>
                      <th>Date</th>
                      <th>Price</th>
                      <th>Dispute Created by</th>
                      {/* <th>In Favour of</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {disputedSessions.map((item) => {
                      let disputeCreatedBy =
                        item?.timeLine?.timeLineArray[
                          item?.timeLine?.timeLineArray?.length - 2
                        ]?.message?.split(" ")[0];
                      let name =
                        disputeCreatedBy?.toLowerCase() === "mentor"
                          ? item?.mentor?.displayName
                          : item?.mentee?.displayName;
                      return (
                        <>
                          <tr key={item._id}>
                            {/* <td>{item?._id}</td> */}
                            <td>{item?.mentor?.displayName}</td>
                            <td>{item?.mentee?.displayName}</td>
                            <td>{moment(item?.sessionStartTime).format('MMM, DD YYYY hh:mm A')}</td>
                            <td>{`$${item?.lessonAmount?.toFixed(2)}`}</td>
                            <td>{`${disputeCreatedBy} (${name})`}</td>
                            {/* <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ marginRight: "8px" }}>
                                <Button
                                  className={`btn btn-user btn-block btn-Fav-MenteeMentor`}
                                  onClick={() => {
                                    accept(item._id, "mentee");
                                  }}
                                >
                                  Mentee
                                </Button>
                              </div>
                              <div style={{ marginLeft: "8px" }}>
                                <Button
                                  className={`btn btn-user btn-block btn-Fav-MenteeMentor`}
                                  onClick={() => {
                                    accept(item._id, "mentor");
                                  }}
                                >
                                  Mentor
                                </Button>
                              </div>
                            </td> */}
                            <td>
                              <Button
                                className={`btn btn-user btn-block btn-ViewDetails`}
                                onClick={() => {
                                  dispatch(getDisputeDetails(item));
                                  history.push("/DisputedSessionDetails");
                                }}
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                  // maxLastLength={maxLength}
                  // array={approvedMentors}
                  // setPageNumber={setPageNumber}
                  // pageNumber={pageNumber}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-12 m-5 d-flex justify-content-center align-items-center">
                <h2>No Disputed Sessions Requests</h2>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- /.container-fluid --> */}
    </>
  );
}

export default DisputedSessions;
