import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../components/Axios/AxiosFunctions";
import { NODE_URL } from "../components/Config/apiURL";
import { Button } from "react-bootstrap";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { toast } from "react-toastify";

const SearchBySessionId = () => {
  const [sessionId, setSessionId] = useState("61781f56584fb20016b719a4");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const token = useSelector((state) => state.globalReducer.accessToken);

  const getData = async () => {
    if (sessionId === '') {
      return toast.error('Please input a valid session Id')
    }
    const url = NODE_URL(`admin/lookupBySessionId?sessionId=${sessionId}`);
    setIsLoading(true);
    const response = await Get(url, token);
    setIsLoading(false);
    if (response !== undefined) {
      setFetchedData(response?.data?.data);
    }else{
      toast.error('Please input a valid session Id');
      setSessionId('');
      setFetchedData(null)
    }
  };

  return (
    <div className="container">
      {/* Search */}
      <form className="form-inline">
        <div className="form-group mb-2">
          <input
            type="text"
            className="form-control mr-5"
            placeholder="Enter session ID"
            value={sessionId}
            onChange={(e) => setSessionId(e.target.value)}
          />
        </div>
        <Button
          type="submit"
          className="btn btn-primary mb-2"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            getData();
          }}
        >
          {isLoading ? "Please wait" : "Search"}
        </Button>
      </form>

      {fetchedData == null && (
        <div className="col-md-4 mx-auto mt-5">
          <h5>Please Search By a valid session Id</h5>
        </div>
      )}

      {/* Mentee and Mentor info */}
      {fetchedData != null && (
        <div className="d-flex">
          {/* Mentor */}
          <div className="card" style={{ width: "35rem", marginRight: "20px" }}>
            <div className="card-body">
              <h5 className="card-title">
                Mentor: {fetchedData?.mentor?.displayName}
              </h5>
              <h6 className="card-subtitle mb-2 text-muted">
                From: {fetchedData?.mentor?.city},{" "}
                {fetchedData?.mentor?.country}
              </h6>
              <p className="card-text">About: {fetchedData?.mentor?.aboutMe}</p>
              <p>
                DoB: {moment(fetchedData?.mentor?.dob).format("DD-MM-YYYY")}
              </p>
              <p
                style={{
                  marginTop: "-15px",
                }}
              >
                Gender: {fetchedData?.mentor?.gender}
              </p>
              <p
                style={{
                  marginTop: "-15px",
                }}
              >
                Timezone:{" "}
                {momentTimezone().tz(fetchedData?.mentor?.location).format("Z")}
              </p>
            </div>
          </div>

          {/* Mentee */}
          <div className="card" style={{ width: "35rem" }}>
            <div className="card-body">
              <h5 className="card-title">
                Mentee: {fetchedData?.mentee?.displayName}
              </h5>
              <h6 className="card-subtitle mb-2 text-muted">
                From: {fetchedData?.mentee?.city},{" "}
                {fetchedData?.mentee?.country}
              </h6>
              <p className="card-text">
                About: {fetchedData?.mentee?.introduction}
              </p>
              <p>
                DoB: {moment(fetchedData?.mentee?.dob).format("DD-MM-YYYY")}
              </p>
              <p
                style={{
                  marginTop: "-15px",
                }}
              >
                Gender: {fetchedData?.mentee?.gender}
              </p>
              <p
                style={{
                  marginTop: "-15px",
                }}
              >
                Timezone:{" "}
                {momentTimezone().tz(fetchedData?.mentee?.location).format("Z")}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Booking Info */}
      {fetchedData !== null && (
        <div className="card" style={{ width: "100%" }} className="mt-5">
          <div className="card-header">Session Detail</div>
          <ul className="list-group list-group-flush">
            {/* mentor schedule */}
            <li className="list-group-item">
              Schedule Date (wrt mentor):{" "}
              {moment
                .utc(fetchedData?.sheduleDate)
                .format(
                  `ddd, DD MMM YYYY hh:mm A (UTC ${momentTimezone()
                    .tz(fetchedData?.mentor?.location)
                    .format("Z")})`
                )}
            </li>
            {/* mentee schedule */}
            <li className="list-group-item">
              Schedule Date (wrt mentee):{" "}
              {moment
                .utc(fetchedData?.sheduleDate)
                .format(
                  `ddd, DD MMM YYYY hh:mm A (UTC ${momentTimezone()
                    .tz(fetchedData?.mentee?.location)
                    .format("Z")})`
                )}
            </li>
            <li className="list-group-item">
              Session type: {fetchedData?.lesson?.lessonName}
            </li>
            <li className="list-group-item">
              Session Duration: {fetchedData?.lessonDuration}
            </li>
            <li className="list-group-item">
              Session Price: {parseFloat(fetchedData?.lessonAmount).toFixed(2)}
            </li>

            <li className="list-group-item">
              Communication Platform: {fetchedData?.communicationPlatform}
            </li>
            <li className="list-group-item">
              Mentor Communication Platform Link:{" "}
              {fetchedData?.mentorCommunicationPlatformLink}
            </li>
            <li className="list-group-item">
              Mentee Communication Platform Link:{" "}
              {fetchedData?.communicationPlatformLink}
            </li>
          </ul>
        </div>
      )}

      {fetchedData !== null && (
        <div className="card" style={{ width: "100%" }} className="mt-5 mb-5">
          <div className="card-header">Session records</div>
          <ul className="list-group list-group-flush">
            {fetchedData?.timeLine?.timeLineArray.map((e, i) => (
              <div className="list-group-item d-flex justify-content-between">
                <li key={i}>{e?.message} </li>
                <small>
                  {moment(e?.createdAt).format("DD-MM-YYYY hh:mm A")}
                </small>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBySessionId;
