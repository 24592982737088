import { useState } from "react/cjs/react.development";
import logo from "./logo.svg";
import "./App.css";
import "../src/assets/css/styles.css";
import "../src/assets/css/stylesCustom.css";
import "react-quill/dist/quill.snow.css";
import Dashboard from "./screens/Dashboard";
import { Fragment } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";

import NewMentorRequests from "./screens/NewMentorRequests";
import NewMentorRequestDetails from "./screens/NewMentorRequestDetails";
import AllMentees from "./screens/AllMentees";
import MenteeDetails from "./screens/MenteeDetails";
import ApprovedMentor from "./screens/ApprovedMentor";
import ApprovedMentorDetails from "./screens/ApprovedMentorDetails";
import PaymentRequest from "./screens/PaymentRequest";
import PaymentRequestDetails from "./screens/PaymentRequestDetails";
import MentorChanges from "./screens/MentorChanges";
import MentorChangesDetails from "./screens/MentorChangesDetails";
import DisputedSessions from "./screens/DisputedSessions";
import DisputedSessionDetails from "./screens/DisputedSessionDetails";
import FeatureAddLanguages from "./screens/FeatureAddLanguages";
import TipsAndPromotions from "./screens/TipsAndPromotions";
import AdvanceNotice from "./screens/AdvanceNotice";
import Report from "./screens/Report";
import ReportDetails from "./screens/ReportDetails";
import DeActivatedAccount from "./screens/DeActivatedAccount";
import DeActivatedAccountDetails from "./screens/DeActivatedAccountDetails";

import Footer from "./components/Footer";
import SideNavbar from "./components/SideNavbar";
import Header from "./components/Header";
import Login from "./components/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AdminGoodwill from "./screens/AdminGoodwill";
import SessionProblems from "./screens/SessionProblems";
import BookingStatistics from "./screens/BookingStatistics";
import Stats_CSV from "./screens/Stats_CSV";
import DefaultPrices from "./screens/DefaultPrices";
import BlockedList from "./screens/BlockedList";
import CMS from "./screens/CMS";
import HomeScreen from "./screens/HomeScreen";
import BecomeAMentor from "./screens/BecomeAMentor";
import Services from "./screens/Services";
import Testimonial from "./screens/Testimonial";
import HowItsWork from "./screens/HowItsWork";
import Category from "./screens/Category";
import SearchBySessionId from "./screens/SearchBySessionId";
import community from "./screens/community";
import ChangePassword from "./screens/changePassword";
import UnresolvedSessions from "./screens/UnresolvedSessions";
import UnresolvedSessionsDetails from "./screens/UnresolvedSessionsDetails";
import AdminMain from "./screens/adminMain";
import Test from "./screens/test";

function App() {
  const isLogin = useSelector((state) => state.globalReducer.isLogin);
  return (
    // <>{userLogin ? <Dashboard /> : <Login setUserLogin={setUserLogin} />}</>
    // isLogin ? <Dashboard /> : <Login />
    // <Router>
    //   <Route path="/dashboard" component={Dashboard} />
    //   <Route path="/" component={Login} />
    // </Router>

    <>
      <ToastContainer />
      <Router>
        <Fragment>
          {isLogin ? (
            <>
              <div id="wrapper">
                <SideNavbar />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <Header />

                    <Route path="/" exact component={NewMentorRequests} />
                    <Route path="/cms" exact component={CMS} />
                    <Route
                      path="/NewMentorRequests"
                      component={NewMentorRequests}
                    />
                    <Route
                      path="/NewMentorRequestDetails"
                      component={NewMentorRequestDetails}
                    />

                    <Route path="/PaymentRequest" component={PaymentRequest} />
                    <Route
                      path="/PaymentRequestDetails"
                      component={PaymentRequestDetails}
                    />
                    <Route path="/MentorChanges" component={MentorChanges} />
                    <Route
                      path="/MentorChangesDetails"
                      component={MentorChangesDetails}
                    />

                    <Route path="/AllMentees" component={AllMentees} />
                    <Route path="/MenteeDetails" component={MenteeDetails} />

                    <Route path="/ApprovedMentor" component={ApprovedMentor} />
                    <Route
                      path="/ApprovedMentorDetails"
                      component={ApprovedMentorDetails}
                    />

                    <Route
                      path="/DisputedSessions"
                      component={DisputedSessions}
                    />
                    <Route
                      path="/DisputedSessionDetails"
                      component={DisputedSessionDetails}
                    />

                    <Route
                      path="/UnresolvedSessions"
                      component={UnresolvedSessions}
                    />

                    <Route
                      path="/UnresolvedSessionsDetails"
                      component={UnresolvedSessionsDetails}
                    />

                    <Route
                      path="/FeatureAddLanguages"
                      component={FeatureAddLanguages}
                    />
                    <Route
                      path="/TipsAndPromotions"
                      component={TipsAndPromotions}
                    />

                    <Route
                      path="/sessionsearch"
                      component={SearchBySessionId}
                    />

                    <Route path="/AdvanceNotice" component={AdvanceNotice} />
                    <Route path="/Report" component={Report} />
                    <Route path="/ReportDetails" component={ReportDetails} />
                    <Route
                      path="/DeActivatedAccount"
                      component={DeActivatedAccount}
                    />
                    <Route
                      path="/DeActivatedAccountDetails"
                      component={DeActivatedAccountDetails}
                    />
                    <Route path="/AdminGoodwill" component={AdminGoodwill} />
                    <Route
                      path="/SessionProblems"
                      component={SessionProblems}
                    />
                    <Route
                      path="/BookingStatistics"
                      component={BookingStatistics}
                    />
                    <Route path="/Stats_CSV" component={Stats_CSV} />
                    <Route path="/community" component={community} />
                    <Route path="/DefaultPrices" component={DefaultPrices} />
                    <Route path="/BlockedList" component={BlockedList} />
                    <Route path="/HomeScreen" component={HomeScreen} />
                    <Route path="/BecomeAMentor" component={BecomeAMentor} />
                    <Route path="/Services" component={Services} />
                    <Route path="/Testimonial" component={Testimonial} />
                    <Route path="/HowItsWork" component={HowItsWork} />
                    <Route path="/Category" component={Category} />
                    <Route path="/test" component={Test} />
                    <Route path="/changePassword" component={ChangePassword} />
                    {/* <Route path="/AdminMain" component={AdminMain} /> */}
                  </div>
                  <Footer />
                </div>
              </div>
            </>
          ) : (
            <Login />
          )}
        </Fragment>
      </Router>
    </>
  );
}

export default App;
