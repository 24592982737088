import moment from "moment";
import React from "react";

const Test = () => {
  const data = [
    {
      id: 1,
      email: "email",
      createdAt: new Date(),
    },
  ];
  return (
    <div className="container">
      <h1>Community Requests</h1>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Email</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((e, i) => (
            <tr>
              <th scope="row">{++i}</th>
              <td>{e?.email}</td>
              <td>{moment(e?.createdAt).format("ddd, DD MMM YYYY hh:mm A")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Test;
