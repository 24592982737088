import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AddLanguageModal from "../components/AddLanguageModal";
import { Get, Post, Patch, Delete } from "../components/Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { NODE_URL } from "../components/Config/apiURL";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import Pagination from "../components/Pagination";

function FeatureAddLanguages(props) {
  const { setChoice } = props;
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState([]);

  const [addLanguageModal, setAddLanguageModal] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [loading, setLoading] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  

  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };

  const getAllLanguages = async () => {
    const collector = NODE_URL(
      `general/languages?limit=${limit}&page=${pageNumber}&admin=true`
    );
    setIsFetchingData(true);
    const responseData = await Get(collector, accessToken);
    let fetchedData = [];
    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      fetchedData = [...responseData?.data?.data];
      setLanguages(fetchedData);
    } else {
      console.log("error");
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    console.log({ pageNumber });
    getAllLanguages();
  }, [pageNumber]);

  const createLanguages = async () => {
    console.log(language);
    let obj = {
      language,
    };
    const collector = NODE_URL(`general/add/language`);
    setModalLoading(true)
    const responseData = await Post(collector, obj, authHeader);
    if (responseData !== undefined) {
      setAddLanguageModal(false)
      toast.success("Succcessfully added")
      console.log(responseData?.data?.data);
      let arr = languages.slice();
      arr.push(responseData?.data?.data);
      console.log(arr);
      setLanguages(arr);
    } else {
      console.log("error");
    }
    setModalLoading(false)
  };

  const deleteRecord = async (id, index) => {
    const collector = NODE_URL(`general/delete/language/${id}`);
    setLoading(id);
    const responseData = await Delete(collector, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      toast.success("Successfully Delete Record")
      let arr = languages.slice();
      arr.splice(index, 1);
      setLanguages(arr);
    } else {
      console.log("error");
    }
    setLoading("")
  };
  const activeInactiveLanguages = async (id, status, index) => {
    console.log(language);
    let obj = {
      isActive: !status,
    };
    const collector = NODE_URL(`general/update/language/${id}`);
    const responseData = await Patch(collector, obj, authHeader);

    if (responseData !== undefined) {
      console.log(responseData?.data?.data);
      let arr = languages.slice();
      arr.splice(index, 1, responseData?.data?.data);
      setLanguages(arr);
    } else {
      console.log("error");
      toast.error("Error")
    }
  };

  return (
    <>
      {/* <!-- DataTales Example --> */}
      <div className="card shadow mb-4 featureLang">
        <div className="card-header py-3 ">
          <div className="d-flex align-items-center justify-contnet-between">
            <div>
              <h6 className="m-0 font-weight-bold text-primary m-2">
                Add Languages
              </h6>
            </div>
            <div className="addBtn text-center">
              <i
                className="fas fa-plus-circle"
                onClick={() => setAddLanguageModal(true)}
              ></i>
            </div>
          </div>
        </div>
        <div className="card-body">
            {isFetchingData ?
              (
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={isFetchingData}
                    thickness={200}
                    color="#044d81"
                  />{" "}
                </div>
              ) : (
          <div className="table-responsive">
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Available Languages</th>
                  <th>Status</th>
                  <th></th>
                  <th></th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {languages.map((item, i) => {
                  return (
                    <>
                      <tr key={item._id}>
                        <td>{item.language}</td>
                        <td>{item.isActive ? "Active" : "Inactive"}</td>
                        <td className="text-center">
                          <Button
                            className={`btn btn-user btn-block ${
                              item.isActive ? "inactiveBtn" : "activeBtn"
                            }`}
                            onClick={() =>
                              activeInactiveLanguages(
                                item._id,
                                item.isActive,
                                i
                              )
                            }
                          >
                            {item.isActive ? "Inactive" : "Active"}
                          </Button>
                        </td>
                        <td className="text-center">
                          <Button
                            className={`btn btn-user btn-block activeBtn }`}
                            onClick={() => deleteRecord(item._id, i)}
                            disabled={loading}
                          >
                            {loading === item._id ? "Loading" : "Delete" }
                          </Button>
                        </td>
                        {/* <div className="addBtn text-center">
                          <i
                            className="fas fa-plus-circle"
                            onClick={() => setAddLanguageModal(true)}
                          ></i>
                        </div> */}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination">
              <Pagination
                maxLastLength={maxLength}
                array={languages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
              />
            </div>
          </div>
          )}
        </div>
      </div>

      <AddLanguageModal
        language={language}
        setLanguage={setLanguage}
        setAddLanguageModal={setAddLanguageModal}
        addLanguageModal={addLanguageModal}
        createLanguages={createLanguages}
        modalLoading={modalLoading}
      />
    </>
  );
}

export default FeatureAddLanguages;
