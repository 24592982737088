import React,{useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { NODE_URL } from "../components/Config/apiURL";
import { Patch } from "../components/Axios/AxiosFunctions";
import { islogin } from "../store/actions/globalActions";
import cookie from "js-cookie";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.globalReducer.accessToken);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  //  submit
  const submitHandler = async () => {
    const url = NODE_URL("users/updateMyPassword");
    const params = {
      passwordCurrent: oldPassword,
      password: newPassword,
      passwordConfirm: reNewPassword,
    };

    for (let key in params) {
      if (params[key] == "") return toast.error("Please enter all fields");
    }

    if (newPassword !== reNewPassword) {
      return toast.error("New Password and re-new password are not same");
    }

    setIsLoading(true);
    const response = await Patch(url, params, header);
    setIsLoading(false);
    if (response !== undefined) {
      console.log(response?.data);
      cookie.set("accessToken", response?.data?.token, { expires: 30 });
      dispatch(islogin(response?.data));
      toast.success("Password Changed");
      setOldPassword('')
      setnewPassword('')
      setReNewPassword('')
    }
  };

  return (
    <div className="container">
      <h1>Change Password</h1>
      <form>
        {/* Old */}
        <div className="form-group">
          <label for="exampleInputPassword1">Enter old password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e?.target?.value)}
          />
        </div>

        {/* new */}
        <div className="form-group">
          <label for="exampleInputPassword1">Enter new password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setnewPassword(e?.target?.value)}
          />
        </div>

        {/* new */}
        <div className="form-group">
          <label for="exampleInputPassword1">Re enter new password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Re enter new Password"
            value={reNewPassword}
            onChange={(e) => setReNewPassword(e?.target?.value)}
          />
        </div>

        <Button
          className="btn btn-primary"
          onClick={submitHandler}
          disabled={isLoading}
        >
          {isLoading ? "Please wait" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
